import {handleActions} from 'redux-actions';
import {
    addMemberContact, attachProjectToMember, deleteMemberContact,
    detachProjectFromMember,
    profileMemberSuccess, requestUpdateMemberDataStart, requestUpdateMemberDataSuccess,
    updateMemberContact, updateMemberContactType,
    updateMemberData
} from "../actions/memberProfile";
import {handleMemberProfileFromAPI} from "./membersListReducer";

const initValues = {
    profile: {
        firstname: null,
        lastname: null,
    },
    updatingInProgress: false,
    isLoaded: false,
}

const CONTACT_ACTION_CREATE = 'create'
const CONTACT_ACTION_UPDATE = 'update'
const CONTACT_ACTION_DELETE = 'delete'
const DEFAULT_CONTACT_TYPE = 'phone';
export const CONTACT_TYPE_PHONE = 'phone';
export const CONTACT_TYPE_INSTAGRAM = 'instagram';

const PROJECT_ACTION_ATTACH = 'attach'
export const PROJECT_ACTION_DETACH = 'detach'

export default handleActions(
    {
        [profileMemberSuccess]: (state, action) => {
            return {
                ...state,
                profile: handleMemberProfileFromAPI(action.payload.member),
                isLoaded: true,
            };
        },
        [requestUpdateMemberDataStart]: (state) => {
            return {
                ...state,
                updatingInProgress: true,
            };
        },
        [requestUpdateMemberDataSuccess]: (state, action) => {
            return {
                ...state,
                profile: handleMemberProfileFromAPI(action.payload.member),
                updatingInProgress: false,
            };
        },
        [updateMemberData]: (state, action) => {
            const field = action.payload.field
            const value = action.payload.value

            return {
                ...state,
                profile: {...state.profile, ...{[field]: value}}
            }
        },
        [attachProjectToMember]: (state, action) => {
            const projectAction = action.payload
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...{
                        projects: {
                            ...state.profile.projects, ...{
                                [projectAction.id]: {
                                    ...{
                                        id: projectAction.id,
                                        action: PROJECT_ACTION_ATTACH,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        [detachProjectFromMember]: (state, action) => {
            const projectAction = action.payload
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...{
                        projects: {
                            ...state.profile.projects, ...{
                                [projectAction.id]: {
                                    ...{
                                        id: projectAction.id,
                                        action: PROJECT_ACTION_DETACH,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        [addMemberContact]: (state, action) => {
            const newContactId = Math.random().toString(36).substring(7);
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...{
                        contacts: {
                            ...state.profile.contacts, ...{
                                [newContactId]: {
                                    ...{
                                        id: newContactId,
                                        value: '',
                                        action: CONTACT_ACTION_CREATE,
                                        type: action.payload.contactType
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        [updateMemberContact]: (state, action) => {
            const contact = action.payload
            let newAction = CONTACT_ACTION_UPDATE
            if (state.profile.contacts[contact.id].action === CONTACT_ACTION_CREATE) {
                newAction = CONTACT_ACTION_CREATE
            }
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...{
                        contacts: {
                            ...state.profile.contacts, ...{
                                [contact.id]: {
                                    ...state.profile.contacts[contact.id],
                                    ...{value: contact.value, action: newAction}
                                }
                            }
                        }
                    }
                }
            }
        },
        [updateMemberContactType]: (state, action) => {
            const contactId = action.payload.id
            const contactNewType = action.payload.type
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...{
                        contacts: {
                            ...state.profile.contacts, ...{
                                [contactId]: {
                                    ...state.profile.contacts[contactId],
                                    ...{type: contactNewType}
                                }
                            }
                        }
                    }
                }
            }
        },
        [deleteMemberContact]: (state, action) => {
            const contact = action.payload
            let newContacts
            if (state.profile.contacts[contact.id].action === CONTACT_ACTION_CREATE) {
                newContacts = {...state.profile.contacts}
                delete newContacts[contact.id]
            } else {
                newContacts = {
                    ...state.profile.contacts, ...{
                        [contact.id]: {
                            ...state.profile.contacts[contact.id],
                            ...{value: contact.value, action: CONTACT_ACTION_DELETE}
                        }
                    }
                }
            }
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...{
                        contacts: newContacts
                    }
                }
            }
        }
    },
    initValues,
);

