import React from "react";
import {connect} from 'react-redux';
import {intentActionUpdateParamsField} from "../actions/main";


const ExecuteUrlAction = (props) => {
    const action = props.action
    return (
        <div className={"ExecuteUrlAction-action-intent-settings"}>
            <div className={"form-group mb-8"}>
                URL для вызова:
                <input className="form-control" type={"text"}
                       onChange={el => {
                           props.intentActionUpdateParamsField({id: action.id, field: 'url', value: el.target.value})
                       }}
                       value={action.params.url}/>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        intentState: state.intentProfile,
    }
}

export default connect(mapStateToProps, {
    intentActionUpdateParamsField
})(ExecuteUrlAction);
