import React from 'react';
import { connect } from "react-redux";
import { setActive } from "../reducers/list";
var MenuItem = function (props) {
    var contentItem = props.contentItem;
    var renderItem = function (contentItem) {
        var title = contentItem.menu.title ? contentItem.menu.title : React.createElement("i", null, "\u0431\u0435\u0437 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u044F");
        return React.createElement("span", null,
            contentItem.parentId &&
                React.createElement("span", { className: "icon" }),
            title);
    };
    var classNames = ["menu-item"];
    if (props.boordListState.activeContent && props.boordListState.activeContent.id == contentItem.id) {
        classNames.push("current");
    }
    var isPassed = props.boordListState.passedContents[contentItem.id];
    if (isPassed) {
        classNames.push("passed");
    }
    classNames.push("child-level-" + props.childLevel);
    return (React.createElement("div", { className: classNames.join(' '), onClick: function () {
            props.setActive(contentItem.id);
        } }, renderItem(contentItem)));
};
var mapStateToProps = function (state) {
    return {
        boordListState: state.boordList,
    };
};
export default connect(mapStateToProps, {
    setActive: setActive
})(MenuItem);
