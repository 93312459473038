import {requests} from "../utils/requests";

const Intent = {
    list: () => requests.get('/api/intents'),
    getById: id => requests.get('/api/intents/' + id),
    create: (intentData) => requests.post('/api/intent', intentData),
    update: (id, intentData) => requests.put('/api/intents/' + id, intentData),
    getAvailableBotMenu: () => requests.get('/api/inline-menu')
};

export default Intent
