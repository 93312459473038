import {createStore, compose, applyMiddleware} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import {createBrowserHistory} from "history";
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import {connectRouter, routerMiddleware} from 'connected-react-router';
import rootReducer from '../reducers';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';


export const history = createBrowserHistory();
// const connectRouterHistory = connectRouter(history);
//
// function configureStoreProd(initialState) {
//     const reactRouterMiddleware = routerMiddleware(history);
//     const middlewares = [
//         // Add other middleware on this line...
//
//         // thunk middleware can also accept an extra argument to be passed to each thunk action
//         // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
//         thunk,
//         reactRouterMiddleware,
//     ];
//
//     return createStore(
//         createRootReducer(history), // root reducer with router state
//         initialState,
//         compose(applyMiddleware(...middlewares))
//     );
// }
//
// function configureStoreDev(initialState) {
//     const reactRouterMiddleware = routerMiddleware(history);
//     const middlewares = [
//         // Add other middleware on this line...
//
//         // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
//         reduxImmutableStateInvariant(),
//
//         // thunk middleware can also accept an extra argument to be passed to each thunk action
//         // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
//         thunk,
//         reactRouterMiddleware,
//     ];
//
//     const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
//     const store = createStore(
//         createRootReducer(history), // root reducer with router state
//         initialState,
//         composeEnhancers(applyMiddleware(...middlewares))
//     );
//
//     if (module.hot) {
//         // Enable Webpack hot module replacement for reducers
//         module.hot.accept('../reducers', () => {
//             const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
//             store.replaceReducer(connectRouterHistory(nextRootReducer));
//         });
//     }
//
//     return store;
// }
//
// const configureStoreOrig = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

const appConfigureStore = () => {


    const middleware = getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true,
    });

    // console.log('reducers', reducers)

    const store = configureStore({
        reducer: rootReducer(history),
        middleware,
        devTools: process.env.NODE_ENV !== 'production',
    });

    return store
}

export default appConfigureStore;
