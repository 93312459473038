import {handleActions} from 'redux-actions';
import {
    requestFetchTeamProfileSuccess, setUpdatedTeamData,
} from "../actions/main";

const initValues = {
    team: {
        name: null,
        leadId: null,
        botCommand: null,
    },
    inProgress: false,
    isLoaded: false
}
export default handleActions(
    {
        [requestFetchTeamProfileSuccess]: (state, action) => {
            const teamData = {
                name: action.payload.team.name,
                leadId: action.payload.team.lead_id,
                botCommand: action.payload.team.bot_command,
            }
            return {
                ...state,
                ...{
                    team: teamData,
                    isLoaded: true,
                    inProgress: false,
                }
            }
        },
        [setUpdatedTeamData]: (state, action) => {
            const field = action.payload.field
            const value = action.payload.value

            return {
                ...state,
                team: {...state.team, ...{[field]: value}}
            }
        },
    },
    initValues,
);
