import {handleActions} from 'redux-actions';
import {requestUpdateStart, setEditableContentAction} from "../actions/main";
import {Actions} from "../api/content";

const initValues = {
    id: null,
    menuTitle: null,
    nextContentButtonTitle: null,
    parentId: null,
    content: [],
    isNew: false,
    savingInProgress: false,
}
export default handleActions(
    {
        [Actions.UPDATE_CONTENT_NEXT_CONTENT_BUTTON_TITLE]: (state, action) => {
            return {
                ...state,
                ...{
                    nextContentButtonTitle: action.payload
                }
            }
        },
        [Actions.UPDATE_CONTENT_MENU_TITLE]: (state, action) => {
            return {
                ...state,
                ...{
                    menuTitle: action.payload
                }
            }
        },
        [Actions.UPDATE_CONTENT_PARENT_ID]: (state, action) => {
            return {
                ...state,
                ...{
                    parentId: action.payload
                }
            }
        },
        [Actions.SET_EDIT_CONTENT]: (state, action) => {
            return {
                ...state,
                ...{
                    id: action.payload.id,
                    content: action.payload.content,
                    parentId: action.payload.parentId,
                    menuTitle: action.payload.menu.title,
                    nextContentButtonTitle: action.payload.nextStep.buttonTitle,
                    isNew: action.payload.isNew
                }
            }
        },
        [Actions.RESET_EDIT_MODE]: (state, action) => {
            return {
                ...state,
                ...{
                    id: null,
                    content: null,
                    parentId: null,
                    menuTitle: null,
                    nextContentButtonTitle: null,
                    isNew: null
                }
            }
        },
        [Actions.REQUEST_UPDATE_START]: (state) => {
            console.log('requestUpdateStart.name')
            return {
                ...state,
                ...{
                    savingInProgress: true
                }
            }
        },
        [Actions.REQUEST_UPDATE_SUCCESSFUL]: (state, action) => {
            return {
                ...state,
                ...{
                    id: action.payload.id,
                    isNew: false,
                    savingInProgress: false
                }
            }
        },
    },
    initValues,
);
