import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import {ENV_PROD} from "../constants/main";
import {getSession} from "../modules/login/helpers/authSessionStorage";

const superagent = superagentPromise(_superagent, global.Promise);

const responseBody = res => res.body;

const tokenPlugin = req => {
    const tokenSession = getSession();
    if (tokenSession) {
        console.log('user-token', tokenSession);
        req.set('AuthToken', `${tokenSession}`);
    }
};

const requestsObj = {
    apiRootUrl: '',

    setRootUrlEnvironment: env => {
        // if (env === ENV_PROD) {
        //   requests.apiRootUrl = 'http://localhost:8711';
        // } else {
        //   requests.apiRootUrl = 'http://localhost:8711';
        // }
        // console.log('requests.apiRootUrl', requests.apiRootUrl);
    },

    get: url => {
        const _url = `${requests.apiRootUrl}${url}`;
        return superagent
            .get(_url)
            .use(tokenPlugin)
            .then(responseBody);
    },

    post: (url, body) => {
        const _url = `${requests.apiRootUrl}${url}`;
        console.log(_url);
        return superagent
            .post(_url, body)
            .use(tokenPlugin)
            .then(responseBody);
    },
    put: (url, body) => {
        const _url = `${requests.apiRootUrl}${url}`;
        console.log(_url);
        return superagent
            .put(_url, body)
            .use(tokenPlugin)
            .then(responseBody);
    },
    patch: (url, body) => {
        const _url = `${requests.apiRootUrl}${url}`;
        return superagent
            .patch(_url, body)
            .use(tokenPlugin)
            .then(responseBody);
    },
    delete: (url, body) => {
        const _url = `${requests.apiRootUrl}${url}`;
        console.log(_url);
        return superagent
            .del(_url, body)
            .use(tokenPlugin)
            .then(responseBody);
    },
};

export let requests = requestsObj;
