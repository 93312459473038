import {handleActions} from 'redux-actions';
import {
    getCurrentUserFailure, getCurrentUserRequest, getCurrentUserSuccess, sendAuthCodeFailure,
    sendAuthCodeRequest,
    sendAuthCodeSuccess, verifyAuthCodeFailure,
    verifyAuthCodeRequest,
    verifyAuthCodeSuccess
} from "../actions/auth";

const initValues = {
    waitPin: false,
    inProgress: false,
    hasFailure: false,
    sessionId: null,
}
export default handleActions(
    {
        [getCurrentUserRequest]: (state) => {
            return {
                ...state,
                inProgress: true,
            }
        },
        [getCurrentUserSuccess]: (state) => {

        },
        [getCurrentUserFailure]: (state) => {

        },
        [sendAuthCodeRequest]: (state) => {
            return {
                ...state,
                inProgress: true,
                hasFailure: false,
            };
        },
        [sendAuthCodeFailure]: (state) => {
            return {
                ...state,
                ...{
                    hasFailure: true,
                }
            }
        },
        [sendAuthCodeSuccess]: (state, action) => {
            console.log('sendAuthCodeSuccess')
            return {
                ...state,
                ...{
                    inProgress: false,
                    waitPin: true,
                    sessionId: action.payload.sessionId,
                }
            };
        },
        [verifyAuthCodeRequest]: (state) => {
            return {
                ...state,
                inProgress: true,
                hasFailure: false,
            };
        },
        [verifyAuthCodeFailure]: (state) => {
            return {
                ...state,
                ...{
                    hasFailure: true,
                }
            }
        },
        [verifyAuthCodeSuccess]: (state) => {
            return {
                ...state,
                ...{
                    hasFailure: false,
                }
            }
        }
    },
    initValues,
);

