import {handleActions} from 'redux-actions';
import {getMembersListRequest, getMembersListSuccess} from "../actions/membersList";

const initValues = {
    members: [],
    isLoading: false
}
export default handleActions(
    {
        [getMembersListRequest]: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        [getMembersListSuccess]: (state, action) => {

            const members = action.payload.members.map(member => {
                return handleMemberProfileFromAPI(member)
            });

            return {
                ...state,
                members: members,
                isLoading: false,
            };
        },
    },
    initValues,
);

export function handleMemberProfileFromAPI(member) {
    const displayName = member.lastname + ' ' + member.name
    const memberData = {
        id: member.id,
        displayName: displayName,
        lastname: member.lastname,
        firstname: member.name,
        about: member.about,
        birthday: member.birthday,
        avatarUrl: null,
        jobPosition: member.job_position,
        jobPositionStartedAt: member.job_position_started_at,
        jobPositionEndedAt: member.job_position_ended_at,
        mainPhone: null,
        mainEmail: member.email,
        gender: member.gender,
        contacts: {},
        teamsId: member.teams_id ? member.teams_id : [],
        projects: [],
    };
    if (member.photos.length) {
        memberData.avatarUrl = member.photos[0].url
    }
    //
    // if (member.teams_id !== undefined && member.teams_id.length) {
    //   memberData.teamsId = member.teams_id
    // }


    member.projects.map(project => {
        memberData.projects = {
            ...memberData.projects, ...{
                [project.id]: {
                    id: project.id,
                    action: 'none'
                }
            }
        }
    })

    member.contacts.map(contact => {
            if (contact.type === 'phone' && memberData.mainPhone === null) {
                memberData.mainPhone = contact.value
            }
            memberData.contacts = {
                ...memberData.contacts, ...{
                    [contact.id]: {
                        id: contact.id,
                        value: contact.value,
                        type: contact.type,
                        action: 'none'
                    }
                }
            }
        }
    )

    return memberData;
}
