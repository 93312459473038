import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {loadTeamsList} from "../actions/main";
import Table from "@material-ui/core/Table";
import {Link} from "react-router-dom";
import {teamCreateUrl, teamProfileUrl} from "../UrlFactory";

const TeamListPage = (props) => {

    useEffect(() => {
        props.loadTeamsList()
    }, [])

    const teams = props.teamsListState.teams.map(team => {
        const teamLink = teamProfileUrl(team.id).getPublicUrl()
        return (<tr key={"team_" + team.id}>
            <td>{team.name}</td>
            <td><Link to={teamLink}>edit</Link></td>
        </tr>)
    });

    return (<div>
        <Link to={teamCreateUrl.getPublicUrl()}>create team</Link>
        <Table striped bordered hover size="sm">
            <thead>
            <tr>
                <th>#</th>
                <th>#</th>
            </tr>
            </thead>
            <tbody>
            {teams}
            </tbody>
        </Table></div>)
};

const mapStateToProps = function (state) {
    return {
        teamsListState: state.teamsList,
    }
}

TeamListPage.propTypes = {
    loadTeamsList: PropTypes.func,
    teamsListState: PropTypes.object,
}

export default connect(mapStateToProps, {
    loadTeamsList
})(TeamListPage);
