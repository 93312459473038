import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {Tab, Nav, InputGroup} from "react-bootstrap";
import {
    Checkbox,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select
} from "@material-ui/core";
import {
    loadMember, updateMemberData,
    attachProjectToMember,
    detachProjectFromMember, saveUpdatedMemberData
} from "../actions/memberProfile";
import PropTypes from 'prop-types';
import {PROJECT_ACTION_DETACH} from "../reducers/memberProfileReducer";
import {loadProjectsList} from "../../projects/actions/main";
import {loadTeamsList} from "../../teams/actions/main";
import {BirthdayDatePicker, ProjectDatePicker} from "../../../components/ProjectDatePicker";
import moment from "moment/moment";
import {TableSpinner} from "../../../components/CustomSpinner";
import ContactTab from "./ContactTab";
import {Link, useParams} from "react-router-dom";
import {membersListUrl} from "../UrlFactory";

const MemberProfileEditPage = (props) => {

    const {memberId} = useParams();

    useEffect(() => {
        props.loadMember(memberId);
        props.loadProjectsList();
        props.loadTeamsList();
    }, [])


    const userProfile = props.memberProfileState.profile

    let renderMemberProjects = []

    if (props.memberProfileState.isLoaded && props.projectsListState.isLoaded) {

        const projectChecked = (isUserInProject, projectId) => {
            if (!isUserInProject) {
                props.attachProjectToMember({id: projectId})
            } else {
                props.detachProjectFromMember({id: projectId})
            }
        }

        renderMemberProjects = props.projectsListState.projects.map(project => {
            const isUserInProject = userProfile.projects[project.id] !== undefined &&
                userProfile.projects[project.id].action !== PROJECT_ACTION_DETACH

            const labelId = `checkbox-list-secondary-label-${project.id}`;
            return (
                <ListItem
                    onClick={() => {
                        projectChecked(isUserInProject, project.id)
                    }}
                    key={'project_' + project.id} button>
                    <ListItemText id={labelId}>
                        <span className={"font-size-lg"}>{project.name}</span>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <Checkbox
                            edge="end"
                            onClick={() => {
                                projectChecked(isUserInProject, project.id)
                            }}
                            checked={isUserInProject}
                            inputProps={{"aria-labelledby": labelId}}
                        />
                    </ListItemSecondaryAction>
                </ListItem>)
        });
    }

    let teamsListSelector = null
    if (props.memberProfileState.isLoaded && props.teamsListState.isLoaded) {

        const teams_idx = {}

        const items = props.teamsListState.teams.map(team => {
            teams_idx[team.id] = {name: team.name}

            return (<MenuItem key={'team_' + team.id} value={team.id}>
                <Checkbox checked={userProfile.teamsId.indexOf(team.id) > -1}/>
                <ListItemText primary={team.name}/>
            </MenuItem>)
        });

        teamsListSelector = <Select multiple={true}
                                    renderValue={(selected) => {
                                        const result = selected.map(e => {
                                            return teams_idx[e].name
                                        })
                                        return result.join(', ');
                                    }}
                                    value={userProfile.teamsId}
                                    onChange={(item) => {
                                        let selectedTeams = item.target.value
                                        props.updateMemberData({field: 'teamsId', value: selectedTeams})
                                    }}>
            {items}
        </Select>
    }


    const updateUserData = () => {
        const contacts = Object.values(userProfile.contacts)
        const projects = Object.values(userProfile.projects)
        let requestData = {
            firstname: userProfile.firstname,
            lastname: userProfile.lastname,
            about: userProfile.about,
            birthday: userProfile.birthday,
            gender: userProfile.gender,
            email: userProfile.mainEmail,
            teams_id: userProfile.teamsId,
            projects: projects,
            contacts: contacts,
            job_position: userProfile.jobPosition,
            job_position_started_at: userProfile.jobPositionStartedAt,
            job_position_ended_at: userProfile.jobPositionEndedAt,
        }
        props.saveUpdatedMemberData(memberId, requestData)
    }

    return (
        <div className={"page-container"}>
            <Tab.Container defaultActiveKey="first">
                <div className={"card2"}>
                    <div className={"header"}>

                        <div className={"breadcrumbs"}>
                            <ul>
                                <li>
                                    <Link to={membersListUrl.getPublicUrl()}>Список сотрудников</Link>
                                </li>
                                <li className={"current"}>
                                    {userProfile.firstname} {userProfile.lastname}
                                </li>
                            </ul>
                        </div>

                        <div className={"nav5"}>
                            <Nav>
                                <Nav.Item>
                                    <Nav.Link eventKey="first">
                                        Профиль
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        Контакты
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="projects">
                                        <span className={"nav-text font-size-lg"}>Проекты</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="jobs">
                                        <span className={"nav-text font-size-lg"}>Работа</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                    </div>
                    <div className={"card-body"}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="form-group row">

                                    <label className="col-form-label col-3 text-lg-right text-left">Фамилия</label>
                                    <div className="col-9">

                                        <input type={"text"} className={"form-control"}
                                               onChange={element => {
                                                   props.updateMemberData({
                                                       field: 'lastname',
                                                       value: element.target.value
                                                   })
                                               }}
                                               value={userProfile.lastname}/>
                                    </div>
                                </div>
                                <div className="form-group row">


                                    <label className="col-form-label col-3 text-lg-right text-left">Имя</label>
                                    <div className="col-9">

                                        {userProfile && (
                                            <Select value={userProfile.gender}
                                                    inputProps={{
                                                        name: "gender",
                                                        id: "gender-select"
                                                    }}
                                                    onChange={element => {
                                                        props.updateMemberData({
                                                            field: 'gender',
                                                            value: element.target.value
                                                        })
                                                    }}>
                                                <MenuItem key={'gender_m'} value={"m"}>👨</MenuItem>
                                                <MenuItem key={'gender_f'} value={"f"}>👩</MenuItem>
                                            </Select>
                                        )}
                                        <input type={"text"}
                                               className={"form-control"}
                                               onChange={element => {
                                                   props.updateMemberData({
                                                       field: 'firstname',
                                                       value: element.target.value
                                                   })
                                               }}
                                               value={userProfile.firstname}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-3 text-lg-right text-left">Email</label>
                                    <div className="col-9">
                                        <input type={"text"} className={"form-control"}
                                               onChange={element => {
                                                   props.updateMemberData({
                                                       field: 'mainEmail',
                                                       value: element.target.value
                                                   })
                                               }}
                                               value={userProfile.mainEmail}/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-3 text-lg-right text-left">Дата
                                        рождения</label>
                                    <div className="col-9">
                                        <BirthdayDatePicker
                                            onChange={dateObj => {
                                                const result = moment(dateObj).format('YYYY-MM-DD');
                                                console.log('res', result)
                                                props.updateMemberData({field: 'birthday', value: result})
                                            }}
                                            date={userProfile.birthday}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-3 text-lg-right text-left">Отдел</label>
                                    <div className="col-9">
                                        {teamsListSelector}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <ContactTab/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="projects">
                                <h4>Отметьте проекты, которыми занимается этот сотрудник</h4>
                                <List dense>
                                    {renderMemberProjects}
                                </List>
                            </Tab.Pane>
                            <Tab.Pane eventKey="jobs">
                                <h4>Работа</h4>

                                <div className="form-group row">
                                    <label className="col-form-label col-3 text-lg-right text-left">Название
                                        должности</label>
                                    <div className="col-9">
                                        <input type={"text"} className={"form-control"}
                                               onChange={element => {
                                                   props.updateMemberData({
                                                       field: 'jobPosition',
                                                       value: element.target.value
                                                   })
                                               }}
                                               value={userProfile.jobPosition}/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-3 text-lg-right text-left">Дата вступления в
                                        должность</label>
                                    <div className="col-9">
                                        <ProjectDatePicker timestamp={userProfile.jobPositionStartedAt}
                                                           onChange={(data) => {
                                                               props.updateMemberData({
                                                                   field: 'jobPositionStartedAt',
                                                                   value: data.timestamp
                                                               })
                                                           }}/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-3 text-lg-right text-left">Дата
                                        увольнения</label>
                                    <div className="col-9">
                                        <ProjectDatePicker timestamp={userProfile.jobPositionEndedAt}
                                                           onChange={(data) => {
                                                               props.updateMemberData({
                                                                   field: 'jobPositionEndedAt',
                                                                   value: data.timestamp
                                                               })
                                                           }}/>
                                    </div>
                                </div>

                            </Tab.Pane>
                        </Tab.Content>
                        <div className={"text-center"}>

                            {props.memberProfileState.updatingInProgress && <TableSpinner/>}
                            {!props.memberProfileState.updatingInProgress &&
                            <button onClick={updateUserData} className={"btn btn-success"}>
                                Сохранить изменения
                            </button>
                            }
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </div>
    )
};

const mapStateToProps = function (state) {
    return {
        memberProfileState: state.memberProfile,
        projectsListState: state.projectsList,
        teamsListState: state.teamsList,
    }
}

MemberProfileEditPage.propTypes = {
    match: PropTypes.object,
    memberProfileState: PropTypes.object,
    projectsListState: PropTypes.object,
    teamsListState: PropTypes.object,
    loadMember: PropTypes.func,
    loadProjectsList: PropTypes.func,
    saveUpdatedMemberData: PropTypes.func,
    updateMemberData: PropTypes.func,
    attachProjectToMember: PropTypes.func,
    detachProjectFromMember: PropTypes.func,
    loadTeamsList: PropTypes.func,
}

export default connect(mapStateToProps, {
    updateMemberData,
    attachProjectToMember,
    detachProjectFromMember,
    loadMember,
    loadProjectsList,
    loadTeamsList,
    saveUpdatedMemberData
})(MemberProfileEditPage);
