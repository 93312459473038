import React from "react";
import {Outlet} from "react-router-dom";
import '../styles/Login.scss'
import {connect} from "react-redux";

const LoginLayout = (props) => {
    return (<div className={"login-layout"}>
        <Outlet/>
    </div>)
}

export default connect()(LoginLayout);

