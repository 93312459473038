import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link, Outlet} from "react-router-dom";
require('../styles/init.scss')

export function AdminLayout({children}) {

    return (<div>
        <div className={"content-wrapper"}>

            <Container>

                <header>
                    <div className={"admin-name"}>
                        <span>Кирилл</span>
                    </div>
                </header>

                <Row>
                    <Col sm={2}>

                        <div className={"menu-section"}>
                            <div className={"title"}>
                                Команда
                            </div>
                            <ul>
                                <li>
                                    <Link to="/panel/members">Сотрудники</Link>
                                </li>
                                <li>
                                    <Link to="/panel/teams">Команды</Link>
                                </li>
                                <li>
                                    <Link to="/panel/projects">Проекты</Link>
                                </li>
                            </ul>
                        </div>
                        <div className={"menu-section"}>
                            <div className={"title"}>
                                Настройки
                            </div>
                            <ul>
                                <li>
                                    <Link to="/panel/intents">Интенты</Link>
                                </li>
                                <li>
                                    <Link to="/panel/notifications">Уведомления</Link>
                                </li>
                                <li>
                                    <Link to="/panel/bot">Настройки бота</Link>
                                </li>
                                <li>
                                    <Link to="/panel/bot">Интеграции</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={10}>
                        <Outlet/>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>)

}
