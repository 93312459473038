var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef } from "react";
import { connect } from "react-redux";
import { setEditableContentAction } from "../actions/main";
import { listContent } from "../api/content";
import { setConditionsForEdit } from "../reducers/editCondition";
import { savePassedMark, } from "../api/steps";
import { toggleMenuInContent } from "../reducers/list";
import { renderSlateContent } from "../utils/slate";
import ConditionsView from "./ConditionsView";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ContentMenuListComponent from "./ContentMenuListComponent";
import MenuIcon from '@mui/icons-material/Menu';
import { TableSpinner } from "../../../components/CustomSpinner";
import BlockedContent from "./BlockedContent";
var getLastChildContent = function (parentContent) {
    var child = parentContent.children;
    var lastChild = child[child.length - 1];
    if (lastChild.children.length) {
        return getLastChildContent(lastChild);
    }
    else {
        return lastChild;
    }
};
var getPrevContent = function (currentContent, items) {
    var prevCandidatesList = [];
    var nextContentMinOrderPosition = currentContent.orderPosition;
    /**
     * если контент без родителя или не является первым дочерним элементом:
     * предыдущий контент получается через сортировку
     *
     * если предыдущий контент имеет детей - нужно найти последний элемент в его максимальной глубине
     */
    var prevItem;
    if (currentContent.parentId) {
        var parentContent = items[currentContent.parentId];
        prevCandidatesList = parentContent.children;
        prevCandidatesList = __spreadArray([], prevCandidatesList, true);
        if (prevCandidatesList[0].id == currentContent.id) {
            return parentContent;
        }
    }
    else {
        prevCandidatesList = Object.values(items)
            .filter(function (item) { return item.parentId == null; })
            .sort(function (a, b) {
            if (a.orderPosition < b.orderPosition)
                return -1;
            if (a.orderPosition > b.orderPosition)
                return 1;
            return 0;
        });
    }
    prevCandidatesList = prevCandidatesList.reverse();
    prevItem = prevCandidatesList.find(function (item) { return item.orderPosition < nextContentMinOrderPosition; });
    if (prevItem && prevItem.children.length) {
        // если у найденного контента есть дочерние элементы - добираемся до самого последнего
        return getLastChildContent(prevItem);
    }
    else {
        return prevItem;
    }
};
var ContentReadModeComponent = function (props) {
    var activeContent = props.boordListState.activeContent;
    if (props.boordListState.loadingInProgress) {
        return React.createElement("div", { className: "content-is-loading" },
            React.createElement(TableSpinner, null));
    }
    if (!activeContent) {
        return React.createElement("div", null);
    }
    var activeContentPassed = props.boordListState.passedContents[activeContent.id];
    var prevContent = getPrevContent(activeContent, props.boordListState.items);
    var prevContentPassed = false;
    if (prevContent) {
        prevContentPassed = props.boordListState.passedContents[prevContent.id];
    }
    var emailToken = {
        token: '%email%',
        value: props.currentUserState.email,
    };
    var nameToken = {
        token: '%name%',
        value: props.currentUserState.name,
    };
    var content = renderSlateContent(activeContent.content, [emailToken, nameToken]);
    var editContent = function () {
        props.setEditableContent(activeContent);
        props.setConditionsForEdit(activeContent);
    };
    var onMenuButtonClick = function () {
        props.toggleMenuInContent(!props.boordListState.showMenuInContentArea);
    };
    var contentDataClassNames = ["content-data"];
    if (props.boordListState.showMenuInContentArea) {
        contentDataClassNames.push("show-menu-in-content");
    }
    var isContentAvailable = activeContentPassed || !prevContent || prevContentPassed;
    var myRef = useRef(null);
    var scrollTo = function () {
        if (myRef && myRef.current /* + other conditions */) {
            myRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { ref: myRef, className: "content-header" },
            React.createElement("div", { className: "left" },
                React.createElement("div", { className: "menu-in-content-area-button" },
                    React.createElement(MenuIcon, { onClick: onMenuButtonClick })),
                React.createElement("div", { className: "title" }, props.boordListState.activeContent.menu.title)),
            React.createElement("div", { className: "right" }, props.currentUserState.authorized && props.currentUserState.isAdmin &&
                React.createElement("a", { onClick: editContent },
                    React.createElement(ModeEditOutlineOutlinedIcon, null)))),
        React.createElement("div", { className: contentDataClassNames.join(" ") },
            React.createElement("div", { className: "menu-in-content-container" },
                React.createElement("div", { className: "hint" }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B \u0434\u043B\u044F \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u0430:"),
                React.createElement("div", { className: "content-menu" },
                    React.createElement(ContentMenuListComponent, null))),
            React.createElement("div", { className: "text-data" }, !isContentAvailable ?
                React.createElement(BlockedContent, null)
                : content),
            isContentAvailable && React.createElement(ConditionsView, { scrollToContentCallback: scrollTo })));
};
var mapStateToProps = function (state) {
    return {
        boordListState: state.boordList,
        currentUserState: state.currentUser,
    };
};
export default connect(mapStateToProps, {
    setEditableContent: setEditableContentAction,
    listContent: listContent,
    setConditionsForEdit: setConditionsForEdit,
    markContentAsPassed: savePassedMark,
    toggleMenuInContent: toggleMenuInContent
})(ContentReadModeComponent);
