import {createAction} from "redux-actions";
import NotificationApi from "../api/main";
import history from "../../../utils/history";

export const requestNotificationListInitialize = createAction('requestNotificationListInitialize');
export const requestNotificationListSuccess = createAction('requestNotificationListSuccess');
export const requestNotificationListFailed = createAction('requestNotificationListFailed');

export const loadNotificationList = () => dispatch => {
    dispatch(requestNotificationListInitialize())
    NotificationApi.getList().then(data => {
        dispatch(requestNotificationListSuccess(data))
    }).catch(() => {
        dispatch(requestNotificationListFailed())
    })
}

export const requestNotificationCreateInitialize = createAction('requestNotificationCreateInitialize');
export const requestNotificationCreateSuccess = createAction('requestNotificationCreateSuccess');
export const requestNotificationCreateFailed = createAction('requestNotificationCreateFailed');

export const createNotification = (notificationData) => {
    return NotificationApi.create(notificationData)
}
export const requestNotificationUpdateInitialize = createAction('requestNotificationUpdateInitialize');
export const requestNotificationUpdateSuccess = createAction('requestNotificationUpdateSuccess');
export const requestNotificationUpdateFailed = createAction('requestNotificationUpdateFailed');

export const updateNotification = (notificationId, notificationData) => {
    return NotificationApi.update(notificationId, notificationData)
}

export const sendTestNotification = (notificationId, notificationData) => {
    return NotificationApi.test(notificationId, notificationData)
}

export const fetchNotificationById = (notificationId) => {
    return NotificationApi.getById(notificationId)
}
