import {handleActions} from 'redux-actions';
import {requestSaveNewTeamSuccess, setNewTeamData} from "../../teams/actions/main";

const initValues = {
    newIntent: {
        name: null,
        leadId: null,
    },
    newTeamId: null,
    justCreated: false,
    inProgress: false
}
export default handleActions(
    {
        [requestSaveNewTeamSuccess]: (state, action) => {
            console.log('requestSaveNewTeamSuccess')
            return {
                ...state,
                ...{
                    justCreated: true,
                    newTeamId: action.payload.team.id,
                }
            }
        },
        [setNewTeamData]: (state, action) => {
            const field = action.payload.field
            const value = action.payload.value

            return {
                ...state,
                newTeam: {...state.newTeam, ...{[field]: value}}
            }
        },
    },
    initValues,
);
