import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {
    addNewAnswer,
    getById,
    removeAnswer,
    updateAnswerInTextIntent,
    updateIntentData,
    updateIntentQuery,
    deleteIntentQuery,
    createIntentQuery, loadIntents
} from "../../../actions/intent";
import {
    intentActionTransitionCreate, intentActionTransitionDelete,
    intentActionTransitionUpdateField,
    intentActionUpdateField
} from "../actions/main";
import {Checkbox, MenuItem, Select} from "@material-ui/core";
import {
    INTENT_TYPE_ASK,
    INTENT_TYPE_EMAIL,
    INTENT_TYPE_SEND_CONTACT,
    INTENT_TYPE_TEXT
} from "../reducers/intentReducer";


const TransitionAction = (props) => {

    const action = props.action

    useEffect(() => {

        props.loadIntents()

        // let transitions = {}
        // if (action.transitions) {
        //   Object.values(action.transitions).forEach(item => {
        //     transitions = {
        //       ...transitions, ...{
        //         [item.id]: {
        //           id: item.id,
        //           text: item.text,
        //         }
        //       }
        //     }
        //   });
        // }
        // setTransitions(transitions)
    }, [])

    const renderTransitionItem = (transition) => {

        console.log('transition.deleteRule', transition.deleteRule)

        const intentsList = props.intentListState.intents.map(intent => {
            return (<MenuItem key={'intent_' + intent.id} value={intent.id}>
                {intent.name}
            </MenuItem>)
        });

        const intentSelect = <Select
            value={transition.targetIntentId}
            onChange={element => {
                props.intentActionTransitionUpdateField({
                    actionId: action.id,
                    transitionId: transition.id,
                    field: 'targetIntentId',
                    value: element.target.value
                })
            }}>
            <MenuItem value={""}>-</MenuItem>
            {intentsList}
        </Select>

        return (
            <div key={"transition_" + transition.id} className={"transition-item"}>
                <h3>transition #{transition.id}</h3>

                <div className="form-group">
                    <label>MessageButtonName:</label>
                    <input type="text"
                           className="form-control"
                           onChange={element => {
                               props.intentActionTransitionUpdateField({
                                   actionId: action.id,
                                   transitionId: transition.id,
                                   field: 'messageButtonName',
                                   value: element.target.value
                               })
                           }}
                           value={transition.messageButtonName}
                    />
                </div>

                <div className="form-group">
                    <label>Target intent:</label>
                    {intentSelect}
                </div>

                <div className="form-group">
                    <label>Action:</label>
                    <div className={"tip"} style={{margin: 5, fontSize: 10}}>value = NEXT_ACTION, PREVIOUS_ACTION,
                        (empty)
                    </div>
                    <input type="text"
                           className="form-control"
                           onChange={element => {
                               props.intentActionTransitionUpdateField({
                                   actionId: action.id,
                                   transitionId: transition.id,
                                   field: 'action',
                                   value: element.target.value
                               })
                           }}
                           value={transition.action}
                    />
                </div>


                <div className="form-group">
                    <label>Удалить переход:</label>
                    <Select value={transition.deleteRule} onChange={(item) => {
                        props.intentActionTransitionUpdateField({
                            actionId: action.id,
                            transitionId: transition.id,
                            field: 'deleteRule',
                            value: item.target.value
                        })
                    }}>
                        <MenuItem key={'never'} value={'NEVER'}>никогда</MenuItem>
                        <MenuItem key={'any_transition'} value={'ON_ANY_TRANSITION'}>после активации любого перехода в
                            действии</MenuItem>
                    </Select>
                </div>

                <div className="form-group">
                    <button className={"btn btn-primary btn-sm btn-danger"} onClick={() => {
                        props.intentActionTransitionDelete({actionId: action.id, transitionId: transition.id})
                    }
                    }>remove
                    </button>
                </div>

                <hr/>
            </div>
        )
    }

    let transitionsRenderElements = []
    if (action.transitions) {
        Object.values(action.transitions).map(item => {
            console.log('action.transitions', action.transitions)
            transitionsRenderElements.push(renderTransitionItem(item))
        })
    }

    return (
        <div className={"transition-intent-settings"}>

            <div><b>action transitions:</b></div>

            {transitionsRenderElements}

            <div style={{marginTop: 20}}>
                <button className="btn btn-sm btn-primary"
                        onClick={() => {
                            props.intentActionTransitionCreate({actionId: action.id})
                        }}
                        type="button">Добавить переход
                </button>
            </div>

        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        intentState: state.intentProfile,
        intentListState: state.intentsList,
    }
}

export default connect(mapStateToProps,
    {
        getById,
        updateIntentData,
        updateAnswerInTextIntent,
        addNewAnswer, removeAnswer,
        loadIntents,
        updateIntentQuery,
        createIntentQuery,
        deleteIntentQuery,
        intentActionUpdateField,
        intentActionTransitionCreate,
        intentActionTransitionDelete,
        intentActionTransitionUpdateField
    }
)(TransitionAction);
