import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    deleteProject,
    fetchProjectData,
    saveUpdatedProjectData,
    setUpdatedProjectData
} from "../actions/main";
import TeamSelector from "../../../components/TeamSelector";
import {useParams} from "react-router-dom";

const ProjectEditPage = (props) => {

    const {projectId} = useParams();

    useEffect(() => {
        props.fetchProjectData(projectId)
    }, [])

    if (props.projectUpdateState.isDeleted) {
        alert('props.projectUpdateState.isDeleted')
        return
    }

    const project = props.projectUpdateState.project


    const submitUpdatedProjectData = () => {
        let requestData = {
            name: project.name,
            description: project.description,
            bot_command: project.botCommand,
            website: project.website,
            appstore_url: project.appstoreUrl,
            googleplay_url: project.googlePlayUrl,
            team_id: project.teamId,
            additional_text_url: project.additionalTextUrl,
        }
        console.log('submitUpdatedProjectData.requestData', requestData)
        props.saveUpdatedProjectData(projectId, requestData)
    }

    return (<div className={"d-flex flex-column-fluid"}>
        <div className={"container"}>
            <div className={"card card-custom"}>

                <div className={"card-body"}>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Название проекта</label>
                        <div className="col-9">
                            <input type={"text"} className={"form-control"}
                                   onChange={element => {
                                       props.setUpdatedProjectData({field: 'name', value: element.target.value})
                                   }}
                                   value={project.name}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Описание </label>
                        <div className="col-9">

              <textarea className={"form-control"}
                        onChange={element => {
                            props.setUpdatedProjectData({field: 'description', value: element.target.value})
                        }} value={project.description}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Bot Command </label>
                        <div className="col-9">

                            <input type={"text"} value={project.botCommand} className={"form-control"}
                                   onChange={element => {
                                       props.setUpdatedProjectData({field: 'botCommand', value: element.target.value})
                                   }}/>

                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">website </label>
                        <div className="col-9">

                            <input type={"text"} value={project.website} className={"form-control"}
                                   onChange={element => {
                                       props.setUpdatedProjectData({field: 'website', value: element.target.value})
                                   }}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">AppStore </label>
                        <div className="col-9">

                            <input type={"text"} value={project.appstoreUrl} className={"form-control"}
                                   onChange={element => {
                                       props.setUpdatedProjectData({field: 'appstoreUrl', value: element.target.value})
                                   }}/>

                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Google Play </label>
                        <div className="col-9">

                            <input type={"text"} value={project.googlePlayUrl} className={"form-control"}
                                   onChange={element => {
                                       props.setUpdatedProjectData({
                                           field: 'googlePlayUrl',
                                           value: element.target.value
                                       })
                                   }}/>

                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">URL для получения дополнительной
                            информации</label>
                        <div className="col-9">

                            <input type={"text"} value={project.additionalTextUrl} className={"form-control"}
                                   onChange={element => {
                                       props.setUpdatedProjectData({
                                           field: 'additionalTextUrl',
                                           value: element.target.value
                                       })
                                   }}/>

                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Команда проекта </label>
                        <div className="col-9">

                            <TeamSelector selectedTeamId={project.teamId} onSelect={(team) => {
                                console.log('TeamSelector.teamId', team.id)
                                props.setUpdatedProjectData({field: 'teamId', value: team.id})
                            }}/>

                        </div>
                    </div>


                    <button onClick={submitUpdatedProjectData} className={"btn btn-success btn-sm"}>
                        Обновить данные проекта
                    </button>
                    <br/>
                    <br/>
                    <button onClick={() => {
                        if (confirm("Удаляем?")) {
                            props.deleteProject(projectId)
                        }
                    }} className={"btn btn-sm btn-sm-remove btn-danger"}>
                        удалить проект
                    </button>

                </div>
            </div>
        </div>
    </div>)
};

const mapStateToProps = function (state) {
    return {
        projectUpdateState: state.projectUpdate,
    }
}

ProjectEditPage.propTypes = {
    deleteProject: PropTypes.func,
    fetchProjectData: PropTypes.func,
    saveUpdatedProjectData: PropTypes.func,
    setUpdatedProjectData: PropTypes.func,
    projectUpdateState: PropTypes.object,
    match: PropTypes.object,
}

export default connect(mapStateToProps, {
    setUpdatedProjectData,
    saveUpdatedProjectData,
    fetchProjectData,
    deleteProject,
})(ProjectEditPage);
