import {createAction} from "redux-actions";

export const intentActionChangeType = createAction('intentActionChangeType');
export const intentActionCreate = createAction('intentActionCreate');
export const intentActionUpdateField = createAction('intentActionUpdateField');
export const intentActionDelete = createAction('intentActionDelete');
export const intentActionUpdateParamsField = createAction('intentActionUpdateParamsField');

export const intentActionTransitionCreate = createAction('intentActionTransitionCreate');
export const intentActionTransitionDelete = createAction('intentActionTransitionDelete');
export const intentActionTransitionUpdateField = createAction('intentActionTransitionUpdateField');
