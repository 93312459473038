import { connect } from "react-redux";
import { logout } from "../modules/login/reducers/currentUser";
import React from "react";
var Logo = function (props) {
    var imgLogoSrc;
    if (props.currentUserState.instanceLogoUrl) {
        imgLogoSrc = props.currentUserState.instanceLogoUrl;
    }
    else {
        imgLogoSrc = require('../media/boord-logo2.png');
    }
    return React.createElement("div", { className: "logo" },
        React.createElement("img", { alt: "", src: imgLogoSrc }));
};
var mapStateToProps = function (state) {
    return {
        currentUserState: state.currentUser,
    };
};
export default connect(mapStateToProps, {
    logout: logout
})(Logo);
