import {requests} from "../../../utils/requests";

const Projects = {
    list: () => requests.get('/api/projects'),
    get: (projectId) => requests.get('/api/projects/' + projectId),
    delete: (projectId) => requests.delete('/api/projects/' + projectId),
    create: (requestData) => requests.post('/api/projects', requestData),
    update: (id, requestData) => requests.post('/api/projects/' + id, requestData)
};

export default Projects
