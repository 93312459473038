import React from "react";
import {Outlet} from "react-router-dom";
import '../styles/BoordLayout.scss'
import {connect} from "react-redux";

const BoordLayout = (props) => {
    return (<div className={"boord-wrapper"}>
        <Outlet/>
    </div>)
}

export default connect()(BoordLayout);

