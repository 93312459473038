import CustomEditor from "../../../components/CustomEditor/CustomEditor";
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { resetEditModeAction, setEditableContentAction, updateContentNextContentButtonTitleAction, updateContentParentIdAction, } from "../actions/main";
import { listContent, createOrUpdateContent } from "../api/content";
import { Form } from "react-bootstrap";
import { recordToArray } from "../../../utils/arrayUtils";
import ConditionsEdit from "./ConditionsEdit";
import { remove } from "../reducers/list";
import Select from "react-select";
var ContentEditModeComponent = function (props) {
    var contentId = props.boordEditState.id;
    var parentId = props.boordEditState.parentId;
    var isNewContent = props.boordEditState.isNew;
    var _a = useState(props.boordEditState.content), editorData = _a[0], setEditorData = _a[1];
    var conditionsFromState = useSelector(function (state) {
        return state.boordEditCondition.conditions;
    });
    var saveChanges = function () {
        var requestData = {
            content: JSON.stringify(editorData),
            menuTitle: props.boordEditState.menuTitle,
            parentId: props.boordEditState.parentId,
            nextContentButtonTitle: props.boordEditState.nextContentButtonTitle,
            conditions: recordToArray(conditionsFromState),
        };
        var contentItemId = props.boordEditState.id;
        if (isNewContent) {
            contentItemId = null;
        }
        props.createOrUpdateContent(contentItemId, requestData);
    };
    var cancelEdit = function () {
        // props.setActive(contentId)
        props.resetEditModeAction();
        // props.setEditableContent({id: null} as ContentItem)
    };
    var removeContent = function () {
        props.remove(props.boordEditState.id);
    };
    var parentContentOptions = [];
    var selectedParent;
    var contentList = Object.values(props.boordListState.items).filter(function (item) { return item.parentId == null; });
    contentList.map(function (contentItem) {
        if (contentItem.id === props.boordEditState.id) {
            return;
        }
        var id = contentItem.id;
        var label = contentItem.menu.title;
        var newItem = { value: id, label: label };
        parentContentOptions.push(newItem);
        if (id == parentId) {
            selectedParent = newItem;
        }
    });
    return React.createElement("div", { className: "content-editor-container" },
        props.boordEditState.savingInProgress && React.createElement("div", null, "progress"),
        React.createElement("button", { onClick: cancelEdit, className: "btn btn-success" }, "\u043E\u0442\u043C\u0435\u043D\u0438\u0442\u044C \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F"),
        React.createElement("div", null,
            React.createElement(Select, { placeholder: "Раздел", key: contentId + '_parent', onChange: function (select) {
                    if (select === null || select === void 0 ? void 0 : select.value) {
                        props.updateContentParentIdAction(select.value);
                    }
                }, value: selectedParent, options: parentContentOptions })),
        React.createElement("div", null,
            React.createElement(CustomEditor, { onChange: function (data) {
                    setEditorData(data.contentData);
                }, editorId: 'ad', initialValue: props.boordEditState.content })),
        React.createElement(Form.Group, { className: "mb-3", controlId: "formBasicEmail" },
            React.createElement(Form.Label, null, "Next button label"),
            React.createElement(Form.Control, { type: "text", value: props.boordEditState.nextContentButtonTitle ? props.boordEditState.nextContentButtonTitle : '', onChange: function (event) {
                    props.updateContentNextContentButtonTitleAction(event.target.value);
                }, placeholder: "Enter next button" })),
        React.createElement(ConditionsEdit, null),
        React.createElement("div", null,
            React.createElement("button", { onClick: saveChanges }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F"),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("button", { onClick: removeContent, className: "btn btn-xs" }, "\u0443\u0434\u0430\u043B\u0438\u0442\u044C")));
};
var mapStateToProps = function (state) {
    return {
        boordEditState: state.boordEdit,
        boordListState: state.boordList
    };
};
export default connect(mapStateToProps, {
    setEditableContent: setEditableContentAction,
    listContent: listContent,
    remove: remove,
    createOrUpdateContent: createOrUpdateContent,
    updateContentNextContentButtonTitleAction: updateContentNextContentButtonTitleAction,
    updateContentParentIdAction: updateContentParentIdAction,
    resetEditModeAction: resetEditModeAction
})(ContentEditModeComponent);
