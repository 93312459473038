import { requestGET, requestPOST, requestPUT } from "../../../utils/apiClient";
import { requestUpdateStart, requestUpdateSuccessful, setActiveContentAction, setEditableContentAction } from "../actions/main";
import { setConditionsForEdit } from "../reducers/editCondition";
import { setActive, setList, startLoading } from "../reducers/list";
export var Actions = {
    SET_ACTIVE_CONTENT: 'SET_ACTIVE_CONTENT',
    SET_EDIT_CONTENT: 'SET_EDIT_CONTENT',
    RESET_EDIT_MODE: 'RESET_EDIT_MODE',
    UPDATE_CONTENT_MENU_TITLE: 'UPDATE_CONTENT_MENU_TITLE',
    UPDATE_CONTENT_PARENT_ID: 'UPDATE_CONTENT_PARENT_ID',
    UPDATE_CONTENT_NEXT_CONTENT_BUTTON_TITLE: 'UPDATE_CONTENT_NEXT_CONTENT_BUTTON_TITLE',
    REQUEST_UPDATE_SUCCESSFUL: 'REQUEST_UPDATE_SUCCESSFUL',
    REQUEST_UPDATE_START: 'REQUEST_UPDATE_START',
    REQUEST_UPDATE_ERROR: 'REQUEST_UPDATE_ERROR',
};
export var saveOrder = function (contentOrderData) { return function (dispatch) {
    requestPOST('/api/boord/content/reorder', contentOrderData)
        .then(function (data) {
    })
        .catch(function (error) {
    });
}; };
export var listContent = function () { return function (dispatch) {
    dispatch(startLoading());
    requestGET('/api/boord/contents')
        .then(function (contentCollection) {
        dispatch(setList(contentCollection));
        if (contentCollection.data.length) {
            var contentList = contentCollection.data;
            var firstContent = contentList
                .filter(function (item) { return item.parentId == null; })
                .sort(function (a, b) {
                if (a.orderPosition < b.orderPosition)
                    return -1;
                if (a.orderPosition > b.orderPosition)
                    return 1;
                return 0;
            })[0];
            dispatch(setActive(firstContent.id));
        }
    })
        .catch(function (error) {
        console.log(error);
        alert('error request ex list');
    });
}; };
export var createOrUpdateContent = function (contentId, contentData) { return function (dispatch) {
    dispatch(requestUpdateStart());
    var url = '/api/boord/content';
    var method = requestPOST;
    if (contentId) {
        method = requestPUT;
        url = '/api/boord/content/' + contentId;
    }
    method(url, contentData)
        .then(function (contentItem) {
        dispatch(requestUpdateSuccessful(contentItem));
        dispatch(setActiveContentAction(contentItem));
        dispatch(setEditableContentAction(contentItem));
        dispatch(setConditionsForEdit(contentItem));
    })
        .catch(function (error) {
        console.log(error);
        alert('error request ex list');
    });
}; };
