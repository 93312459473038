var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFocused, useSelected, useSlate } from "slate-react";
import Select from "react-select";
import React from "react";
import { Transforms } from "slate";
export var TeamListEditorElement = function (props) {
    var attributes = props.attributes;
    var element = props.element;
    var elementId = element.id;
    var selected = useSelected();
    var focused = useFocused();
    var editor = useSlate();
    var advancedGroup = { value: '75cd0f63-8e9e-4deb-aac6-0448157f815c', label: 'CrossFit Advanced' };
    var advancedGroup2 = { value: '75cd0f63-8e9e-4de1-aac6-0448157f815c', label: 'CrossFit2 Advanced' };
    var onChange = function (event) {
        Transforms.setNodes(editor, 
        // @ts-ignore
        { defaultSelectionMode: event.value }, {
            // This path references the editor, and is expanded to a range that
            // will encompass all the content of the editor.
            at: [],
            // This only matches text nodes that are not already italic.
            // @ts-ignore
            match: function (node, path) { return node.id == elementId; },
        });
    };
    var options = [
        advancedGroup,
        advancedGroup2,
    ];
    return (React.createElement("div", __assign({}, attributes, { contentEditable: false, style: {
            padding: '3px 3px 2px',
            margin: '0 1px',
            verticalAlign: 'baseline',
            borderRadius: '4px',
            backgroundColor: '#eee',
            fontSize: '0.9em',
            boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        } }),
        React.createElement("form", { autoComplete: "false" },
            React.createElement(Select, { id: "defaultTeam", onChange: onChange, name: "defaultTeam", options: options }))));
};
export var withTeamList = function (editor) {
    var isInline = editor.isInline, isVoid = editor.isVoid;
    editor.isInline = function (element) {
        return element.type === 'team' ? false : isInline(element);
    };
    editor.isVoid = function (element) {
        return element.type === 'team' ? false : isVoid(element);
    };
    return editor;
};
