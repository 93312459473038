import {createAction} from "redux-actions";
import history from "../../../utils/history";
import Members from "../api/members";


export const setNewMemberData = createAction('setNewMemberData');

export const requestSaveNewMemberDataSuccess = createAction('requestSaveNewMemberDataSuccess');
export const saveNewMemberData = (memberData) => dispatch => {
    return Members.create(memberData)
        .then(data => {
            dispatch(requestSaveNewMemberDataSuccess(data));
        })
        .catch(() => {
        });
}
