import {combineReducers} from 'redux';
import authLink from '../modules/login/reducers/authLink'
import auth from '../modules/login/reducers/authReducer'
import currentUser from '../modules/login/reducers/currentUser'
import {connectRouter} from 'connected-react-router'
import memberCreateProfile from "../modules/members/reducers/create";
import memberProfile from "../modules/members/reducers/memberProfileReducer";
import membersList from "../modules/members/reducers/membersListReducer";
import projectList from "../modules/projects/reducers/list";
import projectCreate from "../modules/projects/reducers/create";
import projectUpdate from "../modules/projects/reducers/update";
import teamList from "../modules/teams/reducers/list";
import teamCreate from "../modules/teams/reducers/create";
import teamUpdate from "../modules/teams/reducers/update";
import intentProfile from "../modules/intents/reducers/intentReducer";
import intentCreate from "../modules/intents/reducers/create";
import intentsList from "../modules/intents/reducers/intentsListReducer";
import botVersion from "../modules/bot/reducers/botVersionReducer";
import botCommandMenu from "../modules/bot/reducers/botCommandMenuReducer";
import botInfo from "../modules/bot/reducers/botInfoReducer";
import notificationList from "../modules/notification/reducers/listReducer";
import boordEdit from "../modules/boord/reducers/edit";
import boordList from "../modules/boord/reducers/list";
import editCondition from "../modules/boord/reducers/editCondition";
//

const rootReducer = history => combineReducers({
    router: connectRouter(history),
    auth,
    currentUser: currentUser,
    memberList: membersList,
    memberProfile: memberProfile,
    memberCreateProfile: memberCreateProfile,
    projectsList: projectList,
    projectCreate: projectCreate,
    projectUpdate: projectUpdate,
    teamsList: teamList,
    teamCreate: teamCreate,
    teamUpdate: teamUpdate,
    intentsList: intentsList,
    intentProfile: intentProfile,
    intentCreate: intentCreate,
    botVersion: botVersion,
    botCommandMenu: botCommandMenu,
    botInfo: botInfo,
    notificationList: notificationList,
    boordEdit: boordEdit,
    boordList: boordList,
    boordEditCondition: editCondition,
    authLink: authLink,
});

export default rootReducer;
