import {createAction} from "redux-actions";
import Members from "../api/members";

export const profileMemberRequest = createAction('profileMemberRequest');
export const profileMemberSuccess = createAction('profileMemberSuccess');
export const profileMemberFailure = createAction('profileMemberFailure');

export const loadMember = (memberId) => dispatch => {
    dispatch(profileMemberRequest());
    return Members.getProfile(memberId)
        .then(data => {
            dispatch(profileMemberSuccess(data));
        })
        .catch(() => {
            dispatch(profileMemberFailure());
        });
};

export const requestUpdateMemberDataSuccess = createAction('requestUpdateMemberDataSuccess');
export const requestUpdateMemberDataStart = createAction('requestUpdateMemberDataStart');
export const requestUpdateMemberDataError = createAction('requestUpdateMemberDataError');
export const saveUpdatedMemberData = (memberId, memberData) => dispatch => {
    dispatch(requestUpdateMemberDataStart());
    return Members.update(memberId, memberData)
        .then(data => {
            dispatch(requestUpdateMemberDataSuccess(data));
        })
        .catch(() => {
            dispatch(requestUpdateMemberDataError());
        });
}

export const updateMemberData = createAction('updateMemberData');
export const updateMemberContactType = createAction('updateMemberContactType');
export const updateMemberContact = createAction('updateMemberContact');
export const deleteMemberContact = createAction('deleteMemberContact');
export const addMemberContact = createAction('addMemberContact');
export const attachProjectToMember = createAction('attachProjectToMember');
export const detachProjectFromMember = createAction('detachProjectFromMember');
