import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {
    addNewAnswer,
    createIntentQuery,
    deleteIntentQuery,
    getById,
    removeAnswer,
    updateAnswerInTextIntent,
    updateIntentData,
    updateIntentQuery
} from "../../../actions/intent";
import {intentActionUpdateField} from "../actions/main";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {BotEditor} from "../../../components/BotEditor";


const TextIntentAction = (props) => {

    const action = props.action


    const [answers, setAnswers] = useState([]);

    const renderAnswers = []
    useEffect(() => {
        let answers = {}
        if (action.params.answers) {
            Object.values(action.params.answers).forEach(item => {
                answers = {
                    ...answers, ...{
                        [item.id]: {
                            id: item.id,
                            text: item.text,
                        }
                    }
                }
            });
        }
        setAnswers(answers)
    }, [])

    const createAnswer = () => {
        const newAnswerId = Math.random().toString(36).substring(7)
        const newAnswer = {
            id: newAnswerId,
            text: '',
        }
        const copyAnswers = {...answers, ...{[newAnswerId]: newAnswer}}
        updateAnswers(copyAnswers)
    }

    const updateAnswers = (newAnswers) => {
        setAnswers(newAnswers)
        props.intentActionUpdateField({id: action.id, field: 'params', value: {answers: newAnswers}})
    }

    const initAnswerField = (answer) => {
        return (
            <div key={"answer_" + answer.id} className={"answer-item"}>

                <BotEditor
                    initialEditorData={answer.text}
                    onTextChange={v => {
                        console.log('v.editorData', v.editorData)
                        const newAnswers = {
                            ...answers, ...{
                                [answer.id]: {
                                    id: answer.id,
                                    text: v.editorData
                                }
                            }
                        }
                        updateAnswers(newAnswers)
                    }}/>

                <div style={{margin: 5}}>
                    <a className={"del-button"}
                       style={{color: '#999', fontSize: 12, cursor: 'pointer', margin: "5px 0 10px 0"}}
                       onClick={() => {
                           const newAnswers = {...answers}
                           delete newAnswers[answer.id]
                           updateAnswers(newAnswers)
                       }}>[x] удалить этот вариант ответа
                    </a>
                </div>
            </div>
        )
    }

    Object.values(answers).map(item => {
        renderAnswers.push(initAnswerField(item))
    })

    return (
        <div className={"text-intent-settings"}>
            <div className=""><b>Ответ для пользователя:</b></div>

            <div>
                {renderAnswers}
                <div>
                    <button className={"btn btn-sm btn-info"} onClick={createAnswer}>Добавить вариант ответа
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        intentState: state.intentProfile,
    }
}

export default connect(mapStateToProps, {
    getById,
    updateIntentData,
    updateAnswerInTextIntent,
    addNewAnswer, removeAnswer,
    updateIntentQuery,
    createIntentQuery,
    deleteIntentQuery,
    intentActionUpdateField
})(TextIntentAction);
