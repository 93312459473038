import { connect } from "react-redux";
import { useEffect } from "react";
import React from "react";
import { useParams, Navigate } from "react-router-dom";
import '../styles/Auth.scss';
import { TableSpinner } from "../../../components/CustomSpinner";
import { authByLink } from "../actions/authNew";
var AuthLinkPage = function (props) {
    var authCode = useParams().authCode;
    console.log('props.authLinkState', props.authLinkState);
    console.log('props.currentUserState', props.currentUserState);
    if (props.currentUserState.id) {
        return React.createElement(Navigate, { to: "/" });
    }
    useEffect(function () {
        props.authByLink(authCode);
    }, []);
    return (React.createElement("div", { className: "auth-link-container" },
        props.authLinkState.inProgress && React.createElement("div", null,
            React.createElement(TableSpinner, null)),
        props.authLinkState.isFailure && React.createElement("div", null,
            React.createElement("h1", null, "\u0423\u043F\u0441, \u043A\u0430\u0436\u0435\u0442\u0441\u044F, \u043F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043A\u0430\u043A\u0430\u044F-\u0442\u043E \u043E\u0448\u0438\u0431\u043A\u0430 :("),
            React.createElement("p", null,
                "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u0447\u0435\u0440\u0435\u0437 ",
                React.createElement("a", { href: "/login" }, "\u0444\u043E\u0440\u043C\u0443 \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u0438"),
                "."))));
};
var mapStateToProps = function (state) {
    return {
        authLinkState: state.authLink,
        boordCreateState: state.boordCreate,
        currentUserState: state.currentUser,
    };
};
export default connect(mapStateToProps, {
    authByLink: authByLink
})(AuthLinkPage);
