import { connect } from "react-redux";
import { setActive } from "../reducers/list";
import React from "react";
var getFirstNotPassedContent = function (rootContents, passedContents) {
    for (var key in rootContents) {
        var content = rootContents[key];
        var isPassed = passedContents[content.id];
        if (!isPassed) {
            return content;
        }
        else {
            if (content.children.length) {
                var childResult = getFirstNotPassedContent(content.children, passedContents);
                if (childResult) {
                    return childResult;
                }
            }
        }
    }
    return null;
};
var BlockedContent = function (props) {
    var contents = Object.values(props.boordListState.items)
        .filter(function (item) { return item.parentId == null; })
        .sort(function (a, b) {
        if (a.orderPosition < b.orderPosition)
            return -1;
        if (a.orderPosition > b.orderPosition)
            return 1;
        return 0;
    });
    var notPassedContent = getFirstNotPassedContent(contents, props.boordListState.passedContents);
    if (!notPassedContent) {
        return React.createElement("div", { className: "blocked-step" },
            React.createElement("h3", null, "\u041E\u0439, \u043A\u0430\u0436\u0435\u0442\u0441\u044F \u043F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430 \u0441 \u043F\u043E\u0438\u0441\u043A\u043E\u043C \u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u0430..."));
    }
    return React.createElement("div", { className: "blocked-step" },
        React.createElement("h3", null, "\u041E\u0439, \u0430 \u0441\u044E\u0434\u0430 \u0435\u0449\u0451 \u043D\u0435\u043B\u044C\u0437\u044F \uD83E\uDDD0"),
        React.createElement("div", { className: "description" }, "\u042D\u0442\u043E\u0442 \u043E\u043D\u0431\u043E\u0440\u0434\u0438\u043D\u0433 \u043D\u0443\u0436\u043D\u043E \u043F\u0440\u043E\u0439\u0442\u0438 \u043F\u043E\u0448\u0430\u0433\u043E\u0432\u043E - \u043D\u043E\u0432\u044B\u0435 \u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u044B \u0431\u0443\u0434\u0443\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B \u043F\u043E\u0441\u043B\u0435 \u043E\u0437\u043D\u0430\u043A\u043E\u043C\u043B\u0435\u043D\u0438\u044F \u0441 \u043F\u0440\u0435\u0434\u044B\u0434\u0443\u0449\u0438\u043C\u0438."),
        React.createElement("div", { className: "go-next-button-container" },
            React.createElement("button", { className: "btn btn-next-step", onClick: function () {
                    // goToNextItem(activeContent)
                    props.setActive(notPassedContent.id);
                } },
                "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u00AB",
                notPassedContent.menu.title,
                "\u00BB")));
};
var mapStateToProps = function (state) {
    return {
        boordListState: state.boordList,
    };
};
export default connect(mapStateToProps, {
    setActive: setActive
})(BlockedContent);
