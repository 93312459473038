import { getSession } from "../modules/login/helpers/authSessionStorage";
export function requestGET(url) {
    var requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('AuthToken', getSession());
    return fetch(url, {
        method: 'GET',
        headers: requestHeaders
    })
        .then(function (response) {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
}
var RequestError = /** @class */ (function () {
    function RequestError(response) {
        this.response = response;
    }
    RequestError.prototype.json = function () {
        return this.response.json();
    };
    return RequestError;
}());
export { RequestError };
export function requestPOST(url, data) {
    var requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('AuthToken', getSession());
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: requestHeaders
    }).then(function (response) {
        if (!response.ok) {
            throw new RequestError(response);
        }
        return response.json();
    });
}
export function requestFormData(url, data) {
    var requestHeaders = new Headers();
    requestHeaders.set('AuthToken', getSession());
    return fetch(url, {
        method: 'POST',
        body: data,
        headers: requestHeaders
    }).then(function (response) {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
}
export function requestPUT(url, data) {
    var requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('AuthToken', getSession());
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: requestHeaders
    }).then(function (response) {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
}
