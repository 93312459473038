import { saveSession } from "../helpers/authSessionStorage";
import { setData } from "../reducers/currentUser";
import { failure, progress, success } from "../reducers/authLink";
import { requestPOST } from "../../../utils/apiClient";
import { checkAuth } from "../api/google";
export var successAuthHandler = function (authSession, dispatch) {
    saveSession(authSession.sessionId);
    dispatch(setData(authSession.user));
};
export var checkGoogleAuth = function (code, state) { return function (dispatch) {
    checkAuth(code, state, function (success) {
        successAuthHandler(success, dispatch);
        window.location.href = '/';
    }, function (error) {
        if (error.errorCode == 'memberNotFound') {
            alert('Пользователь с таким аккаунтом не найден :(');
        }
        else {
            alert('Произошла ошибка при авторизации через Google аккаунт :/');
        }
        window.location.href = '/login';
    });
}; };
export var authByLink = function (authCode) { return function (dispatch) {
    var requestData = {
        code: authCode
    };
    dispatch(progress());
    requestPOST('/api/auth-link', requestData)
        .then(function (authSession) {
        successAuthHandler(authSession, dispatch);
        dispatch(success());
    })
        .catch(function (error) {
        dispatch(failure());
    });
}; };
