var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { ConditionType } from "../models/content";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { savePassedMark } from "../api/steps";
import { connect } from "react-redux";
import { setEditableContentAction } from "../actions/main";
import { listContent } from "../api/content";
import { setConditionsForEdit } from "../reducers/editCondition";
import { markAsPassed, setActive, updateActiveContentCondition } from "../reducers/list";
import { Checkbox } from "@material-ui/core";
var getNextItem = function (currentContent, contents) {
    var nextCandidatesList = [];
    var nextContentMinOrderPosition = currentContent.orderPosition;
    console.log('currentContent.children', currentContent.children);
    if (currentContent.parentId) {
        var parentContent = contents[currentContent.parentId];
        nextCandidatesList = parentContent.children;
        // делаем из рид-онли объекта копию для дальнейшей сортировки
        nextCandidatesList = __assign({}, nextCandidatesList);
        nextCandidatesList = Object.values(nextCandidatesList);
        if (nextCandidatesList.length && nextCandidatesList[nextCandidatesList.length - 1].id == currentContent.id) {
            // достигнут последний элемент в дочерних элементах - переключаемся на корневые элементы
            nextContentMinOrderPosition = parentContent.orderPosition;
            nextCandidatesList = Object.values(contents).filter(function (item) { return item.parentId == null; });
        }
    }
    else if (currentContent.children && currentContent.children.length) {
        nextContentMinOrderPosition = -1;
        // делаем из рид-онли объекта копию для дальнейшей сортировки
        nextCandidatesList = __assign({}, currentContent.children);
        nextCandidatesList = Object.values(nextCandidatesList);
    }
    else {
        nextCandidatesList = Object.values(contents).filter(function (item) { return item.parentId == null; });
    }
    nextCandidatesList.sort(function (a, b) {
        if (a.orderPosition < b.orderPosition)
            return -1;
        if (a.orderPosition > b.orderPosition)
            return 1;
        return 0;
    });
    return nextCandidatesList.find(function (item) { return item.orderPosition > nextContentMinOrderPosition; });
};
var ConditionsView = function (props) {
    var _a;
    var nextStepItemConditions = [];
    var activeContent = props.boordListState.activeContent;
    var foundNextItem = getNextItem(activeContent, props.boordListState.items);
    (_a = activeContent.nextStep.conditions) === null || _a === void 0 ? void 0 : _a.map(function (conditionItem) {
        if (conditionItem.type == ConditionType.Checkbox) {
            var isChecked = conditionItem.selectedCheckbox;
            nextStepItemConditions.push(React.createElement(Form.Group, { className: "condition-item", key: conditionItem.id + '_key_checkbox', controlId: conditionItem.id + 'checkbox' },
                React.createElement("label", null,
                    React.createElement(Checkbox, { checked: isChecked, onChange: function (event) {
                            var payload = {
                                conditionId: conditionItem.id,
                                selectedCheckbox: event.target.checked
                            };
                            props.updateActiveContentCondition(payload);
                        } }),
                    conditionItem.label)));
        }
        if (conditionItem.type == ConditionType.Dropdown) {
            var groupOptions = [];
            var selectedOption = null;
            var currentDropdownValue = conditionItem.selectedDropdownValues[0];
            for (var valueId in conditionItem.values) {
                var valueItem = conditionItem.values[valueId];
                var newItem = { value: valueItem.id, label: valueItem.label };
                groupOptions.push(newItem);
                if (currentDropdownValue == valueItem.id) {
                    selectedOption = newItem;
                }
            }
            nextStepItemConditions.push(React.createElement("div", { className: "condition-item", key: conditionItem.id + '_key_checkbox' },
                React.createElement("label", null, conditionItem.label),
                React.createElement("div", { className: "field" },
                    React.createElement(Select, { placeholder: "Выберите из списка", key: conditionItem.id + '_key_dropdown', onChange: function (select) {
                            if (select === null || select === void 0 ? void 0 : select.value) {
                                var payload = {
                                    conditionId: conditionItem.id,
                                    selectedDropdownValue: select.value
                                };
                                props.updateActiveContentCondition(payload);
                            }
                        }, value: selectedOption, options: groupOptions }))));
        }
    });
    function goToNextItem(currentContent, nextItem) {
        var _a;
        var failedCondition = [];
        var failedConditionAlert = [];
        (_a = currentContent.nextStep.conditions) === null || _a === void 0 ? void 0 : _a.map(function (condition) {
            if (condition.type == ConditionType.Checkbox) {
                var isChecked = condition.selectedCheckbox;
                if (!isChecked) {
                    failedCondition.push(condition);
                    failedConditionAlert.push('Поставьте галочку «' + condition.label + '»');
                }
            }
            if (condition.type == ConditionType.Dropdown) {
                var currentValue = condition.selectedDropdownValues.length ? condition.selectedDropdownValues[0] : null;
                if (!currentValue) {
                    failedCondition.push(condition);
                    failedConditionAlert.push('Сделайте выбор в поле «' + condition.label + '»');
                }
            }
        });
        if (failedCondition.length) {
            alert(failedConditionAlert.join('\n'));
            return;
        }
        var requestData = {
            contentId: currentContent.id,
            conditions: []
        };
        currentContent.nextStep.conditions.map(function (condition) {
            var passedCondition = {
                id: condition.id,
            };
            if (condition.type == ConditionType.Dropdown && condition.selectedDropdownValues.length) {
                passedCondition.valueId = condition.selectedDropdownValues[0];
            }
            requestData.conditions.push(passedCondition);
        });
        props.scrollToContentCallback();
        props.markAsPassed(currentContent);
        props.savePassedMark(requestData);
        props.setActive(nextItem.id);
    }
    if (!foundNextItem) {
        var isPassed = props.boordListState.passedContents[activeContent.id];
        var requestData = {
            contentId: activeContent.id,
            conditions: []
        };
        if (!isPassed) {
            props.markAsPassed(activeContent);
            props.savePassedMark(requestData);
        }
        return React.createElement(React.Fragment, null);
    }
    else {
        var nextStepButtonTitle = activeContent.nextStep.buttonTitle ? activeContent.nextStep.buttonTitle : 'Далее';
        return (React.createElement("div", { className: "transition-container" },
            nextStepItemConditions.length > 0 && React.createElement("div", { className: "conditions" }, nextStepItemConditions),
            React.createElement("div", { className: "go-next-button-container" },
                React.createElement("button", { className: "btn btn-next-step", onClick: function () {
                        goToNextItem(activeContent, foundNextItem);
                    } }, nextStepButtonTitle))));
    }
};
var mapStateToProps = function (state) {
    return {
        boordListState: state.boordList,
    };
};
export default connect(mapStateToProps, {
    setEditableContent: setEditableContentAction,
    listContent: listContent,
    setConditionsForEdit: setConditionsForEdit,
    savePassedMark: savePassedMark,
    setActive: setActive,
    updateActiveContentCondition: updateActiveContentCondition,
    markAsPassed: markAsPassed
})(ConditionsView);
