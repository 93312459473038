import { createAction } from "redux-actions";
import { Actions } from "../api/content";
import { requestGET } from "../../../utils/apiClient";
import { setChildItems } from "../reducers/list";
export var setActiveContentAction = createAction(Actions.SET_ACTIVE_CONTENT);
export var setActiveContentAction2 = function (parentId) { return function (dispatch) {
    requestGET('/api/boord/contents?parentId=' + parentId)
        .then(function (contentCollection) {
        dispatch(setChildItems({ parentId: parentId, items: contentCollection.data }));
    })
        .catch(function (error) {
        console.log(error);
        alert('error request setActiveContentAction2');
    });
}; };
export var setEditableContentAction = createAction(Actions.SET_EDIT_CONTENT);
export var resetEditModeAction = createAction(Actions.RESET_EDIT_MODE);
export var updateContentMenuTitleAction = createAction(Actions.UPDATE_CONTENT_MENU_TITLE);
export var updateContentNextContentButtonTitleAction = createAction(Actions.UPDATE_CONTENT_NEXT_CONTENT_BUTTON_TITLE);
export var updateContentParentIdAction = createAction(Actions.UPDATE_CONTENT_PARENT_ID);
export var requestUpdateStart = createAction(Actions.REQUEST_UPDATE_START);
export var requestUpdateSuccessful = createAction(Actions.REQUEST_UPDATE_SUCCESSFUL);
export var requestUpdateError = createAction(Actions.REQUEST_UPDATE_ERROR);
