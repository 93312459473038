import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {loadIntents} from "../../../actions/intent";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";

const IntentsListPage = (props) => {

    useEffect(() => {
        props.loadIntents()
    }, []);

    const intents = props.intentsListState.intents.map(item => {
        const queries = []
        item.queries.map(query => {
            queries.push(<div>{query.sentence}</div>)
        })
        let intentListItemClassName = ''
        console.log('item.isActive', item.isActive)
        if (!item.isActive) {
            intentListItemClassName = 'disabled';
        }
        return (<tr className={intentListItemClassName}>
            <td>
                <Link to={"/intents/" + item.id}>{item.name}</Link>
                <div style={{margin: 5, fontSize: 12, color: '#666'}}>
                    {item.botCommand}
                </div>
            </td>
            <td>
                {item.className}
                <div>
                    {queries}
                </div>
            </td>
        </tr>)
    })

    return (<div>
        <Link to={"/intents/create"}>create intent</Link>
        <Table striped bordered hover size="sm">
            <tbody>
            {intents}
            </tbody>
        </Table></div>)
};

const mapStateToProps = function (state) {
    return {
        intentsListState: state.intentsList,
    }
}

export default connect(mapStateToProps, {
    loadIntents
})(IntentsListPage);
