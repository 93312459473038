import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from "react-bootstrap";
import { getProfile } from "../api/team";
import { MemberProfile } from "./MemberProfile";
export var MemberInlineProfile = function (props) {
    var popover = (React.createElement(Popover, { id: "popover-basic" },
        React.createElement(MemberData, { memberId: props.mentionItem.mentionId })));
    return React.createElement(OverlayTrigger, { rootClose: true, trigger: "click", placement: "auto", overlay: popover },
        React.createElement("span", { className: "mention-item" }, props.mentionItem.title));
};
var MemberData = function (props) {
    var _a = useState(null), memberProfile = _a[0], setMemberProfile = _a[1];
    useEffect(function () {
        getProfile(props.memberId, function (profile) {
            setMemberProfile(profile);
        });
    }, []);
    var renderProfile = function () {
        return React.createElement(React.Fragment, null,
            React.createElement(MemberProfile, { member: memberProfile }));
    };
    return React.createElement("div", { className: "member-profile-popup" },
        React.createElement(Popover.Body, null, memberProfile && renderProfile()));
};
