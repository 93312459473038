var AppUrl = /** @class */ (function () {
    function AppUrl(routerRule, publicUrl) {
        this.routerRule = routerRule;
        this.publicUrl = publicUrl;
    }
    AppUrl.prototype.getPublicUrl = function () {
        return this.publicUrl;
    };
    AppUrl.prototype.getRouterRule = function () {
        return this.routerRule;
    };
    return AppUrl;
}());
export { AppUrl };
