import {handleActions} from 'redux-actions';
import {requestBotInfoSuccess, setBotInfoFieldValue} from "../actions/bot_data";

const initValues = {
    botInfo: {
        telegramBotToken: null,
    },
}
export default handleActions(
    {
        [requestBotInfoSuccess]: (state, action) => {
            return {
                ...state,
                botInfo: {
                    telegramBotToken: action.payload.telegramBotToken,
                },
            };
        },
        [setBotInfoFieldValue]: (state, action) => {
            const fieldName = action.payload.field
            const fieldValue = action.payload.value
            return {
                ...state,
                botInfo: {...state.botInfo, ...{[fieldName]: fieldValue}},
            };
        },
    },
    initValues,
);

