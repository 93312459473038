import DatePicker from "react-datepicker";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";

export const ProjectDatePicker = (props) => {

    const initDate = props.timestamp ? new Date(props.timestamp * 1000) : null;
    return <DatePicker
        dateFormat={"dd/MM/yyyy"}
        className={"form-control"}
        selected={initDate}
        onChange={(date) => {
            props.onChange({
                timestamp: date ? date.getTime() / 1000 : null,
                dateObj: date,
            })
        }}/>
}

export const BirthdayDatePicker = (props) => {
    /**
     * Ожидаем props.date в формате yyyy-mm-dd, например: 1989-03-27
     * @type {Date|null}
     */
    const initDate = props.date ? new Date(props.date) : null;
    return <DatePicker
        className={"form-control"}
        dateFormat={"dd/MM/yyyy"}
        selected={initDate}
        onChange={(date) => {
            props.onChange(date)
        }}/>
}
