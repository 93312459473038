import * as React from "react";
import mapboxgl, { Marker } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
export var MapboxMap = function (props) {
    var _a = React.useState(), map = _a[0], setMap = _a[1];
    var mapboxToken = 'pk.eyJ1Ijoic29jaWFsc3lzIiwiYSI6ImNrenMzNTVyMjVuY2MydW8xdmt1b3BzN2QifQ.mz2ISLD6VN9R_uu9G1MOmg';
    var marker = new Marker();
    var markerPosition = [props.lon, props.lat];
    marker.setLngLat(markerPosition).addTo(map);
    // React ref to store a reference to the DOM node that will be used
    // as a required parameter `container` when initializing the mapbox-gl
    // will contain `null` by default
    var mapNode = React.useRef(null);
    React.useEffect(function () {
        var node = mapNode.current;
        // if the window object is not found, that means
        // the component is rendered on the server
        // or the dom node is not initialized, then return early
        if (typeof window === "undefined" || node === null)
            return;
        // otherwise, create a map instance
        var mapboxMap = new mapboxgl.Map({
            container: node,
            accessToken: mapboxToken,
            style: "mapbox://styles/mapbox/streets-v11",
            center: markerPosition,
            zoom: 16,
        });
        // save the map object to React.useState
        setMap(mapboxMap);
        return function () {
            mapboxMap.remove();
        };
    }, []);
    return React.createElement("div", { ref: mapNode, style: { width: "100%", height: "100%" } });
};
