import {Routes, BrowserRouter, Route} from "react-router-dom";

import React, {useEffect, useState} from "react";
import {hot} from "react-hot-loader";
import history from '../utils/history'
import IntentPage from "../modules/intents/components/IntentPage";
import IntentsListPage from "../modules/intents/components/IntentsListPage";
import NewIntentPage from "../modules/intents/components/NewIntentPage";
import MemberProfileCreatePage from "../modules/members/components/MemberProfileCreatePage";
import MemberProfileEditPage from "../modules/members/components/MemberProfileEditPage";
import {Layout} from "../layout/Layout";
import ProjectListPage from "../modules/projects/components/ProjectListPage";
import ProjectEditPage from "../modules/projects/components/ProjectEditPage";
import ProjectCreatePage from "../modules/projects/components/ProjectCreatePage";
import TeamListPage from "../modules/teams/components/TeamListPage";
import TeamEditPage from "../modules/teams/components/TeamEditPage";
import TeamCreatePage from "../modules/teams/components/TeamCreatePage";
import VersionsListPage from "../modules/bot/components/VersionsListPage";
import BotCommandGroupPage from "../modules/bot/components/BotCommandGroupPage";
import BotCommandGroupListPage from "../modules/bot/components/BotCommandGroupListPage";
import AuthPage from "../modules/login/components/AuthPage";
import {checkUserSession} from "../modules/login/actions/auth";
import ListPage from "../modules/notification/components/ListPage";
import CreatePage from "../modules/notification/components/CreatePage";
import EditPage from "../modules/notification/components/EditPage";
import MembersListPage from "../modules/members/components/MembersListPage";
import BoordPage from "../modules/boord/components/BoordPage";
import BoordLayout from "../layout/BoordLayout";
import {AdminLayout} from "../layout/AdminLayout";
import {connect} from "react-redux";
import AuthLinkPage from "../modules/login/components/AuthLinkPage";
import {TableSpinner} from "./CustomSpinner";
import AuthGoogleCheckPage from "../modules/login/components/AuthGoogleCheckPage";
import LoginLayout from "../layout/LoginLayout";
import {membersListUrl} from "../modules/members/UrlFactory";
import {teamCreateUrl, teamProfileUrl, teamsListUrl} from "../modules/teams/UrlFactory";
import {projectCreateUrl, projectProfileUrl, projectsListUrl} from "../modules/projects/UrlFactory";

const App = (props) => {

    const [userChecked, setUserChecked] = useState(false)
    const [error, setErrorMessage] = useState(null)

    const isLoginPage = (window.location.pathname.startsWith('/login'))
    const renderRouterCondition = userChecked || isLoginPage

    useEffect(() => {
        if (!isLoginPage) {
            props.checkUserSession(() => {
                setUserChecked(true)
            }, (err, code) => {
                window.location = '/login'
                // alert(err)
            })
        }
    }, [])

    const renderCheckAuthPage = () => {
        return <div>
            <TableSpinner/>
        </div>
    }

    const renderRouter = () => {
        return (
            <BrowserRouter history={history}>
                <Layout>
                    <Routes>

                        <Route path={"/login"} element={<LoginLayout/>}>
                            <Route exact path="" element={<AuthPage/>}/>
                            <Route exact path="google" element={<AuthGoogleCheckPage/>}/>
                            <Route exact path="link/:authCode" element={<AuthLinkPage/>}/>
                        </Route>

                        <Route path={"/"} element={<BoordLayout/>}>
                            <Route exact path="" element={<BoordPage/>}/>
                        </Route>

                        <Route path={"/panel"} element={<AdminLayout/>}>
                            <Route exact path="members/create" element={<MemberProfileCreatePage/>}/>
                            <Route exact path="members/:memberId" element={<MemberProfileEditPage/>}/>
                            <Route exact path={membersListUrl.getRouterRule()} element={<MembersListPage/>}/>

                            <Route exact path="notifications" element={<ListPage/>}/>
                            <Route exact path="notification/create" element={<CreatePage/>}/>
                            <Route exact path="notification/:notificationId" element={<EditPage/>}/>

                            <Route exact path="intents/create" element={<NewIntentPage/>}/>
                            <Route exact path="intents/:intentId" element={<IntentPage/>}/>
                            <Route exact path="intents" element={<IntentsListPage/>}/>

                            <Route exact path={projectCreateUrl.getRouterRule()} element={<ProjectCreatePage/>}/>
                            <Route exact path={projectProfileUrl(null).getRouterRule()} element={<ProjectEditPage/>}/>
                            <Route exact path={projectsListUrl.getRouterRule()} element={<ProjectListPage/>}/>

                            <Route exact path="bot" element={<VersionsListPage/>}/>
                            <Route exact path="commands" element={<BotCommandGroupListPage/>}/>
                            <Route exact path="commands/:id" element={<BotCommandGroupPage/>}/>

                            <Route exact path={teamCreateUrl.getRouterRule()} element={<TeamCreatePage/>}/>
                            <Route exact path={teamProfileUrl(null).getRouterRule()} element={<TeamEditPage/>}/>
                            <Route exact path={teamsListUrl.getRouterRule()} element={<TeamListPage/>}/>
                        </Route>

                    </Routes>
                </Layout>
            </BrowserRouter>
        )
    }

    if (error) {
        return <div>
            error
        </div>
    } else if (renderRouterCondition) {
        return renderRouter()
    } else {
        return renderCheckAuthPage()
    }
}

const mapStateToProps = function (state) {
    return {
        currentUserState: state.currentUser,
    }
}

export default connect(mapStateToProps, {
    checkUserSession
})(hot(module)(App));
