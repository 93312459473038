import {createAction} from "redux-actions";
import Projects from "../api/main";

export const deleteProjectSuccess = createAction('deleteProjectSuccess');
export const deleteProject = (projectId) => dispatch => {
    return Projects.delete(projectId)
        .then(() => {
            dispatch(deleteProjectSuccess());
        })
        .catch(() => {
            // todo handle error
        });
};


export const getProjectsListRequest = createAction('getProjectsListRequest');
export const getProjectsListSuccess = createAction('getProjectsListSuccess');
export const getProjectsListFailure = createAction('getProjectsListFailure');
export const loadProjectsList = () => dispatch => {
    dispatch(getProjectsListRequest());
    return Projects.list()
        .then(data => {
            dispatch(getProjectsListSuccess(data));
        })
        .catch(() => {
            dispatch(getProjectsListFailure());
        });
};


export const setNewProjectData = createAction('setNewProjectData');

export const requestSaveNewProjectSuccess = createAction('requestSaveNewProjectSuccess');
export const saveNewProjectData = (projectData) => dispatch => {
    return Projects.create(projectData)
        .then(data => {
            dispatch(requestSaveNewProjectSuccess(data));
        })
        .catch(() => {
            // todo handle error
        });
};

export const setUpdatedProjectData = createAction('setUpdatedProjectData');
export const requestSaveUpdatedProjectDataSuccess = createAction('requestSaveUpdatedProjectDataSuccess');
export const saveUpdatedProjectData = (projectId, projectData) => dispatch => {
    return Projects.update(projectId, projectData)
        .then(data => {
            dispatch(requestSaveUpdatedProjectDataSuccess(data));
        })
        .catch(() => {
            // todo handle error
        });
};

export const requestGetProjectDataRequest = createAction('requestGetProjectDataRequest');
export const requestGetProjectDataSuccess = createAction('requestGetProjectDataSuccess');
export const fetchProjectData = (projectId) => dispatch => {
    requestGetProjectDataRequest()
    return Projects.get(projectId)
        .then(data => {
            dispatch(requestGetProjectDataSuccess(data));
        })
        .catch(() => {
            // todo handle error
        });
};
