import React from "react";
import { connect } from "react-redux";
import { setEditableContentAction } from "../actions/main";
import { listContent } from "../api/content";
import ContentEditModeComponent from "./ContentEditModeComponent";
import ContentReadModeComponent from "./ContentReadModeComponent";
var ContentDataComponent = function (props) {
    return React.createElement(React.Fragment, null, props.boordEditState.id ? React.createElement(ContentEditModeComponent, null) : React.createElement(ContentReadModeComponent, null));
};
var mapStateToProps = function (state) {
    return {
        boordEditState: state.boordEdit,
    };
};
export default connect(mapStateToProps, {
    setEditableContent: setEditableContentAction,
    listContent: listContent
})(ContentDataComponent);
