import {handleActions} from 'redux-actions';
import {getProjectsListSuccess} from "../actions/main";

const initValues = {
    projects: [],
    inProgress: false,
    isLoaded: false
}
export default handleActions(
    {
        [getProjectsListSuccess]: (state, action) => {

            const projects = action.payload.projects.map(project => {
                return {
                    id: project.id,
                    name: project.name,
                }
            });

            return {
                ...state,
                isLoaded: true,
                inProgress: false,
                projects: projects,
            };
        },
    },
    initValues,
);
