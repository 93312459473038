import {
    createBoldPlugin,
    createCodePlugin,
    createItalicPlugin,
    createLinkPlugin,
    createPlateEditor,
    createPlateUI,
    createPlugins,
    createStrikethroughPlugin,
    createUnderlinePlugin,
    Plate,
    serializeHtml
} from "@udecode/plate";
import React from "react";

export const BotEditor = (props) => {

    let initialValue = props.initialEditorData

    console.log('initialValue', initialValue)

    if (!initialValue) {
        initialValue = [
            {
                "type": "p",
                "children": [
                    {
                        "text": ""
                    }
                ]
            }
        ]
    } else if (initialValue.charAt(0) !== '[') {
        /**
         * Костыль для совместимости со старыми текстами
         * @type {[{children: [{text: *}], type: string}]}
         */
        initialValue = [
            {
                "type": "p",
                "children": [
                    {
                        "text": props.initialEditorData
                    }
                ]
            }
        ]
    } else {
        initialValue = JSON.parse(initialValue)
    }

    const plugins = createPlugins([
        createLinkPlugin(),
        createBoldPlugin(),           // bold mark
        createItalicPlugin(),         // italic mark
        createUnderlinePlugin(),      // underline mark
        createStrikethroughPlugin(),  // strikethrough mark
        createCodePlugin(),           // code mark
    ], {
        components: createPlateUI(),
    });

    const editor = createPlateEditor({
        plugins: plugins,
        components: createPlateUI(),
    });


    const onChangeDebug = (newValue) => {
        console.log('newValue')
        props.onTextChange({
            // htmlContent: serializeHtml(editor, {nodes: newValue}),
            editorData: JSON.stringify(newValue),
        })
    }

    return (
        <>
            <Plate
                // editableProps={CONFIG.editableProps}
                initialValue={initialValue}
                onChange={onChangeDebug}
                plugins={plugins}
            />
        </>
    );
}
