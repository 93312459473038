var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ReactEditor, useFocused, useSelected, useSlate } from "slate-react";
import React from "react";
import { Transforms } from "slate";
export var LocationEditorElement = function (props) {
    var attributes = props.attributes;
    var element = props.element;
    // const editor = useSlateStatic()
    var currentLocation = element.location;
    var editor = useSlate();
    var path = ReactEditor.findPath(editor, element);
    var selected = useSelected();
    var focused = useFocused();
    var onChange = function (event) {
        Transforms.setNodes(editor, 
        // @ts-ignore
        { location: event.target.value }, {
            // This path references the editor, and is expanded to a range that
            // will encompass all the content of the editor.
            at: path,
        });
    };
    return (React.createElement("div", __assign({}, attributes, { contentEditable: false, style: {
            padding: '3px 3px 2px',
            margin: '0 1px',
            verticalAlign: 'baseline',
            borderRadius: '4px',
            backgroundColor: '#eee',
            fontSize: '0.9em',
            boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        } }),
        React.createElement("form", { autoComplete: "false" },
            "location (lat,lon):",
            React.createElement("input", { type: "text", onChange: onChange, value: currentLocation }))));
};
export var withLocations = function (editor) {
    var isInline = editor.isInline, isVoid = editor.isVoid;
    editor.isInline = function (element) {
        return element.type === 'location' ? false : isInline(element);
    };
    editor.isVoid = function (element) {
        return element.type === 'location' ? false : isVoid(element);
    };
    return editor;
};
