import {createAction} from "redux-actions";
import Intent from "../api/intent";
import history from '../utils/history'

export const getIntentsListRequest = createAction('getIntentsListRequest');
export const getIntentsListSuccess = createAction('getIntentsListSuccess');
export const getIntentsListFailure = createAction('getIntentsListFailure');

export const loadIntents = () => dispatch => {
    dispatch(getIntentsListRequest());
    return Intent.list()
        .then(data => {
            dispatch(getIntentsListSuccess(data));
        })
        .catch(err => {
            dispatch(getIntentsListFailure());
        });
};

export const getIntentRequest = createAction('getIntentRequest');
export const getIntentSuccess = createAction('getIntentSuccess');
export const getIntentFailure = createAction('getIntentFailure');

export const getById = (id) => dispatch => {
    dispatch(getIntentRequest());
    return Intent.getById(id)
        .then(data => {
            dispatch(getIntentSuccess(data));
        })
        .catch(err => {
            console.log(err);
            dispatch(getIntentFailure());
        });
};


export const createIntentRequest = createAction('getIntentRequest');
export const createIntentSuccess = createAction('getIntentSuccess');
export const createIntentFailure = createAction('getIntentFailure');

export const createIntent = (intentData) => dispatch => {
    dispatch(createIntentRequest());
    return Intent.create(intentData)
        .then(data => {
            dispatch(createIntentSuccess(data));
            history.push('/intents/' + data.id)
        })
        .catch(err => {
            console.log(err);
            dispatch(createIntentFailure());
        });
};

export const updateIntentMetaRequest = createAction('updateIntentMetaRequest');
export const updateIntentMetaSuccess = createAction('updateIntentMetaSuccess');
export const updateIntentMetaFailure = createAction('updateIntentMetaFailure');

export const updateIntentData = (id, intentData) => dispatch => {
    dispatch(updateIntentMetaRequest());
    return Intent.update(id, intentData)
        .then(data => {
            dispatch(updateIntentMetaSuccess(data));
        })
        .catch(err => {
            console.log(err);
            dispatch(updateIntentMetaFailure());
        });
};

export const getAvailableBotMenuForIntentRequest = createAction('getAvailableBotMenuForIntentRequest');
export const getAvailableBotMenuForIntentSuccess = createAction('getAvailableBotMenuForIntentSuccess');
export const getAvailableBotMenuForIntentFailure = createAction('getAvailableBotMenuForIntentFailure');

export const getAvailableBotMenu = () => dispatch => {
    dispatch(getAvailableBotMenuForIntentRequest());
    return Intent.getAvailableBotMenu()
        .then(data => {
            dispatch(getAvailableBotMenuForIntentSuccess(data));
        })
        .catch(err => {
            console.log(err);
            dispatch(getAvailableBotMenuForIntentFailure());
        });
};


export const updateAnswerInTextIntentRequest = createAction('updateAnswerInTextIntentRequest');

export const updateAnswerInTextIntent = (elementIndex, text) => dispatch => {
    console.log('updateAnswerInTextIntent')
    dispatch(updateAnswerInTextIntentRequest({index: elementIndex, answer: text}));
}


export const addAnswerInTextIntentAction = createAction('addAnswerInTextIntentAction');
export const addNewAnswer = (text) => dispatch => {
    dispatch(addAnswerInTextIntentAction(text));
}
export const removeAnswerFromTextIntentAction = createAction('removeAnswerFromTextIntentAction');
export const removeAnswer = (idx) => dispatch => {
    dispatch(removeAnswerFromTextIntentAction(idx));
}


export const createIntentQuery = createAction('createIntentQuery');
export const updateIntentQuery = createAction('updateIntentQuery');
export const deleteIntentQuery = createAction('deleteIntentQuery');

export const updateIntentContact = createAction('updateIntentContact');
export const updateIntentName = createAction('updateIntentName');
export const updateIntentField = createAction('updateIntentField');
export const updateIntentSetting = createAction('updateIntentSetting');
