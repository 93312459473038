import {createAction} from "redux-actions";
import Auth from "../api/auth";
import {saveSession} from "../helpers/authSessionStorage";
import {setData} from "../reducers/currentUser";

export const sendAuthCodeRequest = createAction('sendAuthCodeRequest');
export const sendAuthCodeSuccess = createAction('sendAuthCodeSuccess');
export const sendAuthCodeFailure = createAction('sendAuthCodeFailure');

export const sendCode = (email) => dispatch => {
    dispatch(sendAuthCodeRequest());
    // const history = useHistory();
    return Auth.sendCode(email)
        .then(data => {
            dispatch(sendAuthCodeSuccess(data));
        })
        .catch(err => {
            console.log('err', err)
            dispatch(sendAuthCodeFailure(err));
        });
};

export const verifyAuthCodeRequest = createAction('verifyAuthCodeRequest');
export const verifyAuthCodeSuccess = createAction('verifyAuthCodeSuccess');
export const verifyAuthCodeFailure = createAction('verifyAuthCodeFailure');

export const verifyCode = (sessionId, pinCode) => dispatch => {
    dispatch(verifyAuthCodeRequest());
    return Auth.verifyCode(sessionId, pinCode)
        .then(data => {
            saveSession(data.sessionId)
            window.location = '/'
            // dispatch(verifyAuthCodeSuccess(data));
        })
        .catch(err => {
            dispatch(verifyAuthCodeFailure(err));
        });
};

export const getCurrentUserRequest = createAction('getCurrentUserRequest');
export const getCurrentUserSuccess = createAction('getCurrentUserSuccess');
export const getCurrentUserFailure = createAction('getCurrentUserFailure');

export const checkUserSession = (successCallback, failureCallback) => dispatch => {
    return Auth.getUserInfo()
        .then(data => {
            dispatch(setData(data))
            successCallback()
        })
        .catch((err, res) => {
            failureCallback(err, res)
        });
};
