import {requests} from "../../../utils/requests";

const BotApi = {
    getBotInfo: () => requests.get('/api/bot'),
    updateBotInfo: (botData) => requests.put('/api/bot', botData),
    loadVersions: (botId) => requests.get('/api/bot/model?bot_id=' + botId),
    createVersion: (versionData) => requests.post('/api/bot/model', versionData),
    listBotCommandMenu: (botId) => requests.get('/api/commands?bot_id=' + botId),
    updateBotCommandMenu: (menuId, menuData) => requests.post('/api/commands/update-menu/' + menuId, menuData),
};

export default BotApi
