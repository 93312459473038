import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { formatPhone } from "../../../utils/strings";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import InstagramIcon from "@mui/icons-material/Instagram";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
export var MemberProfile = function (props) {
    var _a;
    var member = props.member;
    var contactNum = 0;
    var contacts = (_a = member.contacts) === null || _a === void 0 ? void 0 : _a.map(function (contact) {
        contactNum++;
        var contactLabel;
        if (contact.type == 'phone') {
            contactLabel =
                contactLabel = React.createElement("span", null,
                    React.createElement(PhoneIcon, null),
                    formatPhone(contact.value));
        }
        else if (contact.type == 'email') {
            contactLabel = React.createElement("span", null,
                React.createElement(AlternateEmailIcon, null),
                React.createElement("a", { target: "_blank", href: "mailto: " + contact.value }, contact.value));
        }
        else if (contact.type == 'instagram') {
            contactLabel = React.createElement("span", null,
                React.createElement(InstagramIcon, null),
                React.createElement("a", { target: "_blank", href: "https://instagram.com/" + contact.value },
                    "@",
                    contact.value));
        }
        else if (contact.type == 'telegram') {
            contactLabel = React.createElement("span", null,
                React.createElement(InstagramIcon, null),
                React.createElement("a", { target: "_blank", href: "https://t.me/" + contact.value },
                    "@",
                    contact.value));
        }
        else {
            contactLabel = React.createElement("span", null,
                React.createElement(ConnectWithoutContactIcon, null),
                contact.value);
        }
        return React.createElement("div", { key: "member-contact-" + contactNum, className: "contact contact-type-" + contact.type }, contactLabel);
    });
    if (contacts) {
        contacts = [React.createElement("div", { className: "contacts", key: "contacts-" + contactNum }, contacts)];
    }
    var imageUrl = member.avatarImage + "?1";
    return (React.createElement("div", { key: "team_member_" + member.id, className: "member-profile" },
        React.createElement("div", { className: "pic" },
            React.createElement("img", { src: imageUrl, alt: member.displayName })),
        React.createElement("div", { className: "name" }, member.displayName),
        React.createElement("div", { className: "position" }, member.position),
        React.createElement("div", { className: "contacts" }, contacts)));
};
