import {handleActions} from 'redux-actions';
import {requestBotVersionListSuccess} from "../actions/main";

const initValues = {
    versions: [],
}
export default handleActions(
    {
        [requestBotVersionListSuccess]: (state, action) => {

            let result = []

            action.payload.data.forEach(item => {
                result.push({
                    id: item.id,
                    instanceUrl: item.instance_url,
                    status: item.status,
                })
            })

            return {
                ...state,
                versions: result,
            };
        },
    },
    initValues,
);

