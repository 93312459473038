import {createAction} from "redux-actions";
import Members from "../api/members";

export const getMembersListRequest = createAction('getMembersListRequest');
export const getMembersListSuccess = createAction('getMembersListSuccess');
export const getMembersListFailure = createAction('getMembersListFailure');

export const loadMembersList = () => dispatch => {
    dispatch(getMembersListRequest());
    return Members.list()
        .then(data => {
            dispatch(getMembersListSuccess(data));
        })
        .catch(() => {
            dispatch(getMembersListFailure());
        });
};
