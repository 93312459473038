import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {
    createIntent
} from "../../../actions/intent";
import {Link} from "react-router-dom";

const NewIntentPage = (props) => {

    let [name, setName] = useState('');

    const handleSubmit = () => {
        const intentData = {
            name: name,
        };
        props.createIntent(intentData)
        return false;
    }

    return (
        <div className={"main-content-container intent-page"}>
            <div className={"main-content"}>

                <Link to={"/intents"}>К списку интентов</Link>

                <div className={"intent-header"}>

                    <input type={"text"} className={"form-control"}
                           onChange={element => {
                               setName(element.target.value)
                           }}
                           value={name}/>

                </div>

                <div>
                    <button onClick={handleSubmit}>Создать интент</button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        // intentState: state.intent,
    }
}

export default connect(mapStateToProps, {
    createIntent
})(NewIntentPage);
