import {handleActions} from 'redux-actions';
import {requestNotificationListSuccess} from "../actions/main";

const initValues = {
    notifications: [],
}
export default handleActions(
    {
        [requestNotificationListSuccess]: (state, action) => {

            let result = []

            action.payload.data.forEach(item => {
                result.push({
                    id: item.id,
                    title: item.title,
                })
            })

            return {
                ...state,
                notifications: result,
            };
        },
    },
    initValues,
);

