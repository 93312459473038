var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { generateRandomStringID } from "../../../utils/strings";
import { TeamList } from "../renders/TeamList";
import React from "react";
import { MemberInlineProfile } from "../renders/MemberInlineProfile";
import { InlineImage } from "../renders/InlineImage";
import { ProjectsList } from "../renders/ProjectsList";
import { LocationItem } from "../renders/LocationItem";
export var renderSlateContent = function (items, tokens) {
    if (!Array.isArray(items)) {
        return [];
    }
    var result = [];
    items.map(function (item) {
        if (item.children) {
            var childs = renderSlateContent(item.children, tokens);
            if (item.type == 'p' || item.type == 'paragraph') {
                var key = generateRandomStringID();
                result = __spreadArray(__spreadArray([], result, true), [React.createElement("p", { key: key }, childs)], false);
            }
            if (item.type == 'link' || item.type == 'a') {
                var key = generateRandomStringID();
                result = __spreadArray(__spreadArray([], result, true), [React.createElement("a", { target: "_blank", href: item.url, key: key }, childs)], false);
            }
            if (item.type == 'team') {
                var key = generateRandomStringID();
                result = __spreadArray(__spreadArray([], result, true), [React.createElement(TeamList, { key: key })], false);
            }
            if (item.type == 'projects') {
                var key = generateRandomStringID();
                result = __spreadArray(__spreadArray([], result, true), [React.createElement(ProjectsList, { key: key })], false);
            }
            if (item.type == 'location') {
                var key = generateRandomStringID();
                var locationData = item;
                var latLon = locationData.location.split(',');
                var lat = Number(latLon[0]);
                var lon = Number(latLon[1]);
                result = __spreadArray(__spreadArray([], result, true), [React.createElement(LocationItem, { key: key, lat: lat, lon: lon })], false);
            }
            if (item.type == 'image') {
                var key = generateRandomStringID();
                result = __spreadArray(__spreadArray([], result, true), [React.createElement(InlineImage, { key: key, editorElement: item })], false);
            }
            if (item.type == 'mention') {
                var key = generateRandomStringID();
                var mentionData = item;
                var mentionDisplayItem = {
                    mentionId: mentionData.mentionId,
                    title: mentionData.mentionTitle,
                };
                result = __spreadArray(__spreadArray([], result, true), [React.createElement(MemberInlineProfile, { mentionItem: mentionDisplayItem, key: key })], false);
            }
        }
        else {
            if (item.text !== undefined && item.text) {
                var content_1 = item.text;
                tokens.map(function (token) {
                    content_1 = content_1.replace(token.token, token.value);
                });
                var key = generateRandomStringID();
                if (item.bold !== undefined) {
                    result.push(React.createElement("strong", { key: key }, content_1));
                }
                else {
                    result.push(React.createElement("span", { key: key }, content_1));
                }
            }
        }
    });
    return result;
};
