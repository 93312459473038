import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {
    addNewAnswer,
    getById,
    removeAnswer,
    updateAnswerInTextIntent,
    updateIntentData,
    updateIntentQuery,
    deleteIntentQuery,
    createIntentQuery
} from "../../../actions/intent";
import {intentActionUpdateParamsField} from "../actions/main";


const AskIntentAction = (props) => {

    const action = props.action

    const [answers, setAnswers] = useState([]);

    const renderAnswers = []
    useEffect(() => {
        let answers = {}
        if (action.params.answers) {
            Object.values(action.params.answers).forEach(item => {
                answers = {
                    ...answers, ...{
                        [item.id]: {
                            id: item.id,
                            text: item.text,
                        }
                    }
                }
            });
        }
        setAnswers(answers)
    }, [])

    const createAnswer = () => {
        const newAnswerId = Math.random().toString(36).substring(7)
        const newAnswer = {
            id: newAnswerId,
            text: '',
        }
        const copyAnswers = {...answers, ...{[newAnswerId]: newAnswer}}
        updateAnswers(copyAnswers)
    }

    const updateAnswers = (newAnswers) => {
        setAnswers(newAnswers)
        props.intentActionUpdateParamsField({id: action.id, field: 'answers', value: newAnswers})
    }

    const initAnswerField = (answer) => {
        return (
            <div key={"answer_" + answer.id} className={"answer-item"}>
        <textarea className={"form-control"}
                  style={{height: '200px'}}
                  onChange={element => {
                      const newAnswers = {...answers, ...{[answer.id]: {id: answer.id, text: element.target.value}}}
                      updateAnswers(newAnswers)
                  }} value={answer.text}/>
                <div>
                    <a className={"del-button"} onClick={() => {
                        const newAnswers = {...answers}
                        delete newAnswers[answer.id]
                        updateAnswers(newAnswers)
                    }}>удалить этот вариант ответа
                    </a>
                </div>
            </div>
        )
    }

    Object.values(answers).map(item => {
        renderAnswers.push(initAnswerField(item))
    })

    return (
        <div className={"ask-action-intent-settings"}>

            <div className={"form-group mb-8"}>
                Название переменной для хранение ответа:
                <input className="form-control" type={"text"}
                       onChange={el => {
                           props.intentActionUpdateParamsField({
                               id: action.id,
                               field: 'responseVariable',
                               value: el.target.value
                           })
                       }}
                       value={action.params.responseVariable}/>
            </div>

            <div>
                <p>
                    Укажите здесь ответ, который пользователь
                    получит когда обратится к этому интенту. Советуем добавить более одного варианта
                    ответа - тогда пользователь получит один из указанных
                    ответов, выбранный случайным образом - это сделает ваш чат-бот более дружелюбным и
                    интерактивным для пользователя ;)
                </p>
                {renderAnswers}
                <div>
                    <button className={"btn btn-outline-success"} onClick={createAnswer}>Добавить вариант ответа
                    </button>
                </div>
            </div>


        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        intentState: state.intentProfile,
    }
}

export default connect(mapStateToProps, {
    getById,
    updateIntentData,
    updateAnswerInTextIntent,
    addNewAnswer, removeAnswer,
    updateIntentQuery,
    createIntentQuery,
    deleteIntentQuery,
    intentActionUpdateParamsField
})(AskIntentAction);
