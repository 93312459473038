import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {
    MenuItem,
    Select
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {saveNewMemberData, setNewMemberData} from "../actions/main";
import {Navigate} from "react-router-dom";

const MemberProfileCreatePage = (props) => {

    if (props.memberCreateProfileState.justCreated) {
        const newMemberProfileUrl = '/panel/members/' + props.memberCreateProfileState.newMemberId
        return <Navigate to={newMemberProfileUrl}/>
    }

    const userProfile = props.memberCreateProfileState.newProfile

    const submitNewUserData = () => {
        let requestData = {
            firstname: userProfile.firstname,
            lastname: userProfile.lastname,
            birthday: userProfile.birthday,
            gender: userProfile.gender,
            phone: userProfile.phoneNum,
            email: userProfile.email,
        }
        console.log('updateUserData.requestData', requestData)
        props.saveNewMemberData(requestData)
    }

    return (<div className={"d-flex flex-column-fluid"}>
        <div className={"container"}>
            <div className={"card card-custom"}>

                <div className={"card-body"}>
                    <button onClick={submitNewUserData} className={"btn"}>
                        Создать профиль
                    </button>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Фамилия</label>
                        <div className="col-9">
                            <input type={"text"} className={"form-control"}
                                   onChange={element => {
                                       props.setNewMemberData({field: 'lastname', value: element.target.value})
                                   }}
                                   value={userProfile.lastname}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Имя</label>
                        <div className="col-9">

                            {userProfile && userProfile.gender && (
                                <Select value={userProfile.gender}
                                        inputProps={{
                                            name: "gender",
                                            id: "gender-select"
                                        }}
                                        onChange={element => {
                                            props.setNewMemberData({field: 'gender', value: element.target.value})
                                        }}>
                                    <MenuItem key={'gender_m'} value={"m"}>М</MenuItem>
                                    <MenuItem key={'gender_f'} value={"f"}>Ж</MenuItem>
                                </Select>
                            )}

                            <input type={"text"}
                                   className={"form-control"}
                                   onChange={element => {
                                       props.setNewMemberData({field: 'firstname', value: element.target.value})
                                   }}
                                   value={userProfile.firstname}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Телефон</label>
                        <div className="col-9">
                            <input type={"text"} className={"form-control"}
                                   onChange={element => {
                                       props.setNewMemberData({field: 'phoneNum', value: element.target.value})
                                   }}
                                   value={userProfile.phoneNum}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Email</label>
                        <div className="col-9">
                            <input type={"text"} className={"form-control"}
                                   onChange={element => {
                                       props.setNewMemberData({field: 'email', value: element.target.value})
                                   }}
                                   value={userProfile.email}/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Дата рождения</label>
                        <div className="col-9">

                            <TextField
                                label="Дата рождения"
                                type="date"
                                onChange={element => {
                                    props.setNewMemberData({field: 'birthday', value: element.target.value})
                                }}
                                defaultValue="2000-03-27"
                                value={userProfile.birthday}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>)
};

const mapStateToProps = function (state) {
    return {
        memberCreateProfileState: state.memberCreateProfile,
    }
}

MemberProfileCreatePage.propTypes = {}

export default connect(mapStateToProps, {
    setNewMemberData,
    saveNewMemberData
})(MemberProfileCreatePage);
