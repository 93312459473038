import {handleActions} from 'redux-actions';
import {
    botCommandMenuCreateItem, botCommandMenuRemoveItem,
    botCommandMenuUpdateField,
    botCommandMenuUpdateItemField,
    requestBotCommandGroupsListSuccess
} from "../actions/bot_commands";

const initValues = {
    menus: {},
}
export default handleActions(
    {
        [botCommandMenuCreateItem]: (state, action) => {
            const menuId = action.payload.menuId
            const newItemId = Math.random().toString(36).substring(7)

            return {
                ...state,
                menus: {
                    ...state.menus, ...{
                        [menuId]: {
                            ...state.menus[menuId], ...{
                                items: {
                                    ...state.menus[menuId].items, ...{
                                        [newItemId]: {
                                            id: newItemId,
                                            name: 'newItem',
                                            command: 'command',
                                        }
                                    }
                                }
                            }
                        }
                    },
                }
            };
        },
        [botCommandMenuRemoveItem]: (state, action) => {
            const menuId = action.payload.menuId
            const itemId = action.payload.itemId

            const items = state.menus[menuId].items
            delete items[itemId]

            return {
                ...state,
                menus: {
                    ...state.menus, ...{
                        [menuId]: {
                            ...state.menus[menuId], ...{items: items}
                        }
                    }
                }
            };
        },
        [botCommandMenuUpdateField]: (state, action) => {
            const menuId = action.payload.menuId
            const fieldName = action.payload.field
            const value = action.payload.value

            return {
                ...state,
                menus: {...state.menus, ...{[menuId]: {...state.menus[menuId], ...{[fieldName]: value}}}},
            };
        },
        [botCommandMenuUpdateItemField]: (state, action) => {
            const menuId = action.payload.menuId
            const itemId = action.payload.itemId
            const fieldName = action.payload.field
            const value = action.payload.value

            return {
                ...state,
                menus: {
                    ...state.menus, ...{
                        [menuId]: {
                            ...state.menus[menuId], ...{
                                items: {
                                    ...state.menus[menuId].items, ...{
                                        [itemId]: {
                                            ...state.menus[menuId].items[itemId], ...{[fieldName]: value}
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
            };
        },
        [requestBotCommandGroupsListSuccess]: (state, action) => {

            let menus = {}
            action.payload.data.forEach(menuItem => {
                menus = {
                    ...menus, ...{
                        [menuItem.id]: {
                            id: menuItem.id,
                            name: menuItem.name,
                            items: menuItem.items
                        }
                    }
                }
            })

            console.log('menus', menus)

            return {
                ...state,
                menus: menus,
            };
        },
    },
    initValues,
);

