import { requestGET } from "../../../utils/apiClient";
export var fetchProjectsList = function (successCallback, failureCallback) {
    requestGET('/api/boord/projects')
        .then(function (successRequestData) {
        successCallback(successRequestData.data);
    })
        .catch(function (error) {
        console.error('getAuthLink.error', error);
        failureCallback();
    });
};
