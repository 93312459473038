import React from "react";
import { connect } from "react-redux";
import { addDropdownValue, changeDropdownValue, changeLabel, create, markAsDeleted, removeDropdownValue } from "../reducers/editCondition";
import '../styles/Edit.scss';
export var ConditionType;
(function (ConditionType) {
    ConditionType["Dropdown"] = "dropdown";
    ConditionType["Checkbox"] = "checkbox";
})(ConditionType || (ConditionType = {}));
var ConditionsEdit = function (props) {
    var dropdown = props.boordEditConditionState.conditions;
    var conditions = [];
    var renderDropdownCondition = function (item) {
        var values = [];
        var _loop_2 = function (valueId) {
            var value = item.values[valueId];
            values.push(React.createElement("div", { key: "dropdrown-value-" + valueId },
                React.createElement("input", { type: "text", value: value.label, onChange: function (event) {
                        props.changeDropdownValue({
                            condition: item,
                            value: value,
                            label: event.target.value
                        });
                    } }),
                React.createElement("button", { onClick: function () {
                        props.removeDropdownValue({
                            condition: item,
                            value: value
                        });
                    } }, "\u0443\u0434\u0430\u043B\u0438\u0442\u044C")));
        };
        for (var valueId in item.values) {
            _loop_2(valueId);
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("input", { type: "text", onChange: function (event) {
                    var changeLabelDto = {
                        item: item,
                        label: event.target.value
                    };
                    props.changeLabel(changeLabelDto);
                }, value: item.label }),
            React.createElement("div", null, "values:"),
            React.createElement("div", null,
                values,
                React.createElement("button", { onClick: function () {
                        props.addDropdownValue(item);
                    } }, "add variant"))));
    };
    var renderCheckboxCondition = function (item) {
        return (React.createElement(React.Fragment, null,
            React.createElement("input", { type: "text", onChange: function (event) {
                    var changeLabelDto = {
                        item: item,
                        label: event.target.value
                    };
                    props.changeLabel(changeLabelDto);
                }, value: item.label })));
    };
    var _loop_1 = function (key) {
        var item = dropdown[key];
        var renderFn = renderDropdownCondition;
        if (item.type == ConditionType.Checkbox) {
            renderFn = renderCheckboxCondition;
        }
        var cssClasses = ["condition-item"];
        if (item.isDeleted) {
            cssClasses.push("deleted");
        }
        var displayCondition = React.createElement("div", { key: "condition-" + item.id, className: cssClasses.join(" ") },
            renderFn(item),
            React.createElement("div", null,
                React.createElement("button", { onClick: function () {
                        props.markAsDeleted(item);
                    } }, item.isDeleted ? 'Не удалять это условие' : 'Удалить условие')));
        conditions.push(displayCondition);
    };
    for (var key in dropdown) {
        _loop_1(key);
    }
    var addSelectCondition = function () {
        props.create(ConditionType.Dropdown);
    };
    var addCheckboxCondition = function () {
        props.create(ConditionType.Checkbox);
    };
    console.log('props.boordEditConditionState', props.boordEditConditionState);
    return React.createElement("div", null,
        conditions,
        React.createElement("button", { onClick: addSelectCondition }, "\u0434\u043E\u0431\u0430\u0432\u0438\u0442\u044C select"),
        React.createElement("button", { onClick: addCheckboxCondition }, "\u0434\u043E\u0431\u0430\u0432\u0438\u0442\u044C checkbox"));
};
var mapStateToProps = function (state) {
    return {
        boordEditConditionState: state.boordEditCondition,
    };
};
export default connect(mapStateToProps, {
    create: create,
    changeLabel: changeLabel,
    addDropdownValue: addDropdownValue,
    changeDropdownValue: changeDropdownValue,
    removeDropdownValue: removeDropdownValue,
    markAsDeleted: markAsDeleted
})(ConditionsEdit);
