import React, { useState } from "react";
import { uploadPhoto } from "../api/avatar";
export var PhotosTab = function (props) {
    var _a = useState(), selectedFile = _a[0], setSelectedFile = _a[1];
    var onFileChange = function (event) {
        var files = event.target.files[0];
        setSelectedFile(event.target.files[0]);
    };
    var onUpload = function () {
        var formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        uploadPhoto(props.memberId, formData);
    };
    return React.createElement("div", { style: { padding: 20, backgroundColor: '#f9f9f9' } },
        React.createElement("h4", null, "avatar"),
        React.createElement("input", { type: "file", onChange: onFileChange }),
        React.createElement("button", { onClick: onUpload }, "upload avatar"));
};
