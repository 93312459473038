import React from "react";
import {connect} from 'react-redux';
import {intentActionUpdateParamsField} from "../actions/main";

const EmailIntentAction = (props) => {

    const action = props.action

    return (
        <div className={"ask-action-intent-settings"}>
            <div className={"form-group mb-8"}>
                Тема письма
                <input type={"text"} className={"form-control"}
                       onChange={element => {
                           props.intentActionUpdateParamsField({
                               id: action.id,
                               field: 'messageSubject',
                               value: element.target.value
                           })
                       }} value={action.params.messageSubject}/>
                <div className="hint">
                    Системные переменные для использования в тексте:
                    $userLink (ссылка на профиль пользователя)
                </div>
            </div>
            <div className={"form-group mb-8"}>
                Текст сообщения
                <textarea className={"form-control"}
                          onChange={element => {
                              props.intentActionUpdateParamsField({
                                  id: action.id,
                                  field: 'messageText',
                                  value: element.target.value
                              })
                          }} value={action.params.messageText}/>
                <div className="hint">
                    Системные переменные для использования в тексте:
                    $userLink (ссылка на профиль пользователя)
                </div>
            </div>

            <div className={"form-group mb-8"}>
                Email получателя
                <input className="form-control" type={"text"}
                       onChange={element => {
                           props.intentActionUpdateParamsField({
                               id: action.id,
                               field: 'receiverEmail',
                               value: element.target.value
                           })
                       }}
                       value={action.params.receiverEmail}/>
            </div>

        </div>
    );
};

export default connect(null, {
    intentActionUpdateParamsField
})(EmailIntentAction);
