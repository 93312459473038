import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {
    addNewAnswer,
    getById,
    removeAnswer,
    updateAnswerInTextIntent,
    updateIntentData,
    updateIntentQuery,
    deleteIntentQuery,
    createIntentQuery
} from "../../../actions/intent";
import {intentActionUpdateParamsField} from "../actions/main";


const SendLocationAction = (props) => {
    const action = props.action
    return (
        <div className={"SendLocationAction-action-intent-settings"}>

            <div className={"form-group mb-8"}>
                Широта
                <input className="form-control" type={"text"}
                       onChange={el => {
                           props.intentActionUpdateParamsField({
                               id: action.id,
                               field: 'latitude',
                               value: el.target.value
                           })
                       }}
                       value={action.params.latitude}/>
            </div>
            <div className={"form-group mb-8"}>
                Долгота
                <input className="form-control" type={"text"}
                       onChange={el => {
                           props.intentActionUpdateParamsField({
                               id: action.id,
                               field: 'longitude',
                               value: el.target.value
                           })
                       }}
                       value={action.params.longitude}/>
            </div>

        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        intentState: state.intentProfile,
    }
}

export default connect(mapStateToProps, {
    getById,
    updateIntentData,
    updateAnswerInTextIntent,
    addNewAnswer, removeAnswer,
    updateIntentQuery,
    createIntentQuery,
    deleteIntentQuery,
    intentActionUpdateParamsField
})(SendLocationAction);
