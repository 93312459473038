import React, {useEffect} from "react";
import {connect} from 'react-redux';
import Table from "@material-ui/core/Table";
import {loadBotCommandsList} from "../actions/bot_commands";
import {Link} from "react-router-dom";

const botId = 1

const BotCommandGroupListPage = (props) => {

    useEffect(() => {
        props.loadBotMenuList(botId)
    }, []);

    const versions = Object.values(props.botCommandGroupState.menus).map(item => {

        return (<tr>
            <td>
                #{item.id}
            </td>
            <td>
                <Link to={"/commands/" + item.id} params={{menuId: item.id}}>{item.name}</Link>
            </td>
            <td>
            </td>
        </tr>)
    })

    return (<div>

        <hr/>

        <h3>Меню бота</h3>

        <Table striped bordered hover size="sm">
            <thead>
            <tr>
                <td>version num.</td>
                <td>status</td>
                <td>Instance URL</td>
            </tr>
            </thead>
            <tbody>
            {versions}
            </tbody>
        </Table></div>)
};

const mapStateToProps = function (state) {
    return {
        botCommandGroupState: state.botCommandMenu,
    }
}

export default connect(mapStateToProps, {
    loadBotMenuList: loadBotCommandsList,
})(BotCommandGroupListPage);
