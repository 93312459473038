import {handleActions} from 'redux-actions';
import {
    deleteProjectSuccess, requestGetProjectDataRequest,
    requestGetProjectDataSuccess,
    setUpdatedProjectData
} from "../actions/main";

const initValues = {
    project: {
        name: null,
        description: null,
        botCommand: null,
        website: null,
        appstoreUrl: null,
        googlePlayUrl: null,
        teamId: null,
        additionalTextUrl: null,
    },
    inProgress: false,
    isLoaded: false,
    isDeleted: false,
}
export default handleActions(
    {
        [deleteProjectSuccess]: (state) => {
            return {
                ...state,
                ...{
                    project: null,
                    isDeleted: true,
                }
            }
        },
        [requestGetProjectDataRequest]: (state) => {
            return {
                ...state,
                ...{
                    inProgress: true,
                    isDeleted: false,
                }
            }
        },
        [requestGetProjectDataSuccess]: (state, action) => {
            const projectData = {
                name: action.payload.project.name,
                description: action.payload.project.description,
                botCommand: action.payload.project.bot_command,
                website: action.payload.project.website,
                appstoreUrl: action.payload.project.appstore_url,
                googlePlayUrl: action.payload.project.googleplay_url,
                teamId: action.payload.project.team_id,
                additionalTextUrl: action.payload.project.additional_text_url,
            }
            return {
                ...state,
                ...{
                    project: projectData,
                    isLoaded: true,
                    inProgress: false,
                    isDeleted: false,
                }
            }
        },
        [setUpdatedProjectData]: (state, action) => {
            const field = action.payload.field
            const value = action.payload.value

            return {
                ...state,
                project: {...state.project, ...{[field]: value}}
            }
        },
    },
    initValues,
);
