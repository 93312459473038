import {handleActions} from 'redux-actions';
import {
    addAnswerInTextIntentAction,
    createIntentQuery,
    deleteIntentQuery,
    getAvailableBotMenuForIntentSuccess,
    getIntentRequest,
    getIntentSuccess,
    removeAnswerFromTextIntentAction,
    updateAnswerInTextIntentRequest,
    updateIntentContact,
    updateIntentField,
    updateIntentName,
    updateIntentQuery,
    updateIntentSetting
} from "../../../actions/intent";
import {
    intentActionChangeType,
    intentActionCreate,
    intentActionDelete, intentActionTransitionCreate, intentActionTransitionDelete,
    intentActionTransitionUpdateField,
    intentActionUpdateField, intentActionUpdateParamsField
} from "../actions/main";

const initValues = {
    intentId: null,
    name: null,
    description: null,
    botMenuId: null,
    botCommand: null,
    metaInfo: null,
    settingsMeta: null,
    settingsValue: null,
    isActive: false,
    answers: [],
    queries: [],
    actions: [],
    availableBotMenu: [],
    className: null,
    contactInfo: null,
    inProgress: false,
    isLoaded: false,
}

export const INTENT_TYPE_ASK = 'ask'
export const INTENT_TYPE_EMAIL = 'email'
export const INTENT_TYPE_TEXT = 'text'
export const INTENT_TYPE_SEND_CONTACT = 'contact'
export const INTENT_TYPE_SEND_LOCATION = 'location'
export const INTENT_TYPE_EXECUTE_URL = 'execute_url'

export default handleActions(
    {
        [getAvailableBotMenuForIntentSuccess]: (state, action) => {
            console.log('on.payload.value.items', action.payload.items)
            return {
                ...state,
                availableBotMenu: action.payload.items,
            };
        },
        [getIntentRequest]: (state) => {
            return {
                ...state,
                isLoaded: true,
                inProgress: true,
            };
        },
        [updateIntentField]: (state, action) => {
            return {
                ...state,
                [action.payload.field]: action.payload.value,
            }
        },
        [updateIntentSetting]: (state, action) => {
            const settingsName = action.payload.name
            const settingValue = action.payload.value

            console.log('settingsName, settingValue', settingsName, settingValue)

            const obj = {
                ...state,
                settingsValue: {...state.settingsValue, ...{[settingsName]: settingValue}},
            }

            console.log('updateIntentSetting - obj', obj)
            return obj
        },
        [updateIntentName]: (state, action) => {
            return {
                ...state,
                name: action.payload
            };
        },
        [createIntentQuery]: (state) => {
            const id = Math.random().toString(36).substring(7);
            const queries = ({
                ...state.queries, ...{
                    [id]: {
                        id: id,
                        isNew: true,
                        sentence: ''
                    }
                }
            })
            return {
                ...state,
                queries: queries
            }
        },
        [intentActionCreate]: (state) => {
            const id = Math.random().toString(36).substring(7);
            const actions = {
                ...state.actions, ...{
                    [id]: {
                        id: id,
                        description: '',
                        isNew: true,
                        type: '',
                        weight: 0,
                        manualTransition: false,
                        params: null,
                        settingsMeta: null,
                        settingsValue: null,
                        transitions: []
                    }
                }
            }
            return {
                ...state,
                actions: actions
            }
        },
        [intentActionUpdateField]: (state, action) => {
            let actions = {...state.actions}
            actions[action.payload.id] = {...actions[action.payload.id], ...{[action.payload.field]: action.payload.value}}
            return {
                ...state,
                ...{actions: actions},
            }
        },
        [intentActionUpdateParamsField]: (state, action) => {
            let actions = {...state.actions}
            const actionId = action.payload.id
            const field = action.payload.field
            const value = action.payload.value
            actions[actionId] = {
                ...actions[actionId], ...{
                    params: {
                        ...actions[actionId].params,
                        ...{[field]: value}
                    }
                }
            }
            return {
                ...state,
                ...{actions: actions},
            }
        },
        [intentActionDelete]: (state, action) => {
            const intentActionId = action.payload
            const actions = {...state.actions}
            delete actions[intentActionId]
            return {
                ...state,
                ...{actions: actions}
            }
        },


        [intentActionTransitionCreate]: (state, action) => {
            const id = Math.random().toString(36).substring(7);

            const intentActionId = action.payload.actionId

            const transitions = {
                ...state.actions[action.payload.actionId].transitions, ...{
                    [id]: {
                        id: id,
                        messageButtonName: '',
                        isNew: true,
                        action: '',
                        targetIntentId: null,
                    }
                }
            }
            return {
                ...state,
                ...{actions: {...state.actions, ...{[intentActionId]: {...state.actions[intentActionId], ...{transitions: transitions}}}}}
            }
        },

        [intentActionTransitionDelete]: (state, action) => {
            const transitionId = action.payload.transitionId;
            const intentActionId = action.payload.actionId;

            const transitions = state.actions[action.payload.actionId].transitions
            delete transitions[transitionId]
            return {
                ...state,
                ...{actions: {...state.actions, ...{[intentActionId]: {...state.actions[intentActionId], ...{transitions: transitions}}}}}
            }
        },

        [intentActionTransitionUpdateField]: (state, action) => {

            const transitionId = action.payload.transitionId
            const intentActionId = action.payload.actionId
            const field = action.payload.field
            const newValue = action.payload.value

            return {
                ...state,
                ...{
                    actions: {
                        ...state.actions,
                        ...{
                            [intentActionId]: {
                                ...state.actions[intentActionId], ...{
                                    transitions: {
                                        ...state.actions[intentActionId].transitions,
                                        ...{
                                            [transitionId]: {...state.actions[intentActionId].transitions[transitionId], ...{[field]: newValue}}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        [intentActionChangeType]: (state, action) => {
            const intentActionId = action.payload.id
            const newType = action.payload.type
            let actions = {...state.actions}
            let newTypeParams = {}
            if (newType === INTENT_TYPE_TEXT) {
                newTypeParams = {
                    answers: []
                }
            } else if (newType === INTENT_TYPE_SEND_CONTACT) {
                newTypeParams = {
                    firstname: '',
                    lastname: '',
                    phoneNum: '',
                }
            }
            actions[intentActionId] = {...actions[intentActionId], type: newType, params: newTypeParams}
            return {
                ...state,
                ...{actions: actions}
            }
        },
        [deleteIntentQuery]:
            (state, action) => {
                const deleteQueryId = action.payload
                console.log('state.queries:', state.queries)
                console.log('action.payload:', action.payload)
                const queries = {...state.queries}
                delete queries[deleteQueryId]

                return {
                    ...state,
                    ...{queries: queries}
                }
            },
        [updateIntentQuery]:
            (state, action) => {
                const updatedQuery = action.payload
                console.log('state.queries:', state.queries)
                console.log('action.payload:', action.payload)
                const queries = ({
                    ...state.queries, ...{
                        [updatedQuery.id]: {
                            id: updatedQuery.id,
                            isNew: state.queries[updatedQuery.id].isNew,
                            sentence: updatedQuery.sentence
                        }
                    }
                })
                console.log('updateIntentQuery.newQueries:', queries)
                // state.queries.map((k, item) => {
                //   console.log('item', item);
                //   if item.id == updatedQuery.id
                //     return item
                // })
                return {
                    ...state,
                    queries: queries
                }
            },
        [updateIntentContact]:
            (state, action) => {
                console.log('updateIntentContact', action.payload)
                return {
                    ...state,
                    ...{
                        contactInfo: {...state.contactInfo, ...{[action.payload.field]: action.payload.value}}
                    }
                }

            },
        [getIntentSuccess]:
            (state, action) => {

                let answers = []
                let contactInfo = null;
                console.log('action.payload.metaInfo', action.payload.metaInfo)
                if (action.payload.metaInfo != null) {
                    if (action.payload.metaInfo.answers) {
                        answers = action.payload.metaInfo.answers
                    }

                    if (action.payload.metaInfo.send_contact != null) {
                        contactInfo = {
                            firstname: action.payload.metaInfo.send_contact.firstname,
                            lastname: action.payload.metaInfo.send_contact.lastname,
                            phoneNum: action.payload.metaInfo.send_contact.phone_num,
                        }
                        console.log('reducer.contactInfo', contactInfo)
                    }
                }

                let queries = {}
                if (action.payload.queries) {
                    action.payload.queries.map(query => {
                        queries = {
                            ...queries, ...{
                                [query.id]: {
                                    id: query.id,
                                    isNew: false,
                                    sentence: query.sentence
                                }
                            }
                        }
                    })
                }

                let actions = {}
                if (action.payload.actions) {
                    action.payload.actions.map(actionItem => {

                        let transitions = {}
                        if (actionItem.transitions) {
                            actionItem.transitions.map(transitionItem => {
                                transitions = {
                                    ...transitions, ...{
                                        [transitionItem.id]: {
                                            id: transitionItem.id,
                                            action: transitionItem.action,
                                            targetIntentId: transitionItem.targetIntentId,
                                            messageButtonName: transitionItem.messageButtonName,
                                            deleteRule: transitionItem.deleteRule,
                                        }
                                    }
                                }
                            })
                        }


                        actions = {
                            ...actions, ...{
                                [actionItem.id]: {
                                    id: actionItem.id,
                                    isNew: false,
                                    type: actionItem.type,
                                    botMenuId: actionItem.botMenuId,
                                    weight: actionItem.weight,
                                    manualTransition: actionItem.manualTransition,
                                    description: actionItem.description,
                                    params: actionItem.params,
                                    transitions: transitions,
                                }
                            }
                        }
                    })
                }

                return {
                    ...state,
                    inProgress: false,
                    className: action.payload.className,
                    intentId: action.payload.id,
                    metaInfo: action.payload.metaInfo,
                    name: action.payload.name,
                    description: action.payload.description,
                    botMenuId: action.payload.botMenuId,
                    botCommand: action.payload.botCommand,
                    settingsMeta: action.payload.settingsMeta,
                    settingsValue: action.payload.settingsValue,
                    isActive: action.payload.isActive,
                    answers: answers,
                    actions: actions,
                    contactInfo: contactInfo,
                    queries: queries,
                };
            },
        [updateAnswerInTextIntentRequest]:
            (state, action) => {
                const idx = action.payload.index
                const text = action.payload.answer

                const newElement = {}
                newElement[idx] = text

                const newAnswers = Object.values({...state.answers, ...newElement})
                return {
                    ...state,
                    answers: newAnswers,
                };
            },
        [addAnswerInTextIntentAction]:
            (state, action) => {
                const newElement = {}
                console.log('state.answers.length', state.answers.length)
                newElement[state.answers.length] = action.payload

                const newAnswers = Object.values({...state.answers, ...newElement})
                return {
                    ...state,
                    answers: newAnswers,
                };
            },
        [removeAnswerFromTextIntentAction]:
            (state, action) => {
                const idx = action.payload
                const newAnswers = state.answers.slice()
                newAnswers.splice(idx, 1);
                console.log('newAnswers', newAnswers)
                return {
                    ...state,
                    answers: newAnswers,
                };
            }
    },
    initValues,
);

