import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Table from "react-bootstrap/Table";
import {loadNotificationList} from "../actions/main";

const ListPage = (props) => {

    useEffect(() => {
        props.loadNotificationList()
    }, []);

    const notifications = props.notificationListState.notifications.map(item => {

        return (<tr>
            <td style={{width: '130px', textAlign: 'center'}}>
                {item.id}
            </td>
            <td>
                <Link to={"/panel/notification/" + item.id}>{item.title}</Link>
            </td>
        </tr>)
    })

    return (<div className={"page-container"}>

        <div className={"header"}>

            <div className={"breadcrumbs"}>
                <ul>
                    <li>
                        Список уведомлений
                    </li>
                </ul>
            </div>

            <div className="info">
                <div className="caption">
                    <div className={"title"}>Уведомления</div>
                    <div className={"description"}>
                        В этом разделе происходит управление уведомлениями,
                        которые можно отправить вашей команде.
                    </div>
                </div>
                <div className="right-controls">
                    <Link
                        className={"btn-add-data"}
                        to={"/panel/notification/create"}>Отправить новое уведомление</Link>
                </div>
            </div>

        </div>

        <div className={"content"}>
            <Table hover>
                <thead>
                <tr>
                    <th style={{width: '130px', textAlign: 'center'}}>ID</th>
                    <th>Название</th>
                </tr>
                </thead>
                <tbody>
                {notifications}
                </tbody>
            </Table>
        </div>

    </div>)
};

const mapStateToProps = function (state) {
    return {
        notificationListState: state.notificationList,
    }
}

export default connect(mapStateToProps, {
    loadNotificationList,
})(ListPage);
