import { requestFormData } from "../../../utils/apiClient";
export var uploadPhoto = function (memberId, data) {
    var apiUrl = '/api/members/' + memberId + '/avatar';
    requestFormData(apiUrl, data)
        .then(function (data) {
        alert('success');
    })
        .catch(function (error) {
        alert('error on upload member avatar');
    });
};
