var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { listMembers, listTeams } from "../api/team";
import { MemberProfile } from "./MemberProfile";
import Select from "react-select";
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
export var TeamList = function () {
    var _a = useState([]), members = _a[0], setMembers = _a[1];
    var _b = useState(0), offset = _b[0], setOffset = _b[1];
    var _c = useState([]), teams = _c[0], setTeams = _c[1];
    var _d = useState([]), selectedTeam = _d[0], setSelectedTeam = _d[1];
    var _e = useState(''), searchQuery = _e[0], setSearchQuery = _e[1];
    useEffect(function () {
        listMembers(null, function (membersCollection) {
            setMembers(membersCollection.data);
        });
        listTeams(function (teamsCollection) {
            setTeams(teamsCollection.data);
        });
    }, []);
    var onPagination = function (offsetCorrection) {
        setOffset(offset + offsetCorrection);
    };
    var pageSize = 10;
    var membersForDisplay = members.slice(offset, pageSize + offset);
    var membersList = membersForDisplay.map(function (member) {
        return React.createElement(MemberProfile, { member: member });
    });
    var teamsListForSelect = teams.map(function (team) {
        return {
            label: team.name,
            value: team.id,
        };
    });
    teamsListForSelect = __spreadArray([{
            label: 'Все сотрудники компании',
            value: ''
        }], teamsListForSelect, true);
    return React.createElement("div", { className: "members-list-container" },
        React.createElement("div", { className: "filters" },
            React.createElement("div", { className: "filter-item" },
                React.createElement("label", null, "\u041A\u043E\u043C\u0430\u043D\u0434\u0430"),
                React.createElement(Select, { placeholder: "Фильтр по команде...", onChange: function (select) {
                        var selectedTeamId = null;
                        if (select === null || select === void 0 ? void 0 : select.value) {
                            selectedTeamId = select.value;
                        }
                        listMembers(selectedTeamId, function (membersCollection) {
                            setMembers(membersCollection.data);
                        });
                    }, options: teamsListForSelect }))),
        React.createElement("div", { className: "list" }, membersList),
        React.createElement("div", { className: "pagination-control" },
            offset > 0 &&
                React.createElement("button", { className: "btn btn-success", onClick: function () {
                        onPagination(-pageSize);
                    } },
                    React.createElement(SkipPreviousIcon, null),
                    "\u043F\u0440\u0435\u0434\u044B\u0434\u0443\u0449\u0438\u0435 10"),
            (offset + pageSize) < members.length &&
                React.createElement("button", { className: "btn btn-success", onClick: function () {
                        onPagination(pageSize);
                    } },
                    "\u0441\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0435 10",
                    React.createElement(SkipNextIcon, null))));
};
