var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    inProgress: false,
    isFailure: null
};
var authLinkSlice = createSlice({
    name: 'currentUser',
    initialState: initialState,
    reducers: {
        success: function (state) {
            return __assign(__assign({}, state), {
                inProgress: false,
                isFailure: false,
            });
        },
        progress: function (state) {
            return __assign(__assign({}, state), {
                inProgress: true,
                isFailure: null,
            });
        },
        failure: function (state) {
            return __assign(__assign({}, state), {
                inProgress: false,
                isFailure: true,
            });
        },
    }
});
export var success = (_a = authLinkSlice.actions, _a.success), progress = _a.progress, failure = _a.failure;
export default authLinkSlice.reducer;
