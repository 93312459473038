import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import {loadBotVersions, createBotVersion} from "../actions/main";
import {loadBotInfo, setBotInfoFieldValue, updateBotInfo} from "../actions/bot_data";

const botId = 1

const VersionsListPage = (props) => {

    useEffect(() => {
        props.loadBotInfo()
        props.loadBotVersions(botId)
    }, []);

    const versions = props.botVersionState.versions.map(item => {

        return (<tr>
            <td>
                #{item.id}
            </td>
            <td>
                <div style={{margin: 5, fontSize: 16, fontWeight: 'bold'}}>
                    {item.status}
                </div>
            </td>
            <td>
                {item.instanceUrl}
            </td>
        </tr>)
    })

    return (<div>

        <h3>Настройки чат-бота</h3>
        <div className="form-group">
            <label htmlFor="exampleInputEmail1">Telegram Token:</label>
            <div>
                <input type="text" className="form-control"
                       onChange={element => {
                           props.setBotDataFieldValue({field: 'telegramBotToken', value: element.target.value})
                       }}
                       value={props.botInfoState.botInfo.telegramBotToken}/>
            </div>
        </div>

        <div style={{marginTop: 20}}>
            <button className="btn btn-success"
                    onClick={() => {
                        props.updateBotInfo({
                            telegramBotToken: props.botInfoState.botInfo.telegramBotToken,
                        })
                    }}
                    type="button">Обновить информацию
            </button>
        </div>

        <hr/>

        <h3>Список обученных моделей
            <button className="btn btn-primary btn-sm"
                    onClick={() => {
                        props.createBotVersion(botId)
                    }}
                    type="button">Обучить новую версию
            </button>

        </h3>

        <Table striped bordered hover size="sm">
            <thead>
            <tr>
                <td>version num.</td>
                <td>status</td>
                <td>Instance URL</td>
            </tr>
            </thead>
            <tbody>
            {versions}
            </tbody>
        </Table></div>)
};

const mapStateToProps = function (state) {
    return {
        botVersionState: state.botVersion,
        botInfoState: state.botInfo,
    }
}

export default connect(mapStateToProps, {
    loadBotVersions,
    loadBotInfo,
    updateBotInfo,
    createBotVersion,
    setBotDataFieldValue: setBotInfoFieldValue
})(VersionsListPage);
