import {handleActions} from 'redux-actions';
import {getTeamsListSuccess} from "../actions/main";

const initValues = {
    teams: [],
    inProgress: false,
    isLoaded: false
}
export default handleActions(
    {
        [getTeamsListSuccess]: (state, action) => {

            const teams = action.payload.teams.map(team => {
                return {
                    id: team.id,
                    name: team.name,
                }
            });

            return {
                ...state,
                isLoaded: true,
                inProgress: false,
                teams: teams,
            };
        },
    },
    initValues,
);
