import {requests} from "../../../utils/requests";

const Members = {
    list: () => requests.get('/api/members'),
    getProfile: (id) => requests.get('/api/members/' + id),
    create: (data) => requests.post('/api/members', data),
    update: (id, data) => requests.post('/api/members/' + id, data),
};

export default Members
