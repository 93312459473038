import React from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {saveNewTeamData, setNewTeamData} from "../actions/main";
import MemberSelector from "../../../components/MemberSelector";
import {Navigate} from "react-router-dom";
import {teamProfileUrl} from "../UrlFactory";

const TeamCreatePage = (props) => {

    if (props.teamCreateState.justCreated) {
        const newTeamProfileUrl = teamProfileUrl(props.teamCreateState.newTeamId).getPublicUrl()
        return <Navigate to={newTeamProfileUrl}/>
    }

    const team = props.teamCreateState.newTeam

    const submitNewTeamData = () => {
        let requestData = {
            name: team.name,
            lead_id: team.leadId,
        }
        console.log('submitNewTeamData.requestData', requestData)
        props.saveNewTeamData(requestData)
    }

    return (<div className={"d-flex flex-column-fluid"}>
        <div className={"container"}>
            <div className={"card card-custom"}>

                <div className={"card-body"}>
                    <button onClick={submitNewTeamData} className={"btn"}>
                        Создать команду
                    </button>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Название команды</label>
                        <div className="col-9">
                            <input type={"text"} className={"form-control"}
                                   onChange={element => {
                                       props.setNewTeamData({field: 'name', value: element.target.value})
                                   }}
                                   value={team.name}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Руководитель команды </label>
                        <div className="col-9">

                            <MemberSelector onSelect={member => {
                                props.setNewTeamData({field: 'leadId', value: member.id})
                            }}/>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>)
};

const mapStateToProps = function (state) {
    return {
        teamCreateState: state.teamCreate,
    }
}

TeamCreatePage.propTypes = {
    setNewTeamData: PropTypes.func,
    saveNewTeamData: PropTypes.func,
    teamCreateState: PropTypes.object
}

export default connect(mapStateToProps, {
    setNewTeamData,
    saveNewTeamData
})(TeamCreatePage);
