import { requestGET } from "../../../utils/apiClient";
export function listMembers(teamId, successfulCallback) {
    var url = '/api/boord/members?';
    if (teamId) {
        url += 'teamId=' + teamId;
    }
    requestGET(url)
        .then(successfulCallback)
        .catch(function (error) {
        console.log(error);
        alert('error request ex list');
    });
}
export function listTeams(successfulCallback) {
    requestGET('/api/boord/teams')
        .then(successfulCallback)
        .catch(function (error) {
        console.log(error);
        alert('error on fetch teams list');
    });
}
export function getProfile(memberId, successfulCallback) {
    requestGET('/api/boord/member/' + memberId)
        .then(successfulCallback)
        .catch(function (error) {
        console.log(error);
        alert('error on fetch member profile');
    });
}
