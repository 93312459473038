import {handleActions} from 'redux-actions';
import {
    requestSaveNewProjectSuccess,
    setNewProjectData
} from "../actions/main";

const initValues = {
    newProject: {
        name: null,
        description: null,
        additionalTextUrl: null,
    },
    newProjectId: null,
    justCreated: false,
    inProgress: false
}
export default handleActions(
    {
        [requestSaveNewProjectSuccess]: (state, action) => {
            return {
                ...state,
                ...{
                    justCreated: true,
                    newProjectId: action.payload.project.id,
                }
            }
        },
        [setNewProjectData]: (state, action) => {
            const field = action.payload.field
            const value = action.payload.value

            return {
                ...state,
                newProject: {...state.newProject, ...{[field]: value}}
            }
        },
    },
    initValues,
);
