import React, { useEffect } from "react";
import { listContent } from "../api/content";
import { connect } from 'react-redux';
import { setEditableContentAction } from "../actions/main";
import ContentMenuListComponent from "./ContentMenuListComponent";
import ContentDataComponent from "./ContentDataComponent";
import "../styles/Style.scss";
import Logo from "../../../layout/Logo";
import UserProfile from "../../../layout/UserProfile";
var BoordPage = function (props) {
    useEffect(function () {
        props.listContent();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Logo, null),
        React.createElement("div", { className: "content-menu-left-side" },
            React.createElement(ContentMenuListComponent, null)),
        React.createElement("div", { className: "content-area" },
            React.createElement(UserProfile, null),
            React.createElement(ContentDataComponent, null))));
};
var mapStateToProps = function (state) {
    return {
        boordCreateState: state.boordCreate,
    };
};
export default connect(mapStateToProps, {
    setEditableContent: setEditableContentAction,
    listContent: listContent
})(BoordPage);
