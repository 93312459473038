import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import React from 'react';
import { connect } from "react-redux";
import { setActiveContentAction2, updateContentMenuTitleAction } from "../actions/main";
import { setActive } from "../reducers/list";
export var ItemTypes = {
    CARD: 'card',
};
var MenuEditableItem = function (props) {
    var contentItem = props.contentItem;
    var index = props.index;
    var moveCard = props.moveCard;
    var onDrop = props.onDrop;
    var setActiveContentAction = props.setActive;
    var acceptId = 'parent-' + contentItem.parentId;
    var ref = useRef(null);
    var _a = useDrop({
        accept: acceptId,
        collect: function (monitor) {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                handlerId: monitor.getHandlerId(),
            };
        },
        drop: function (item, monitor) {
            var dragIndex = item.index;
            onDrop(item.contentItem, dragIndex);
        },
        hover: function (item, monitor) {
            var _a;
            if (!ref.current) {
                return;
            }
            var dragIndex = item.index;
            var hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            var hoverBoundingRect = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            // Get vertical middle
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            var clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            console.log('item.contentItem', item.contentItem);
            // Time to actually perform the action
            moveCard(item.contentItem, dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    }), _b = _a[0], isOver = _b.isOver, canDrop = _b.canDrop, handlerId = _b.handlerId, drop = _a[1];
    var _c = useDrag({
        type: acceptId,
        item: function () {
            return { contentItem: contentItem, index: index };
        },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), isDragging = _c[0].isDragging, drag = _c[1];
    var opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    var renderItem = function (contentItem) {
        if (props.boordEditState.id == contentItem.id) {
            return (React.createElement("input", { type: "text", onChange: function (event) {
                    console.log('event.target.value', event.target.value);
                    props.updateContentMenuTitleAction(event.target.value);
                }, value: props.boordEditState.menuTitle ? props.boordEditState.menuTitle : '' }));
        }
        else {
            var title = contentItem.menu.title ? contentItem.menu.title : React.createElement("i", null, "\u0431\u0435\u0437 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u044F");
            return React.createElement("span", { onClick: function () {
                    setActiveContentAction(contentItem.id);
                    props.setActiveContentAction2(contentItem.id);
                } }, title);
        }
    };
    var classNames = ["menu-item"];
    if (props.boordListState.activeContent && props.boordListState.activeContent.id == contentItem.id) {
        classNames.push('current');
    }
    var isPassed = props.boordListState.passedContents[contentItem.id];
    if (isPassed) {
        classNames.push('passed');
    }
    classNames.push("child-level-" + props.childLevel);
    return (React.createElement("div", { className: classNames.join(' '), ref: ref, style: { opacity: opacity }, "data-handler-id": handlerId }, renderItem(contentItem)));
};
var mapStateToProps = function (state) {
    return {
        boordListState: state.boordList,
        boordEditState: state.boordEdit,
    };
};
export default connect(mapStateToProps, {
    setActive: setActive,
    updateContentMenuTitleAction: updateContentMenuTitleAction,
    setActiveContentAction2: setActiveContentAction2
})(MenuEditableItem);
