import React, { useEffect, useState } from "react";
import { fetchProjectsList } from "../api/projects";
import { MemberInlineProfile } from "./MemberInlineProfile";
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import WebIcon from '@mui/icons-material/Web';
var ProjectItem = function (props) {
    var memberItems = props.project.members.map(function (member) {
        var mentionItem = {
            mentionId: member.id,
            title: member.name,
        };
        return React.createElement(MemberInlineProfile, { mentionItem: mentionItem });
    });
    var project = props.project;
    var memberRendered = [];
    memberItems.map(function (item, key) {
        memberRendered.push(item);
        if (key + 1 !== memberItems.length) {
            memberRendered.push(React.createElement(React.Fragment, null, ", "));
        }
    });
    return React.createElement("div", { className: "project-item" },
        React.createElement("div", { className: "name" }, props.project.name),
        React.createElement("div", { className: "description" }, props.project.description),
        React.createElement("div", { className: "links" },
            project.appstoreUrl && React.createElement("div", { className: "link appstore" },
                React.createElement(AppleIcon, null),
                React.createElement("a", { target: "_blank", href: project.appstoreUrl }, "\u041F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u0434\u043B\u044F iOS")),
            project.googlePlayUrl && React.createElement("div", { className: "link android" },
                React.createElement(AndroidIcon, null),
                React.createElement("a", { target: "_blank", href: project.googlePlayUrl }, "\u041F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u0434\u043B\u044F Android")),
            project.websiteUrl && React.createElement("div", { className: "link web" },
                React.createElement(WebIcon, null),
                React.createElement("a", { target: "_blank", href: project.websiteUrl }, "\u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043F\u0440\u043E\u0435\u043A\u0442\u0430"))),
        memberItems.length > 0 &&
            React.createElement("div", { className: "team" },
                React.createElement("div", { className: "t" }, "\u041F\u0440\u043E\u0435\u043A\u0442\u043E\u043C \u0437\u0430\u043D\u0438\u043C\u0430\u044E\u0442\u0441\u044F:"),
                memberRendered));
};
export var ProjectsList = function () {
    var _a = useState([]), projects = _a[0], setProjects = _a[1];
    useEffect(function () {
        fetchProjectsList(function (projectsList) {
            setProjects(projectsList);
        }, function () {
        });
    }, []);
    var projectsList = projects.map(function (project) {
        return React.createElement(ProjectItem, { project: project });
    });
    return React.createElement("div", { className: "projects-list-container" }, projectsList);
};
