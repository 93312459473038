import React, {useState} from "react";
import {connect} from 'react-redux';
import {createNotification} from "../actions/main";
import history from "../../../utils/history";
import {BotEditor} from "../../../components/BotEditor";

const CreatePage = () => {

    const [title, setTitle] = useState('')
    const [editorData, setEditorData] = useState('[{"type": "p", "children": [{"text": "текст уведомления"}]}]')

    const submitNewNotification = () => {
        let requestData = {
            title: title,
            editorData: editorData,
        }
        createNotification(requestData).then(data => {
            history.push('/panel/notification/' + data.notification.id)
        }).catch(() => {
            alert('error')
        })
    }

    return (<div className={"d-flex flex-column-fluid"}>
        <div className={"container"}>
            <div className={"card card-custom"}>

                <div className={"card-body"}>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Название</label>
                        <div className="col-9">
                            <input type={"text"} className={"form-control"}
                                   onChange={element => {
                                       setTitle(element.target.value)
                                   }}
                                   value={title}/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Сообщение</label>
                        <div className="col-9">
                            <BotEditor
                                initialEditorData={editorData}
                                onTextChange={v => {
                                    setEditorData(v.editorData)
                                }}/>
                        </div>
                    </div>

                    <button onClick={submitNewNotification} className={"btn"}>
                        Создать уведомление
                    </button>

                </div>
            </div>
        </div>
    </div>)
};

const mapStateToProps = function (state) {
    return {}
}

export default connect(mapStateToProps, {})(CreatePage);
