var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFocused, useSelected } from "slate-react";
import React from "react";
export var ProjectsEditorElement = function (props) {
    var attributes = props.attributes;
    var selected = useSelected();
    var focused = useFocused();
    return (React.createElement("div", __assign({}, attributes, { contentEditable: false, style: {
            padding: '3px 3px 2px',
            margin: '0 1px',
            verticalAlign: 'baseline',
            borderRadius: '4px',
            backgroundColor: '#eee',
            fontSize: '0.9em',
            boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        } }), "projects"));
};
export var withProjects = function (editor) {
    var isInline = editor.isInline, isVoid = editor.isVoid;
    editor.isInline = function (element) {
        return element.type === 'projects' ? false : isInline(element);
    };
    editor.isVoid = function (element) {
        return element.type === 'projects' ? false : isVoid(element);
    };
    return editor;
};
