export function clearSession() {
    localStorage.removeItem('authSession');
}

export function saveSession(sessionId) {
    localStorage.setItem('authSession', sessionId);
}

export function getSession() {
    return localStorage.getItem('authSession');
}
