import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {intentActionUpdateField} from "../actions/main";
import InputMask from 'react-input-mask'

const SendContactAction = (props) => {

    const action = props.action

    const updateContactData = (field, value) => {
        props.intentActionUpdateField({
            id: action.id, field: 'params', value: {
                ...action.params, ...{[field]: value}
            }
        })
    }

    return (
        <div>

            <div className="form-group row">
                <label className="col-3 col-form-label">Имя:</label>
                <div className="col-9">

                    <input className="form-control" type={"text"}
                           onChange={el => {
                               updateContactData('firstname', el.target.value)
                           }}
                           value={action.params.firstname}/>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-3 col-form-label">Фамилия:</label>
                <div className="col-9">
                    <input className="form-control" type={"text"}
                           onChange={el => {
                               updateContactData('lastname', el.target.value)
                           }}
                           value={action.params.lastname}/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-3 col-form-label">Номер телефона:</label>
                <div className="col-9">
                    <InputMask mask="+7 (999) 999-99-99" className="form-control" type={"text"}
                               onChange={el => {
                                   updateContactData('phoneNum', el.target.value)
                               }}
                               value={action.params.phoneNum}/>
                </div>
            </div>

        </div>
    );
};

SendContactAction.propTypes = {
    action: PropTypes.object,
    intentActionUpdateField: PropTypes.func,
}

const mapStateToProps = function (state) {
    return {
        intentState: state.intentProfile,
    }
}

export default connect(mapStateToProps, {
    intentActionUpdateField
})(SendContactAction);
