import React, { useEffect } from "react";
import { connect } from 'react-redux';
import '../styles/Auth.scss';
import { useSearchParams } from "react-router-dom";
import { TableSpinner } from "../../../components/CustomSpinner";
import { checkGoogleAuth } from "../actions/authNew";
var AuthGoogleCheckPage = function (props) {
    var searchParams = useSearchParams()[0];
    var state = searchParams.get('state');
    var code = searchParams.get('code');
    useEffect(function () {
        props.checkGoogleAuth(code, state);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "check-google-auth-process" },
            React.createElement(TableSpinner, null))));
};
var mapStateToProps = function (state) {
    return {
        authState: state.auth,
    };
};
export default connect(mapStateToProps, {
    checkGoogleAuth: checkGoogleAuth
})(AuthGoogleCheckPage);
