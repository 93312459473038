import {requests} from "../../../utils/requests";

const NotificationApi = {
    getList: () => requests.get('/api/notifications'),
    create: (notificationData) => requests.post('/api/notification', notificationData),
    update: (notificationId, notificationData) => requests.put('/api/notification/' + notificationId, notificationData),
    test: (notificationId, notificationData) => requests.post('/api/notification/' + notificationId + '/test', notificationData),
    getById: (notificationId) => requests.get('/api/notification/' + notificationId),
};

export default NotificationApi
