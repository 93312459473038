import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import { clearSession } from "../modules/login/helpers/authSessionStorage";
import { connect } from "react-redux";
import { logout } from "../modules/login/reducers/currentUser";
import { useNavigate } from "react-router-dom";
var UserProfile = function (props) {
    var navigate = useNavigate();
    var onLogout = function () {
        clearSession();
        props.logout();
        navigate('/login');
    };
    return React.createElement("div", { className: "admin-header" }, props.currentUserState.authorized &&
        React.createElement("div", { className: "current-user" },
            React.createElement("span", null, props.currentUserState.name),
            React.createElement("a", { className: "logout-button", onClick: onLogout },
                React.createElement(LogoutIcon, null))));
};
var mapStateToProps = function (state) {
    return {
        currentUserState: state.currentUser,
    };
};
export default connect(mapStateToProps, {
    logout: logout
})(UserProfile);
