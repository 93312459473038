import {handleActions} from 'redux-actions';
import {getIntentsListSuccess} from "../../../actions/intent";

const initValues = {
    intents: [],
    isLoading: false
}
export default handleActions(
    {
        [getIntentsListSuccess]: (state, action) => {
            return {
                ...state,
                intents: action.payload.intents,
            };
        },
    },
    initValues,
);

