import {requests} from "../../../utils/requests";

const Teams = {
    list: () => requests.get('/api/teams'),
    get: (teamId) => requests.get('/api/teams/' + teamId),
    create: (requestData) => requests.post('/api/teams', requestData),
    update: (id, requestData) => requests.post('/api/teams/' + id, requestData)
};

export default Teams
