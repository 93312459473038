import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {loadTeamsList} from "../modules/teams/actions/main";

const TeamSelector = (props) => {

    useEffect(() => {
        props.loadTeamsList()
    }, [])

    const teamsByKey = []
    const items = props.teamsListState.teams.map(team => {

        teamsByKey[team.id] = team.name
        return {
            value: team.id,
            label: team.name
        }

        // return <MenuItem key={'team_' + team.id} value={team.id}>{team.name}</MenuItem>
    });
    console.log('props.selectedTeamId', props.selectedTeamId)
    return (<div>
        <Select value={{value: props.selectedTeamId, label: teamsByKey[props.selectedTeamId]}}
                options={items}
                onChange={(item) => {
                    console.log('item', item)
                    props.onSelect({id: item.value})
                }}/>
    </div>)
};

const mapStateToProps = function (state) {
    return {
        teamsListState: state.teamsList,
    }
}

TeamSelector.propTypes = {
    selectedTeamId: PropTypes.number,
    onSelect: PropTypes.func,
    loadTeamsList: PropTypes.func,
    teamsListState: PropTypes.object,
}

export default connect(mapStateToProps, {
    loadTeamsList,
})(TeamSelector);
