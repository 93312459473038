import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {fetchNotificationById, sendTestNotification, updateNotification} from "../actions/main";
import MemberSelector from "../../../components/MemberSelector";
import {Checkbox} from "@material-ui/core";
import {BotEditor} from "../../../components/BotEditor";
import {Link, useParams} from "react-router-dom";

const EditPage = (props) => {

    const {notificationId} = useParams();

    const [dataLoaded, setDataLoaded] = useState(false)
    const [allRecipient, sendForAllRecipient] = useState(false)
    const [notificationData, setData] = useState({
        title: '',
        editorData: '[{"type": "p", "children": [{"text": "привет"}]}]',
        testMemberIds: [],
        exceptMemberIds: [],
    })

    useEffect(() => {
        fetchNotificationById(notificationId).then(data => {
            console.log('then(data', data)
            setData({
                title: data.notification.title,
                editorData: data.notification.editorData,
            })
            setDataLoaded(true)
        }).catch(() => {
            alert('error')
        })
    }, [])

    const submitNotification = () => {
        let requestData = {
            title: notificationData.title,
            editorData: notificationData.editorData,
        }
        console.log('submitNewProjectData.requestData', requestData)
        updateNotification(notificationId, requestData).then(data => {
            alert('Данные успешно сохранены')
        }).catch(() => {
            alert('error')
        })
    }

    const sendTestMessage = () => {
        let requestData = {
            title: notificationData.title,
            editorData: notificationData.editorData,
            member_ids: notificationData.testMemberIds,
            except_member_ids: notificationData.exceptMemberIds,
            send_for_all: allRecipient ? 1 : 0
        }
        console.log('sendTestMessage.requestData', requestData)
        sendTestNotification(notificationId, requestData).then(data => {
            alert('Сообщение успешно отправлено')
        }).catch(() => {
            alert('error')
        })
    }

    return (<div className={"page-container"}>

        <div className={"header"}>

            <div className={"breadcrumbs"}>
                <ul>
                    <li>
                        <Link to={"/notifications"}>Уведомления</Link>
                    </li>
                    <li>Редактирование уведомления «{notificationData.title}»</li>
                </ul>
            </div>
        </div>

        <div className={"content"}>

            <div className="form-group row">
                <label className="col-form-label col-3 text-lg-right text-left">Название</label>
                <div className="col-9">
                    <input type={"text"} className={"form-control"}
                           onChange={element => {
                               setData({...notificationData, ...{title: element.target.value}})
                           }}
                           value={notificationData.title}/>
                </div>
            </div>

            {dataLoaded ?
                <div className="form-group row">
                    <label className="col-form-label col-3 text-lg-right text-left">Сообщение</label>
                    <div className="col-9">

                        <BotEditor
                            initialEditorData={notificationData.editorData}
                            onTextChange={v => {
                                setData({...notificationData, ...{editorData: v.editorData}})
                            }}/>
                    </div>
                </div> : ''}

            <div style={{textAlign: 'center'}}>
                <button onClick={submitNotification} className={"btn btn-sm btn-success"}>
                    Сохранить изменения
                </button>
            </div>

            <hr/>

            <div style={{width: '800px', margin: '0 auto'}}>
                <h4>Отправка уведомления</h4>
                <p style={{color: '#999'}}>
                    Укажите получателей этого уведомления.
                </p>

                <div className="form-group row">
                    <label className="col-form-label col-4 text-lg-right text-left">Отправить всем сотрудникам</label>
                    <div className="col-8">
                        <Checkbox
                            onClick={element => {
                                sendForAllRecipient(element.target.checked)
                            }}
                            checked={allRecipient}
                        />
                    </div>
                </div>

                {!allRecipient ?

                    <div>
                        <div className="form-group row">
                            <label className="col-form-label col-4 text-lg-right text-left">Отправить выбранным:</label>
                            <div className="col-8">

                                <MemberSelector
                                    isMulti={true}
                                    onSelect={(selectorData) => {
                                        setData({...notificationData, ...{testMemberIds: selectorData.values}})
                                    }}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label col-4 text-lg-right text-left">Отправить всем, за
                                исключением выбранных:</label>
                            <div className="col-8">
                                <MemberSelector
                                    isMulti={true}
                                    onSelect={(selectorData) => {
                                        setData({...notificationData, ...{exceptMemberIds: selectorData.values}})
                                    }}/>
                            </div>
                        </div>
                    </div>
                    : ''}
                <div style={{textAlign: 'center'}}>
                    <button onClick={sendTestMessage} className={"btn btn-sm btn-success"}>
                        Отправить уведомление
                    </button>
                </div>
            </div>

        </div>
    </div>)
};

const mapStateToProps = function (state) {
    return {}
}

export default connect(mapStateToProps, {})(EditPage);
