import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {connect} from 'react-redux';
import Table from "@material-ui/core/Table";
import {
    botCommandMenuCreateItem,
    botCommandMenuRemoveItem,
    botCommandMenuUpdateField,
    botCommandMenuUpdateItemField,
    loadBotCommandsList, updateBotCommandGroup
} from "../actions/bot_commands";

const botId = 1

const BotCommandMenuPage = (props) => {

    const menuId = props.match.params.id

    const selectedMenu = props.botCommandGroupState.menus[menuId]


    const renderedMenuItems = []

    const renderMenuItem = (item) => {
        return (
            <div style={{marginLeft: '25px'}}>
                <div className="form-group">
                    <label htmlFor="intentNameField">Название</label>
                    <input type="text"
                           className="form-control"
                           onChange={element => {
                               props.botCommandMenuUpdateItemField({
                                   menuId: menuId,
                                   itemId: item.id,
                                   field: 'name',
                                   value: element.target.value
                               })
                           }}
                           value={item.name}
                           placeholder="Укажите название пункта"/>
                </div>
                <div className="form-group">
                    <label htmlFor="intentNameField">Команда</label>
                    <input type="text"
                           className="form-control"
                           onChange={element => {
                               props.botCommandMenuUpdateItemField({
                                   menuId: menuId,
                                   itemId: item.id,
                                   field: 'command',
                                   value: element.target.value
                               })
                           }}
                           value={item.command}
                           placeholder="Укажите название команды"/>
                </div>
                <div>
                    <a className={"btn btn-danger btn-sm btn-sm-remove"} onClick={() => {
                        props.botCommandMenuRemoveItem({menuId: menuId, itemId: item.id})
                    }}>удалить этот пункт меню
                    </a>
                </div>
            </div>
        )
    };

    Object.values(selectedMenu.items).map(menuItem => {
        renderedMenuItems.push(renderMenuItem(menuItem))
    })

    return (
        <div className={"main-content-container intent-page"}>
            <h1>Управление пунктами меню #{menuId}</h1>

            <div className="form-group">
                <label htmlFor="intentNameField">Название меню</label>
                <input type="text"
                       className="form-control"
                       onChange={element => {
                           props.botCommandMenuUpdateField({menuId: menuId, field: 'name', value: element.target.value})
                       }}
                       value={selectedMenu.name}
                       placeholder="Укажите название меню"/>
            </div>
            <hr/>
            <h4>Пункты меню

                <button className="btn btn-info btn-sm"
                        onClick={() => {
                            props.botCommandMenuCreateItem({menuId: menuId})
                        }}
                        type="button">Добавить пункт меню
                </button>
            </h4>
            {renderedMenuItems}
            <br/>
            <hr/>
            <button className="btn btn-primary btn-sm-remove"
                    onClick={() => {

                        const items = []
                        Object.values(selectedMenu.items).map(item => {
                            items.push({
                                id: item.id,
                                name: item.name,
                                command: item.command,
                            });
                        });
                        const requestData = {
                            name: selectedMenu.name,
                            items: items
                        }
                        props.updateBotCommandGroup(menuId, requestData)
                    }}
                    type="button">Сохранить изменения
            </button>
        </div>)
};

const mapStateToProps = function (state) {
    return {
        botCommandGroupState: state.botCommandMenu,
    }
}

export default connect(mapStateToProps, {
    loadBotMenuList: loadBotCommandsList,
    updateBotCommandGroup,
    botCommandMenuUpdateField,
    botCommandMenuUpdateItemField,
    botCommandMenuRemoveItem,
    botCommandMenuCreateItem,
})(BotCommandMenuPage);
