var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Transforms } from "slate";
import { ReactEditor, useFocused, useSelected, useSlateStatic } from "slate-react";
import { css } from '@emotion/css';
import { Button } from "react-bootstrap";
import isUrl from "is-url";
export var withImages = function (editor) {
    var insertData = editor.insertData, isVoid = editor.isVoid;
    editor.isVoid = function (element) {
        return element.type === 'image' ? true : isVoid(element);
    };
    editor.insertData = function (data) {
        var text = data.getData('text/plain');
        var files = data.files;
        if (files && files.length > 0) {
            var _loop_1 = function (file) {
                var reader = new FileReader();
                var mime = file.type.split('/')[0];
                if (mime === 'image') {
                    reader.addEventListener('load', function () {
                        var url = reader.result;
                        insertImage(editor, url);
                    });
                    reader.readAsDataURL(file);
                }
            };
            for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
                var file = files_1[_i];
                _loop_1(file);
            }
        }
        else if (isImageUrl(text)) {
            insertImage(editor, text);
        }
        else {
            insertData(data);
        }
    };
    return editor;
};
export var InsertImageButton = function () {
    var editor = useSlateStatic();
    return (React.createElement(Button, { onMouseDown: function (event) {
            event.preventDefault();
            var url = window.prompt('Enter the URL of the image:');
            if (url && !isImageUrl(url)) {
                alert('URL is not an image');
                return;
            }
            insertImage(editor, url);
        } },
        React.createElement("div", null, "image")));
};
var insertImage = function (editor, url) {
    var text = { text: '' };
    var image = {
        type: 'image',
        imageWidth: '100%',
        url: url,
        children: [text]
    };
    Transforms.insertNodes(editor, image);
    var p = {
        type: 'p',
        children: [
            {
                text: '',
            },
        ],
    };
    Transforms.insertNodes(editor, p);
};
// @ts-ignore
export var ImageEditorElement = function (_a) {
    var attributes = _a.attributes, children = _a.children, element = _a.element;
    var editor = useSlateStatic();
    var path = ReactEditor.findPath(editor, element);
    var selected = useSelected();
    var focused = useFocused();
    var _b = useState('100%'), imageWidth = _b[0], setImageWidth = _b[1];
    var onWidthChange = function (event) {
        var newWidth = event.target.value;
        Transforms.setNodes(editor, 
        // @ts-ignore
        { imageWidth: newWidth }, {
            // This path references the editor, and is expanded to a range that
            // will encompass all the content of the editor.
            at: path
        });
        setImageWidth(newWidth);
    };
    return (React.createElement("div", __assign({}, attributes),
        children,
        React.createElement("div", { contentEditable: false, className: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          position: relative;\n        "], ["\n          position: relative;\n        "]))) },
            React.createElement("img", { src: element.url, width: imageWidth, className: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            display: block;\n            max-width: 100%;\n            max-height: 20em;\n            box-shadow: ", ";\n          "], ["\n            display: block;\n            max-width: 100%;\n            max-height: 20em;\n            box-shadow: ", ";\n          "])), selected && focused ? '0 0 0 3px #B4D5FF' : 'none') }),
            React.createElement(Button, { active: true, onClick: function () { return Transforms.removeNodes(editor, { at: path }); }, className: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            display: ", ";\n            position: absolute;\n            top: 0.5em;\n            left: 0.5em;\n            background-color: white;\n          "], ["\n            display: ", ";\n            position: absolute;\n            top: 0.5em;\n            left: 0.5em;\n            background-color: white;\n          "])), selected && focused ? 'inline' : 'none') },
                React.createElement("div", null, "del")),
            React.createElement("input", { type: "text", value: imageWidth, onChange: onWidthChange }))));
};
var isImageUrl = function (url) {
    if (!url)
        return false;
    if (!isUrl(url))
        return false;
    var ext = new URL(url).pathname.split('.').pop().toLowerCase();
    var extensions = ['png', 'jpeg', 'jpg', 'gif'];
    return extensions.indexOf(ext) > -1;
};
var templateObject_1, templateObject_2, templateObject_3;
