import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    fetchTeamProfile,
    saveUpdatedTeamData,
    setUpdatedTeamData
} from "../actions/main";
import MemberSelector from "../../../components/MemberSelector";
import {useParams} from "react-router-dom";

const TeamEditPage = (props) => {

    const {teamId} = useParams();

    useEffect(() => {
        props.fetchTeamProfile(teamId)
    }, [])

    const team = props.teamUpdateState.team

    const submitUpdatedTeamData = () => {
        let requestData = {
            name: team.name,
            bot_command_alias: team.botCommand,
            lead_id: team.leadId,
        }
        console.log('submitUpdatedTeamData.requestData', requestData)
        props.saveUpdatedTeamData(teamId, requestData)
    }

    return (<div className={"d-flex flex-column-fluid"}>
        <div className={"container"}>
            <div className={"card card-custom"}>

                <div className={"card-body"}>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Название команды</label>
                        <div className="col-9">
                            <input type={"text"} className={"form-control"}
                                   onChange={element => {
                                       props.setUpdatedTeamData({field: 'name', value: element.target.value})
                                   }}
                                   value={team.name}/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Bot Command</label>
                        <div className="col-9">

                            <input type={"text"} className={"form-control"}
                                   onChange={element => {
                                       props.setUpdatedTeamData({field: 'botCommand', value: element.target.value})
                                   }} value={team.botCommand}/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Руководитель </label>
                        <div className="col-9">

                            <MemberSelector
                                selectedMemberId={team.leadId}
                                onSelect={member => {
                                    props.setUpdatedTeamData({field: 'leadId', value: member.id})
                                }}/>

                        </div>
                    </div>

                    <div className={"text-center"}>
                        <button onClick={submitUpdatedTeamData} className={"btn btn-success"}>
                            Сохранить изменения
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>)
};

const mapStateToProps = function (state) {
    return {
        teamUpdateState: state.teamUpdate,
    }
}

TeamEditPage.propTypes = {
    setUpdatedTeamData: PropTypes.func,
    saveUpdatedTeamData: PropTypes.func,
    fetchTeamProfile: PropTypes.func,
    teamUpdateState: PropTypes.object,
    match: PropTypes.object,
}

export default connect(mapStateToProps, {
    setUpdatedTeamData,
    saveUpdatedTeamData,
    fetchTeamProfile,
})(TeamEditPage);
