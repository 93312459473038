import {createAction} from "redux-actions";
import Teams from "../api/main";

export const getTeamsListRequest = createAction('getTeamsListRequest');
export const getTeamsListSuccess = createAction('getTeamsListSuccess');
export const getTeamsListFailure = createAction('getTeamsListFailure');

export const loadTeamsList = () => dispatch => {
    dispatch(getTeamsListRequest());
    return Teams.list()
        .then(data => {
            dispatch(getTeamsListSuccess(data));
        })
        .catch(() => {
            dispatch(getTeamsListFailure());
        });
};


export const setNewTeamData = createAction('setNewTeamData');
export const requestSaveNewTeamSuccess = createAction('requestSaveNewTeamSuccess');
export const saveNewTeamData = (TeamData) => dispatch => {
    return Teams.create(TeamData)
        .then(data => {
            console.log('teams.create - requestSaveNewTeamSuccess')
            dispatch(requestSaveNewTeamSuccess(data));
        })
        .catch(() => {
            // todo handle error
        });
};

export const requestFetchTeamProfileSuccess = createAction('requestFetchTeamProfileSuccess');
export const fetchTeamProfile = (teamId) => dispatch => {
    return Teams.get(teamId)
        .then(data => {
            dispatch(requestFetchTeamProfileSuccess(data));
        })
        .catch(() => {
            // todo handle error
        });
};

export const setUpdatedTeamData = createAction('setUpdatedTeamData');
export const requestSaveUpdatedTeamDataSuccess = createAction('requestSaveUpdatedTeamDataSuccess');
export const saveUpdatedTeamData = (TeamId, TeamData) => dispatch => {
    return Teams.update(TeamId, TeamData)
        .then(data => {
            dispatch(requestSaveUpdatedTeamDataSuccess(data));
        })
        .catch(() => {
            // todo handle error
        });
};
