var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    name: null,
    email: null,
    id: null,
    authorized: false,
    instanceLogoUrl: null,
    isAdmin: false
};
var currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: initialState,
    reducers: {
        logout: function (state) {
            return __assign(__assign({}, state), {
                authorized: false,
                name: null,
                id: null,
                email: null,
                instanceLogoUrl: null
            });
        },
        setData: function (state, action) {
            return __assign(__assign({}, state), {
                authorized: true,
                name: action.payload.name,
                email: action.payload.email,
                id: action.payload.id,
                instanceLogoUrl: action.payload.instanceLogoUrl,
                isAdmin: action.payload.isAdmin
            });
        }
    }
});
export var logout = (_a = currentUserSlice.actions, _a.logout), setData = _a.setData;
export default currentUserSlice.reducer;
