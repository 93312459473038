import React from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {saveNewProjectData, setNewProjectData} from "../actions/main";
import {Navigate} from "react-router-dom";
import {projectProfileUrl} from "../UrlFactory";

const ProjectCreatePage = (props) => {

    if (props.projectCreateState.justCreated) {
        const newProjectProfileUrl = projectProfileUrl(props.projectCreateState.newProjectId).getPublicUrl()
        return <Navigate to={newProjectProfileUrl}/>
    }

    const project = props.projectCreateState.newProject

    const submitNewProjectData = () => {
        let requestData = {
            name: project.name,
            description: project.description,
        }
        console.log('submitNewProjectData.requestData', requestData)
        props.saveNewProjectData(requestData)
    }

    return (<div className={"d-flex flex-column-fluid"}>
        <div className={"container"}>
            <div className={"card card-custom"}>

                <div className={"card-body"}>
                    <button onClick={submitNewProjectData} className={"btn"}>
                        Создать проект
                    </button>

                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Название проекта</label>
                        <div className="col-9">
                            <input type={"text"} className={"form-control"}
                                   onChange={element => {
                                       props.setNewProjectData({field: 'name', value: element.target.value})
                                   }}
                                   value={project.name}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-3 text-lg-right text-left">Описание </label>
                        <div className="col-9">

              <textarea className={"form-control"}
                        onChange={element => {
                            props.setNewProjectData({field: 'description', value: element.target.value})
                        }}>{project.description}</textarea>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>)
};

const mapStateToProps = function (state) {
    return {
        projectCreateState: state.projectCreate,
    }
}

ProjectCreatePage.propTypes = {
    setNewProjectData: PropTypes.func,
    saveNewProjectData: PropTypes.func,
    projectCreateState: PropTypes.object
}

export default connect(mapStateToProps, {
    setNewProjectData,
    saveNewProjectData
})(ProjectCreatePage);
