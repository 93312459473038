import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {
    addNewAnswer,
    getById,
    getAvailableBotMenu,
    removeAnswer,
    updateAnswerInTextIntent,
    updateIntentData,
    updateIntentQuery,
    deleteIntentQuery,
    createIntentQuery, updateIntentContact, updateIntentField, updateIntentSetting
} from "../../../actions/intent";
import {Checkbox, Icon, MenuItem, Select} from "@material-ui/core";
import {Nav, Tab} from "react-bootstrap";
import {
    intentActionChangeType,
    intentActionCreate,
    intentActionDelete,
    intentActionUpdateField
} from "../actions/main";
import PropTypes from "prop-types";
import {
    INTENT_TYPE_ASK,
    INTENT_TYPE_EMAIL, INTENT_TYPE_EXECUTE_URL,
    INTENT_TYPE_SEND_CONTACT, INTENT_TYPE_SEND_LOCATION,
    INTENT_TYPE_TEXT
} from "../reducers/intentReducer";
import SendContactAction from "./SendConctactAction";
import AskIntentAction from "./AskIntentAction";
import EmailIntentAction from "./EmailIntentAction";
import TransitionAction from "./TransitionAction";
import SendLocationAction from "./SendLocationAction";
import ExecuteUrlAction from "./ExecuteUrlAction";
import TextIntentAction from "./TextIntentAction";

const IntentPage = (props) => {

    let intentId = props.match.params.id;

    useEffect(() => {
        props.getById(intentId)
        props.getAvailableBotMenu()
    }, []);

    const handleSubmit = () => {

        const intentData = {
            name: props.intentState.name,
            botCommand: props.intentState.botCommand,
            botMenuId: props.intentState.botMenuId,
            description: props.intentState.description,
            isActive: props.intentState.isActive,
            queries: props.intentState.queries,
            actions: props.intentState.actions,
            settingsValue: props.intentState.settingsValue,
        };

        console.log('intentData', intentData)
        props.updateIntentData(intentId, intentData)
        return false;
    }

    const initQueryField = (query) => {
        return (
            <div className="form-group input-group" key={"query_" + query.id}>
                <input type="text" className="form-control"
                       onChange={element => {
                           props.updateIntentQuery({id: query.id, sentence: element.target.value})
                       }}
                       value={query.sentence}
                       placeholder="Введите пример запроса от пользователя"/>
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary"
                            onClick={() => {
                                props.deleteIntentQuery(query.id)
                            }}
                            type="button">Удалить
                    </button>
                </div>
            </div>
        )
    }
    const initActionField = (action) => {

        const botMenuItems = props.intentState.availableBotMenu.map(menu => {
            return (<MenuItem key={'menu_' + menu.id} value={menu.id}>
                {menu.name}
            </MenuItem>)
        });

        const botMenuSelector = <Select
            value={action.botMenuId}
            onChange={element => {
                props.intentActionUpdateField({id: action.id, field: 'botMenuId', value: element.target.value})
            }}>
            <MenuItem value={""}>-</MenuItem>
            {botMenuItems}
        </Select>

        return (
            <div key={"action_" + action.id} style={{marginBottom: 10, borderTop: '1px #eee solid'}}>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Тип действия: </label>
                    <Select value={action.type}
                            onChange={element => {
                                props.intentActionChangeType({id: action.id, type: element.target.value})
                            }}>
                        <MenuItem key={INTENT_TYPE_ASK} value={INTENT_TYPE_ASK}>ask</MenuItem>
                        <MenuItem key={INTENT_TYPE_EMAIL} value={INTENT_TYPE_EMAIL}>email</MenuItem>
                        <MenuItem key={INTENT_TYPE_TEXT} value={INTENT_TYPE_TEXT}>text</MenuItem>
                        <MenuItem key={INTENT_TYPE_SEND_CONTACT} value={INTENT_TYPE_SEND_CONTACT}>contact</MenuItem>
                        <MenuItem key={INTENT_TYPE_SEND_LOCATION} value={INTENT_TYPE_SEND_LOCATION}>location</MenuItem>
                        <MenuItem key={INTENT_TYPE_EXECUTE_URL} value={INTENT_TYPE_EXECUTE_URL}>EXECUTE_URL</MenuItem>
                    </Select>
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Описание действия:</label>
                    <div>
                        <input type="text" className="form-control"
                               onChange={element => {
                                   props.intentActionUpdateField({
                                       id: action.id,
                                       field: 'description',
                                       value: element.target.value
                                   })
                               }}
                               value={action.description}
                               placeholder="Описание действия"/>
                    </div>
                </div>
                <div className="form-group">
                    <label>Вес (порядок выполнения):</label>
                    <div>
                        <input type="text" className="form-control"
                               onChange={element => {
                                   props.intentActionUpdateField({
                                       id: action.id,
                                       field: 'weight',
                                       value: element.target.value
                                   })
                               }}
                               value={action.weight}
                               placeholder="Вес"/>
                    </div>
                </div>
                <div className="form-group">
                    <label>Требует ручного прохождения:</label>
                    <Checkbox
                        onClick={element => {
                            props.intentActionUpdateField({
                                id: action.id,
                                field: 'manualTransition',
                                value: element.target.checked
                            })
                        }}
                        checked={action.manualTransition}
                    />
                </div>

                <div className="form-group">
                    <label>Inline menu preset:</label>
                    {botMenuSelector}
                </div>

                <div className={"intent-settings"}>
                    {
                        action.type === INTENT_TYPE_TEXT && <TextIntentAction action={action}/>
                    }
                    {
                        action.type === INTENT_TYPE_ASK && <AskIntentAction action={action}/>
                    }
                    {
                        action.type === INTENT_TYPE_SEND_CONTACT && <SendContactAction action={action}/>
                    }
                    {
                        action.type === INTENT_TYPE_EMAIL && <EmailIntentAction action={action}/>
                    }
                    {
                        action.type === INTENT_TYPE_SEND_LOCATION && <SendLocationAction action={action}/>
                    }
                    {
                        action.type === INTENT_TYPE_EXECUTE_URL && <ExecuteUrlAction action={action}/>
                    }

                    <div className={"intent-transitions"} style={{
                        marginTop: 30,
                        marginLeft: 15,
                        backgroundColor: '#f9f9f9',
                        padding: 10
                    }}>
                        <TransitionAction action={action}/>
                    </div>

                    <div style={{marginTop: 20}}>
                        <button className="btn btn-sm btn-outline-secondary"
                                onClick={() => {
                                    props.intentActionDelete(action.id)
                                }}
                                type="button">Удалить это действие
                        </button>
                    </div>
                </div>

            </div>
        )
    }

    const queries = []
    Object.values(props.intentState.queries).forEach(item => {
        queries.push(initQueryField(item))
    })

    const actions = []
    Object.values(props.intentState.actions).forEach(item => {
        actions.push(initActionField(item))
    })

    if (props.intentState.contactInfo) {
        console.log('props.intentState.contactInfo.firstname', props.intentState.contactInfo.firstname)
    }

    const renderSettingsField = (settingKey) => {
        const settingItemMeta = props.intentState.settingsMeta[settingKey]
        const settingItemValue = props.intentState.settingsValue[settingKey]

        let element;

        if (settingItemMeta.formatType == "richtext") {
            element = (
                <div>
                    <label>{settingItemMeta.title}</label>
                    <textarea onChange={element => {
                        props.updateIntentSetting({name: settingKey, value: element.target.value})
                    }}
                              value={settingItemValue}
                              className="form-control"/>
                    <div className={"hint"}>{settingItemMeta.description}</div>
                </div>)
        } else {
            element = (<div>unknown formatType in settings field {settingKey}</div>)
        }
        return element
    }

    const renderedSettings = []
    if (props.intentState.settingsMeta) {
        Object.keys(props.intentState.settingsMeta).forEach(settingKey => {
            renderedSettings.push(renderSettingsField(settingKey))
        })
    }

    return (
        <div className={"main-content-container intent-page"}>
            {!props.intentState.isLoaded ? '<div>загружается...</div>' : ''}

            <Tab.Container defaultActiveKey="general">

                <div className={"card"}>
                    <div className={"intent-header"} style={{columns: 2}}>
                        <Nav>
                            <Nav.Item>
                                <Nav.Link eventKey="general">
                                    Общее
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="queries">
                                    Запросы
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="actions">
                                    Действия
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div style={{textAlign: 'right'}}>
                            <button className={"btn btn-success btn-sm"} onClick={handleSubmit}>Сохранить изменения
                            </button>
                        </div>
                    </div>
                    <div className={"card-body"}>
                        <Tab.Content>
                            <Tab.Pane eventKey="general">

                                <div className="form-group">
                                    <label htmlFor="intentNameField">Название<span
                                        className="text-danger">*</span></label>
                                    <input type="text"
                                           id="intentNameField"
                                           className="form-control"
                                           onChange={element => {
                                               props.updateIntentField({field: 'name', value: element.target.value})
                                           }}
                                           value={props.intentState.name}
                                           placeholder="Укажите название интента"/>
                                    <span className="form-text text-muted">Это название отобржается только для администраторов и недоступно пользователям чат-бота</span>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="intentNameField">BotCommand</label>
                                    <input type="text"
                                           id="intentNameField"
                                           className="form-control"
                                           onChange={element => {
                                               props.updateIntentField({
                                                   field: 'botCommand',
                                                   value: element.target.value
                                               })
                                           }}
                                           value={props.intentState.botCommand}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="intentDescriptionField">Описание
                                        <span className="text-danger">*</span></label>
                                    <textarea id="intentDescriptionField"
                                              onChange={element => {
                                                  props.updateIntentField({
                                                      field: 'description',
                                                      value: element.target.value
                                                  })
                                              }}
                                              value={props.intentState.description}
                                              className="form-control"/>
                                    <span className="form-text text-muted">Если требуется - укажите небольшое описание этого интента. Описание недоступно пользователям чат-бота.</span>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="intentDescriptionField">Активность</label>
                                    <Checkbox
                                        onClick={element => {
                                            props.updateIntentField({field: 'isActive', value: element.target.checked})
                                        }}
                                        checked={props.intentState.isActive}
                                    />
                                </div>

                                <h3>Дополнительные настройки</h3>
                                {renderedSettings}

                            </Tab.Pane>

                            <Tab.Pane eventKey="queries">
                                <div className={"card-body"}>

                                    <div className="form-group mb-8">
                                        <div className="alert alert-custom alert-default" role="alert">
                                            <div className="alert-icon">
                                                <Icon className={"fa fa-fire"}></Icon>
                                            </div>
                                            <div className="alert-text">Укажите примерные фразы и словосочетания, какие
                                                пользователи
                                                могут вводить чтобы активировать этот интент. Для корректной работы
                                                рекомендуем указать не менее 4-х примеров.
                                            </div>
                                        </div>
                                    </div>

                                    {queries}
                                    <div>
                                        <button className={"btn btn-primary"} onClick={() => {
                                            props.createIntentQuery()
                                        }}>Добавить запрос
                                        </button>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="actions">
                                <div className={"card-body"}>
                                    {actions}
                                    <hr/>
                                    <div>
                                        <button className={"btn btn-primary"} onClick={() => {
                                            props.intentActionCreate()
                                        }}>Добавить действие
                                        </button>
                                    </div>

                                </div>
                            </Tab.Pane>

                        </Tab.Content>

                    </div>
                </div>

            </Tab.Container>
        </div>
    );
};

IntentPage.propTypes = {
    match: PropTypes.object,
    intentState: PropTypes.object,
    getById: PropTypes.func,
    updateIntentField: PropTypes.func,
    createIntentQuery: PropTypes.func,
    intentActionCreate: PropTypes.func,
    intentActionUpdateField: PropTypes.func,
    intentActionDelete: PropTypes.func,
    intentActionChangeType: PropTypes.func,
}

const mapStateToProps = function (state) {
    return {
        intentState: state.intentProfile,
    }
}

export default connect(mapStateToProps, {
    getById,
    getAvailableBotMenu,
    updateIntentData,
    updateAnswerInTextIntent,
    addNewAnswer, removeAnswer,
    updateIntentQuery,
    createIntentQuery,
    deleteIntentQuery,
    updateIntentContact,
    intentActionCreate,
    intentActionUpdateField,
    intentActionDelete,
    updateIntentField,
    intentActionChangeType,
    updateIntentSetting
})(IntentPage);
