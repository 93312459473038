var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import { generateRandomStringID } from "../../../utils/strings";
import { connect } from "react-redux";
import { setEditableContentAction, updateContentMenuTitleAction } from "../actions/main";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MenuEditableItem from "./MenuEditableItem";
import { saveOrder } from "../api/content";
import { create, reorder } from "../reducers/list";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuItem from "./MenuItem";
var ContentMenuListComponent = function (props) {
    var contentList = Object.values(props.boordListState.items);
    contentList.sort(function (a, b) {
        if (a.orderPosition < b.orderPosition)
            return -1;
        if (a.orderPosition > b.orderPosition)
            return 1;
        return 0;
    });
    var renderEditableMenuItem = function (contentItem, index, childLevel) {
        if (childLevel === void 0) { childLevel = 0; }
        var moveCard = function (contentItem, dragIndex, hoverIndex) {
            var reorderListData = {
                dragContent: contentItem,
                dragIndex: dragIndex,
                hoverIndex: hoverIndex
            };
            console.log('reorder');
            props.reorder(reorderListData);
        };
        var result = [React.createElement(MenuEditableItem, { childLevel: childLevel, key: contentItem.id, onDrop: function (contentItem, dragIndex) {
                    console.log('ContentMenuListComponent.onDrop');
                    var orderNum = 0;
                    var contentOrderedList = [];
                    if (contentItem.parentId) {
                        var parentContent = props.boordListState.items[contentItem.parentId];
                        contentOrderedList = parentContent.children;
                    }
                    else {
                        contentOrderedList = Object.values(props.boordListState.items);
                        contentOrderedList = contentOrderedList.filter(function (item) { return item.parentId == null; });
                        contentOrderedList = contentOrderedList.sort(function (a, b) {
                            if (a.orderPosition < b.orderPosition)
                                return -1;
                            if (a.orderPosition > b.orderPosition)
                                return 1;
                            return 0;
                        });
                    }
                    var orderResult = contentOrderedList.map(function (item) {
                        orderNum++;
                        return {
                            'title': item.menu.title,
                            'content_id': item.id,
                            'order_num': orderNum
                        };
                    });
                    props.saveOrder(orderResult);
                }, index: index, moveCard: moveCard, contentItem: contentItem })];
        // let childItems = props.boordListState.child[contentItem.id]
        var childItems = contentItem.children;
        if (childItems === undefined) {
            childItems = [];
        }
        if (childItems.length) {
            childLevel += 1;
            var childIndex_1 = -1;
            var childResult = childItems.map(function (childItem) {
                childIndex_1++;
                return renderEditableMenuItem(childItem, childIndex_1, childLevel);
            });
            var childResult2 = React.createElement("div", { key: "dnd-child-" + contentItem.id, className: "child" },
                React.createElement(DndProvider, { backend: HTML5Backend }, childResult));
            result = __spreadArray(__spreadArray([], result, true), [childResult2], false);
        }
        return result;
    };
    var renderMenuItem = function (contentItem, childLevel) {
        if (childLevel === void 0) { childLevel = 0; }
        var result = [React.createElement(MenuItem, { childLevel: childLevel, key: contentItem.id, contentItem: contentItem })];
        childLevel += 1;
        contentItem.children.map(function (childItem) {
            var childResult = renderMenuItem(childItem, childLevel);
            result = __spreadArray(__spreadArray([], result, true), childResult, true);
        });
        return result;
    };
    var addContent = function () {
        var newId = generateRandomStringID();
        var menuItem = { title: 'newItem' };
        var nextStep = {
            buttonTitle: '',
            conditions: []
        };
        var someText = {
            type: 'paragraph',
            children: [
                {
                    text: 'Content of new materials',
                },
            ],
        };
        var newContent = {
            id: newId,
            menu: menuItem,
            orderPosition: 0,
            parentId: null,
            children: [],
            isNew: true,
            isPassed: false,
            passedDate: null,
            nextStep: nextStep,
            content: [someText]
        };
        props.create(newContent);
        props.setEditableContent(newContent);
    };
    var menuItems;
    var rootItems = contentList.filter(function (i) { return i.parentId == null; });
    if (props.currentUserState.authorized && props.currentUserState.isAdmin) {
        menuItems = React.createElement(DndProvider, { backend: HTML5Backend }, rootItems.map(function (contentItem, i) {
            return renderEditableMenuItem(contentItem, i);
        }));
    }
    else {
        menuItems = React.createElement("div", null, rootItems.map(function (contentItem) {
            return renderMenuItem(contentItem);
        }));
    }
    return React.createElement("div", { className: "content-menu" },
        React.createElement("div", { className: "items" }, menuItems),
        props.currentUserState.authorized && props.currentUserState.isAdmin &&
            React.createElement("div", { className: "new-content-container" },
                React.createElement("a", { onClick: addContent },
                    React.createElement(AddCircleIcon, null),
                    " \u0421\u043E\u0437\u0434\u0430\u0442\u044C \u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B")));
};
var mapStateToProps = function (state) {
    return {
        boordListState: state.boordList,
        currentUserState: state.currentUser,
    };
};
export default connect(mapStateToProps, {
    create: create,
    setEditableContent: setEditableContentAction,
    updateContentMenuTitleAction: updateContentMenuTitleAction,
    reorder: reorder,
    saveOrder: saveOrder
})(ContentMenuListComponent);
