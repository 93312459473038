import {createAction} from "redux-actions";
import BotApi from "../api/main";

export const requestBotVersionListSuccess = createAction('requestBotVersionListSuccess');


export const loadBotVersions = (botId) => dispatch => {
    // todo pre loading handler
    return BotApi.loadVersions(botId)
        .then(data => {
            dispatch(requestBotVersionListSuccess(data));
        })
        .catch(err => {
            console.log(err);
            // todo error handler
        });
};

export const requestBotVersionCreateSuccess = createAction('requestBotVersionCreateSuccess');

export const createBotVersion = (botId) => dispatch => {
    // todo pre loading handler
    return BotApi.createVersion({bot_id: botId})
        .then(data => {
            dispatch(requestBotVersionCreateSuccess(data));
        })
        .catch(err => {
            console.log(err);
            // todo error handler
        });
};
