import React, {useEffect} from "react";
import {connect} from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {loadMembersList} from "../modules/members/actions/membersList";

const MemberSelector = (props) => {

    useEffect(() => {
        props.loadMembersList()
    }, [])

    const items = props.memberListState.members.map(member => {
        return {
            value: member.id,
            label: member.displayName
        }
        // return <MenuItem key={'member_' + member.id} value={member.id}>{member.displayName}</MenuItem>
    });

    const isMulti = props.isMulti === true

    return (<div>
        <Select value={props.selectedMemberId}
                options={items}
                isMulti={isMulti}
                onChange={(item) => {
                    console.log('item', item)
                    if (isMulti) {
                        const values = item.map(item => {
                            return item.value
                        })
                        props.onSelect({values: values})
                    } else {
                        props.onSelect({id: item.value})
                    }
                }}/>
    </div>)
};

const mapStateToProps = function (state) {
    return {
        memberListState: state.memberList,
    }
}

MemberSelector.propTypes = {
    selectedMemberId: PropTypes.number,
    onSelect: PropTypes.func,
    loadMembersList: PropTypes.func,
    memberListState: PropTypes.object,
}

export default connect(mapStateToProps, {
    loadMembersList,
})(MemberSelector);
