import {createAction} from "redux-actions";
import BotApi from "../api/main";

export const requestBotInfoSuccess = createAction('requestBotInfoSuccess');


export const loadBotInfo = () => dispatch => {
    // todo pre loading handler
    return BotApi.getBotInfo()
        .then(data => {
            dispatch(requestBotInfoSuccess(data));
        })
        .catch(err => {
            console.log(err);
            // todo error handler
        });
};

export const setBotInfoFieldValue = createAction('setBotInfoFieldValue');
export const requestBotInfoUpdateSuccess = createAction('requestBotInfoUpdateSuccess');

export const updateBotInfo = (botData) => dispatch => {
    // todo pre loading handler
    return BotApi.updateBotInfo(botData)
        .then(data => {
            dispatch(requestBotInfoUpdateSuccess(data));
        })
        .catch(err => {
            console.log(err);
            // todo error handler
        });
};
