import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {sendCode, verifyCode} from "../actions/auth";
import {Button, Form} from "react-bootstrap";
import '../styles/Auth.scss'
import {getAuthLink} from "../api/google";
import GoogleIcon from '@mui/icons-material/Google';

const AuthPage = (props) => {

    const [email, setEmail] = useState();
    const [pin, setPin] = useState();

    useEffect(() => {

    }, []);

    console.log('props.authState', props.authState)

    const handleSubmit = () => {
        props.sendCode(email)
        return false;
    }
    const handleSubmitPin = () => {
        props.verifyCode(props.authState.sessionId, pin)
        return false;
    }

    const handleGoogleAuth = () => {
        getAuthLink(successData => {
            window.location = successData.authLink
        }, () => {
            alert('error :/')
        })
        return false
    }

    const handleCodeChange = (value) => {
        setPin(value.target.value)
    }
    const handleEmailChange = (value) => {
        setEmail(value.target.value)
    }

    const renderPinInput = () => {
        return <>
            <Form
                onSubmit={handleSubmitPin}
                className={"form-login"}>
                <p>
                    На почтовый адрес {email} отправлен 5 значный код для
                    входа в аккаунт - введите этот код в это поле.
                </p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Пин-код</Form.Label>
                    <Form.Control type="number"
                                  onChange={handleCodeChange}
                                  value={pin} placeholder="Введите код для входа"/>
                    <Form.Text className="text-muted">
                        Введите код для входа.
                    </Form.Text>

                    <div style={{marginTop: '10px'}}>
                        {props.authState.hasFailure &&
                        <div className="text-danger">
                            Что-то пошло не так:(<br/>
                            Проверьте правильность ввода кода и попробуйте снова.
                        </div>
                        }
                    </div>

                </Form.Group>
                <div className={"submit-button"}>
                    <Button variant="primary" onClick={handleSubmitPin}>
                        Войти
                    </Button>
                </div>
            </Form>
        </>
    }

    const renderLoginInput = () => {
        return <div className={"form-login-container"}>
            <h3>Привет, друг!</h3>
            <p style={{fontSize: '16pt'}}>
                Чтобы попасть на борт корабля - авторизуйся с помощью корпоративного Google аккаунта.
            </p>
            <div style={{marginTop: 25}}>
                <button className={"btn-auth"} style={{padding: 10}} onClick={handleGoogleAuth}>
                    <GoogleIcon/> Авторизоваться через Google
                </button>
            </div>
            {/*

            <Form
                onSubmit={handleSubmit}
                className={"form-login"}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email"
                                  onChange={handleEmailChange}
                                  value={email} placeholder="Enter email"/>
                    <div style={{marginTop: '10px'}}>
                        {props.authState.hasFailure &&
                        <div className="text-danger">
                            Что-то пошло не так:(<br/>
                            Проверьте правильность ввода email и попробуйте снова.
                        </div>
                        }
                        <div className="text-muted">
                            We'll never share your email with anyone else.
                        </div>
                    </div>
                </Form.Group>
                <div className={"submit-button"}>
                    <Button variant="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </Form>
            */}
        </div>
    }

    let renderedForm;
    if (props.authState.waitPin) {
        renderedForm = renderPinInput()
    } else {
        renderedForm = renderLoginInput()
    }

    return (
        <>
            {renderedForm}
        </>
    );
};

const mapStateToProps = function (state) {
    return {
        authState: state.auth,
    }
}

export default connect(mapStateToProps, {sendCode, verifyCode})(AuthPage);
