import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import {loadMembersList} from "../actions/membersList";
import Table from "react-bootstrap/Table";
import '../styles/main.scss'
import {formatPhone} from "../../../utils/strings";
import {TableSpinner} from "../../../components/CustomSpinner";

const MembersListPage = (props) => {

    useEffect(() => {
        props.loadMembersList()
    }, [])

    const renderMemberItem = (member) => {
        const profileUrl = '/panel/members/' + member.id
        let memberPhone = ''
        if (member.mainPhone) {
            memberPhone = formatPhone(member.mainPhone)
        }
        return (<tr key={"member_" + member.id}>
            <td className={"avatar"}>
                <img alt={""} src={member.avatarUrl}/>
            </td>
            <td>
                <Link to={profileUrl}>{member.displayName}</Link>
                <div className={"job-position"}>
                    {member.jobPosition}
                </div>
            </td>
            <td>
                {memberPhone}
                <div>{member.mainEmail}</div>
            </td>
        </tr>)
    }

    const members = props.memberListState.members.map(member => {
        return renderMemberItem(member)
    });

    let navigate = useNavigate();

    return (<div className={"page-container"}>

        <div className={"header"}>

            <div className={"breadcrumbs"}>
                <ul>
                    <li>Список сотрудников</li>
                </ul>
            </div>

            <div className="info">
                <div className="caption">
                    <div className={"title"}>Команда</div>
                    <div className={"description"}>
                        В этом разделе выводится список ваших сотрудников.
                        Здесь можно добавить или изменить данные кого-то из коллег.
                    </div>
                </div>
                <div className="right-controls">
                    <button className={"btn-add-data"} onClick={() => {
                        navigate("/panel/members/create", {replace: true});
                    }}>Добавить сотрудника
                    </button>
                </div>
            </div>


        </div>

        <div className={"content members-list"}>
            {props.memberListState.isLoading && <TableSpinner/>}
            {!props.memberListState.isLoading &&
            <Table hover>
                <thead>
                <tr>
                    <th></th>
                    <th>Имя</th>
                    <th>Контакты</th>
                </tr>
                </thead>
                <tbody>
                {members}
                </tbody>
            </Table>
            }
        </div>
    </div>)
};

const mapStateToProps = function (state) {
    return {
        memberListState: state.memberList,
    }
}

export default connect(mapStateToProps, {
    loadMembersList
})(MembersListPage);
