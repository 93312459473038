import React, {useState} from "react";
import {connect} from "react-redux";
import {
    addMemberContact,
    deleteMemberContact,
    updateMemberContact,
    updateMemberContactType,
} from "../actions/memberProfile";
import {CONTACT_TYPE_INSTAGRAM, CONTACT_TYPE_PHONE} from "../reducers/memberProfileReducer";
import {MenuItem, Select} from "@material-ui/core";
import InputMask from "react-input-mask";
import {Button, FormGroup, FormLabel, FormText, InputGroup} from "react-bootstrap";
import {PhotosTab} from "./PhotosTab";


function ContactTab(props) {

    const availableContactTypes = [
        {name: 'Телефон', value: CONTACT_TYPE_PHONE},
        {name: 'Instagram', value: 'instagram'},
        {name: 'ВКонтакте', value: 'vkontakte'},
        {name: 'Email', value: 'email'},
        {name: 'Telegram', value: 'telegram'},
        {name: 'Веб-сайт', value: 'website'}
    ]
    const [newContactType, setNewContactType] = useState(availableContactTypes[0].value)

    const newContactSelectOptions = availableContactTypes.map(item => {
        return <MenuItem value={item.value}>{item.name}</MenuItem>
    })

    const userProfile = props.memberProfileState.profile

    const renderedContacts = []
    if (props.memberProfileState.isLoaded && userProfile.contacts) {

        Object.values(userProfile.contacts).forEach(contact => {
            let contactInput
            let foundContactType = availableContactTypes.filter(item => item.value === contact.type)
            if (!foundContactType) {
                return
            }
            let contactTypeLabel = foundContactType[0].name
            if (contact.type === CONTACT_TYPE_INSTAGRAM) {
                contactInput = <div>
                    <input type={"text"} className={"form-control"}
                           onChange={element => {
                               props.updateMemberContact({id: contact.id, value: element.target.value})
                           }}
                           value={contact.value}/>
                    <FormText className="text-muted">
                        Укажите название аккаунта в Instagram.
                    </FormText>
                </div>
            } else if (contact.type === CONTACT_TYPE_PHONE) {
                contactInput = <InputMask mask="+7\(999) 999-99-99"
                                          onChange={element => {
                                              props.updateMemberContact({id: contact.id, value: element.target.value})
                                          }}
                                          value={contact.value}
                                          className={"form-control"} maskChar=" "/>
            } else {
                contactInput = <input type={"text"} className={"form-control"}
                                      onChange={element => {
                                          props.updateMemberContact({id: contact.id, value: element.target.value})
                                      }}
                                      value={contact.value}/>
            }

            renderedContacts.push(
                <FormGroup>
                    <FormLabel>{contactTypeLabel}</FormLabel>
                    <InputGroup className="mb-3">

                        {contactInput}
                        <Button className={"btn btn-sm btn-danger"}
                                onClick={() => {
                                    props.deleteMemberContact({id: contact.id})
                                }}>
                            удалить
                        </Button>
                    </InputGroup>
                </FormGroup>)
        })
    }

    return (
        <div>
            {renderedContacts}
            <div>
                <PhotosTab memberId={userProfile.id} />

                <hr/>

                <FormGroup className="mb-3">
                    <FormLabel><h3>Добавить новый контакт</h3></FormLabel>
                    <InputGroup className="mb-3">
                        <Select
                            value={newContactType}
                            onChange={(element) => {
                                setNewContactType(element.target.value)
                            }}
                        >
                            {newContactSelectOptions}
                        </Select>
                        <Button
                            className={"btn-add-data"} onClick={() => {
                            props.addMemberContact({contactType: newContactType})
                        }}>Добавить
                        </Button>
                    </InputGroup>
                </FormGroup>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        memberProfileState: state.memberProfile,
    }
}

export default connect(mapStateToProps,
    {
        updateMemberContact,
        updateMemberContactType,
        addMemberContact,
        deleteMemberContact,
    }
)(ContactTab);
