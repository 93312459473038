import { requestPOST } from "../../../utils/apiClient";
var getGoogleAuthCallbackUrl = function () {
    return window.location.origin + '/login/google';
};
export var getAuthLink = function (successCallback, failureCallback) {
    var requestData = {
        callbackUrl: getGoogleAuthCallbackUrl(),
    };
    requestPOST('/api/auth/google/link', requestData)
        .then(successCallback)
        .catch(function (error) {
        console.log('getAuthLink.error', error);
        failureCallback();
    });
};
export var checkAuth = function (code, state, successCallback, failureCallback) {
    var requestData = {
        code: code,
        state: state,
        callbackUrl: getGoogleAuthCallbackUrl(),
    };
    requestPOST('/api/auth/google/check', requestData)
        .then(successCallback)
        .catch(function (error) {
        error.json().then(function (errorData) {
            failureCallback(errorData);
        });
    });
};
