var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFocused, useSelected } from "slate-react";
import React from "react";
import { Transforms } from "slate";
export var MentionEditorElement = function (props) {
    var element = props.element;
    console.log('MentionEditorElement.element', element);
    var attributes = props.attributes;
    var children = props.children;
    var selected = useSelected();
    var focused = useFocused();
    return (React.createElement("span", __assign({}, attributes, { contentEditable: false, "data-cy": "mention-".concat(String(element.mentionId).replace(' ', '-')), style: {
            padding: '3px 3px 2px',
            margin: '0 1px',
            verticalAlign: 'baseline',
            borderRadius: '4px',
            backgroundColor: 'green',
            fontSize: '0.9em',
            boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        } }),
        "@",
        element.mentionTitle,
        children));
};
// MentionableItem
export var insertMention = function (editor, item) {
    console.log('insertMention.character', item);
    var mention = {
        type: 'mention',
        mentionId: item.id,
        mentionTitle: item.title,
        children: [{ text: '' }],
    };
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
};
export var withMentions = function (editor) {
    var isInline = editor.isInline, isVoid = editor.isVoid;
    editor.isInline = function (element) {
        return element.type === 'mention' ? true : isInline(element);
    };
    editor.isVoid = function (element) {
        return element.type === 'mention' ? true : isVoid(element);
    };
    return editor;
};
