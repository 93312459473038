import {createAction} from "redux-actions";
import BotApi from "../api/main";

export const botCommandMenuUpdateField = createAction('botCommandMenuUpdateField');
export const botCommandMenuUpdateItemField = createAction('botCommandMenuUpdateItemField');
export const botCommandMenuCreateItem = createAction('botCommandMenuCreateItem');
export const botCommandMenuRemoveItem = createAction('botCommandMenuRemoveItem');
export const requestBotCommandGroupsListSuccess = createAction('requestBotCommandGroupsListSuccess');


export const loadBotCommandsList = (botId) => dispatch => {
    // todo pre loading handler
    return BotApi.listBotCommandMenu(botId)
        .then(data => {
            dispatch(requestBotCommandGroupsListSuccess(data));
        })
        .catch(err => {
            console.log(err);
            // todo error handler
        });
};

export const requestBotCommandMenuUpdateSuccess = createAction('requestBotCommandMenuUpdateSuccess');

export const updateBotCommandGroup = (menuId, menuData) => dispatch => {
    // todo pre loading handler
    return BotApi.updateBotCommandMenu(menuId, menuData)
        .then(data => {
            dispatch(requestBotCommandMenuUpdateSuccess(data));
        })
        .catch(err => {
            console.log(err);
            // todo error handler
        });
};
