var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from 'react';
import { createEditor, Editor, Range, Transforms, Element } from 'slate';
import { withHistory } from 'slate-history';
import isUrl from 'is-url';
import { Editable, ReactEditor, Slate, useSelected, useSlate, withReact, } from 'slate-react';
import { TeamListEditorElement, withTeamList } from "./TeamListEditorElement";
import { insertMention, MentionEditorElement, withMentions } from "./MentionEditorElement";
import { Button } from "react-bootstrap";
import isHotkey from 'is-hotkey';
import { css } from "@emotion/react";
import { ImageEditorElement, InsertImageButton } from "./Images";
import { requestGET } from "../../utils/apiClient";
import { LocationEditorElement, withLocations } from "./LocationEditorElement";
import { ProjectsEditorElement } from "./ProjectsEditorElement";
var HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
};
var isMarkActive = function (editor, format) {
    var marks = Editor.marks(editor);
    // @ts-ignore
    return marks ? marks[format] === true : false;
};
var toggleMark = function (editor, format) {
    var isActive = isMarkActive(editor, format);
    if (isActive) {
        Editor.removeMark(editor, format);
    }
    else {
        Editor.addMark(editor, format, true);
    }
};
var CustomEditor = function (props) {
    var initialValue = props.initialValue;
    if (!initialValue || !Array.isArray(initialValue) || initialValue.length == 0) {
        initialValue = [{
                type: 'paragraph',
                children: [
                    {
                        text: '',
                    },
                ],
            }];
    }
    console.log('CustomEditor.initialValue', initialValue);
    var ref = React.useRef();
    // const [value, setValue] = useState<any[]>(initialValue)
    // const [editorId, setEditorId] = useState<string>(props.editorId)
    var _a = useState(), target = _a[0], setTarget = _a[1];
    var _b = useState(0), index = _b[0], setIndex = _b[1];
    var _c = useState(''), search = _c[0], setSearch = _c[1];
    var renderLeaf = useCallback(function (props) { return React.createElement(Leaf, __assign({}, props)); }, []);
    var renderElement = useCallback(function (props) { return React.createElement(CustomEditorElement, __assign({}, props)); }, []);
    var _d = useState([]), mentionableItems = _d[0], setMentionableItems = _d[1];
    var _e = useState(withReact(withHistory(withLocations(withTeamList(withMentions(withInlines(createEditor()))))))), editor = _e[0], setEditor = _e[1];
    var chars = mentionableItems.filter(function (mentionItem) {
        return mentionItem.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
    }).slice(0, 10);
    var onKeyDown = useCallback(function (event) {
        for (var hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
                event.preventDefault();
                // @ts-ignore
                var mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
            }
        }
        if (target) {
            switch (event.key) {
                case 'ArrowDown':
                    event.preventDefault();
                    var prevIndex = index >= chars.length - 1 ? 0 : index + 1;
                    setIndex(prevIndex);
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    var nextIndex = index <= 0 ? chars.length - 1 : index - 1;
                    setIndex(nextIndex);
                    break;
                case 'Tab':
                case 'Enter':
                    event.preventDefault();
                    Transforms.select(editor, target);
                    insertMention(editor, chars[index]);
                    setTarget(null);
                    break;
                case 'Escape':
                    event.preventDefault();
                    setTarget(null);
                    break;
            }
        }
    }, [index, search, target]);
    useEffect(function () {
        if (target && chars.length > 0) {
            var el = ref.current;
            var domRange = ReactEditor.toDOMRange(editor, target);
            var rect = domRange.getBoundingClientRect();
            // if (el) {
            el.style.top = "".concat(rect.top + window.pageYOffset + 24, "px");
            el.style.left = "".concat(rect.left + window.pageXOffset, "px");
            // }
        }
        requestGET('/api/boord/mentions')
            .then(function (items) {
            setMentionableItems(items.data);
        })
            .catch(function (error) {
            console.log(error);
            alert('failed to get mentions');
        });
    }, [chars.length, editor, index, search, target]);
    var insertTeamItem = function () {
        var teamElement = {
            type: 'team',
            children: [{ text: '' }],
        };
        var p = {
            type: 'p',
            children: [
                {
                    text: '',
                },
            ],
        };
        Transforms.insertNodes(editor, [teamElement]);
        Transforms.insertNodes(editor, p);
        Transforms.move(editor);
    };
    var insertLocationItem = function () {
        var locationElement = {
            type: 'location',
            location: '',
            children: [{ text: '' }],
        };
        var p = {
            type: 'p',
            children: [
                {
                    text: '',
                },
            ],
        };
        Transforms.insertNodes(editor, [locationElement]);
        Transforms.insertNodes(editor, p);
        Transforms.move(editor);
    };
    var insertProjectsItem = function () {
        var element = {
            type: 'projects',
            location: '',
            children: [{ text: '' }],
        };
        var p = {
            type: 'p',
            children: [
                {
                    text: '',
                },
            ],
        };
        Transforms.insertNodes(editor, [element]);
        Transforms.insertNodes(editor, p);
        Transforms.move(editor);
    };
    return (React.createElement("div", { style: { backgroundColor: '#fff', padding: 10 } },
        React.createElement(Slate, { editor: editor, value: initialValue, onChange: function (value) {
                props.onChange({ contentData: value });
                var selection = editor.selection;
                if (selection && Range.isCollapsed(selection)) {
                    var start = Range.edges(selection)[0];
                    var wordBefore = Editor.before(editor, start, { unit: 'word' });
                    var before = wordBefore && Editor.before(editor, wordBefore);
                    var beforeRange = before && Editor.range(editor, before, start);
                    var beforeText = beforeRange && Editor.string(editor, beforeRange);
                    var beforeMatch = beforeText && beforeText.match(/^@(.+)$/);
                    var after = Editor.after(editor, start);
                    var afterRange = Editor.range(editor, start, after);
                    var afterText = Editor.string(editor, afterRange);
                    var afterMatch = afterText.match(/^(\s|$)/);
                    console.log('wordBefore', wordBefore);
                    console.log('before', before);
                    console.log('beforeMatch', beforeMatch);
                    console.log('afterMatch', afterMatch);
                    if (beforeMatch && afterMatch) {
                        console.log('setSearch', beforeMatch[1]);
                        setTarget(beforeRange);
                        setSearch(beforeMatch[1]);
                        setIndex(0);
                        return;
                    }
                }
                setTarget(null);
            } },
            React.createElement(Button, { onClick: insertTeamItem, className: "btn btn-primary" }, "team"),
            React.createElement(Button, { onClick: insertLocationItem, className: "btn btn-primary" }, "location"),
            React.createElement(Button, { onClick: insertProjectsItem, className: "btn btn-primary" }, "projects"),
            React.createElement(AddLinkButton, null),
            React.createElement(RemoveLinkButton, null),
            React.createElement(InsertImageButton, null),
            React.createElement("div", { style: { borderRadius: 5, border: '1px #eee solid', padding: 10 } },
                React.createElement(Editable, { renderElement: renderElement, renderLeaf: renderLeaf, onKeyDown: onKeyDown, placeholder: "Enter some text..." })),
            target && chars.length > 0 && (React.createElement("div", { ref: ref, style: {
                    top: '-9999px',
                    left: '-9999px',
                    position: 'absolute',
                    zIndex: 1,
                    padding: '3px',
                    background: 'white',
                    borderRadius: '4px',
                    boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                }, "data-cy": "mentions-portal" }, chars.map(function (char, i) { return (React.createElement("div", { key: char.id, style: {
                    padding: '1px 3px',
                    borderRadius: '3px',
                    background: i === index ? '#B4D5FF' : 'transparent',
                } }, char.title)); }))))));
};
var CustomEditorElement = function (props) {
    var attributes = props.attributes, children = props.children, element = props.element;
    switch (element.type) {
        case 'link':
            return React.createElement(LinkComponent, __assign({}, props));
        case 'image':
            return React.createElement(ImageEditorElement, __assign({}, props));
        case 'team':
            return React.createElement(TeamListEditorElement, __assign({}, props));
        case 'location':
            return React.createElement(LocationEditorElement, __assign({}, props));
        case 'projects':
            return React.createElement(ProjectsEditorElement, __assign({}, props));
        case 'mention':
            return React.createElement(MentionEditorElement, __assign({}, props));
        case 'p':
        case 'paragraph':
            return React.createElement("p", __assign({}, attributes), children);
        default:
            console.error('cannot find editor render for type ' + element.type);
            alert('cannot find editor render for type ' + element.type);
    }
};
var Leaf = function (props) {
    var attributes = props.attributes, children = props.children, leaf = props.leaf;
    if (leaf.bold) {
        children = React.createElement("strong", null, children);
    }
    if (leaf.code) {
        children = React.createElement("code", null, children);
    }
    if (leaf.italic) {
        children = React.createElement("em", null, children);
    }
    if (leaf.underline) {
        children = React.createElement("u", null, children);
    }
    return React.createElement("span", __assign({}, attributes), children);
};
// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
var InlineChromiumBugfix = function () { return (React.createElement("span", { contentEditable: false, style: { fontSize: 0 } },
    "$",
    String.fromCodePoint(160) /* Non-breaking space */)); };
var withInlines = function (editor) {
    // @ts-ignore
    var insertData = editor.insertData, insertText = editor.insertText, isInline = editor.isInline;
    editor.isInline = function (element) {
        // @ts-ignore
        return ['link', 'button'].includes(element.type) || isInline(element);
    };
    editor.insertText = function (text) {
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        }
        else {
            insertText(text);
        }
    };
    // @ts-ignore
    editor.insertData = function (data) {
        var text = data.getData('text/plain');
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        }
        else {
            insertData(data);
        }
    };
    return editor;
};
var insertLink = function (editor, url) {
    if (editor.selection) {
        wrapLink(editor, url);
    }
};
var isLinkActive = function (editor) {
    // @ts-ignore
    var link = Editor.nodes(editor, {
        match: function (n) {
            // @ts-ignore
            return !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link';
        },
    })[0];
    return !!link;
};
var unwrapLink = function (editor) {
    Transforms.unwrapNodes(editor, {
        // @ts-ignore
        match: function (n) { return !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link'; }, // @ts-ignore
    });
};
var wrapLink = function (editor, url) {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }
    var selection = editor.selection;
    var isCollapsed = selection && Range.isCollapsed(selection);
    var link = {
        type: 'link',
        url: url,
        children: isCollapsed ? [{ text: url }] : [],
    };
    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    }
    else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};
var LinkComponent = function (props) {
    var selected = useSelected();
    var attributes = props.attributes, children = props.children, element = props.element;
    return (React.createElement("a", __assign({}, attributes, { href: element.url, className: selected
            ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n              box-shadow: 0 0 0 3px #ddd;\n            "], ["\n              box-shadow: 0 0 0 3px #ddd;\n            "]))) : '' }),
        React.createElement(InlineChromiumBugfix, null),
        children,
        React.createElement(InlineChromiumBugfix, null)));
};
var AddLinkButton = function () {
    var editor = useSlate();
    return (React.createElement(Button, { active: isLinkActive(editor), onMouseDown: function (event) {
            event.preventDefault();
            var url = window.prompt('Enter the URL of the link:');
            if (!url)
                return;
            insertLink(editor, url);
        } }, "link"));
};
var RemoveLinkButton = function () {
    var editor = useSlate();
    return (React.createElement(Button, { active: isLinkActive(editor), onMouseDown: function (event) {
            if (isLinkActive(editor)) {
                unwrapLink(editor);
            }
        } }, "unlink"));
};
export default CustomEditor;
var templateObject_1;
