var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import update from 'immutability-helper';
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    data: [],
    items: {},
    child: {},
    passedContents: {},
    activeContent: null,
    loadingInProgress: false,
    showMenuInContentArea: false,
};
var contentListSlice = createSlice({
    name: 'contentList',
    initialState: initialState,
    reducers: {
        markAsPassed: function (state, action) {
            var _a;
            return __assign(__assign({}, state), {
                passedContents: __assign(__assign({}, state.passedContents), (_a = {},
                    _a[action.payload.id] = true,
                    _a))
            });
        },
        toggleMenuInContent: function (state, action) {
            return __assign(__assign({}, state), {
                showMenuInContentArea: action.payload,
            });
        },
        startLoading: function (state) {
            return __assign(__assign({}, state), {
                loadingInProgress: true,
            });
        },
        updateActiveContentCondition: function (state, action) {
            var _a;
            var conditionIndex = state.activeContent.nextStep.conditions.findIndex(function (item) {
                return item.id == action.payload.conditionId;
            });
            var selectedCondition = state.activeContent.nextStep.conditions[conditionIndex];
            var newConditionValue = {};
            if (action.payload.selectedCheckbox != undefined) {
                console.log('selectedCheckbox');
                newConditionValue = __assign(__assign({}, newConditionValue), {
                    selectedCheckbox: action.payload.selectedCheckbox
                });
            }
            if (action.payload.selectedDropdownValue != undefined) {
                newConditionValue = __assign(__assign({}, newConditionValue), {
                    selectedDropdownValues: [action.payload.selectedDropdownValue]
                });
            }
            var newCondition = __assign(__assign({}, selectedCondition), newConditionValue);
            return update(state, {
                activeContent: {
                    nextStep: {
                        conditions: (_a = {},
                            _a[conditionIndex] = {
                                $set: newCondition
                            },
                            _a)
                    }
                }
            });
        },
        remove: function (state, action) {
            var contentId = action.payload;
            var newContentList = update(state, {
                data: function (data) { return data.filter(function (item) { return item.id != contentId; }); },
            });
            return __assign(__assign({}, state), newContentList);
        },
        setChildItems: function (state, action) {
            var _a;
            var childItems = action.payload.items.map(function (item) {
                var newItem = {
                    id: item.id,
                    parentId: item.parentId,
                    orderPosition: 0,
                    isNew: false,
                    isPassed: item.isPassed,
                    passedDate: null,
                    content: item.content,
                    children: [],
                    menu: {
                        title: item.menu.title,
                    },
                    nextStep: {
                        buttonTitle: item.nextStep.buttonTitle,
                        conditions: item.nextStep.conditions,
                    }
                };
                return newItem;
            });
            // return state
            return __assign(__assign({}, state), {
                child: __assign(__assign({}, state.child), (_a = {}, _a[action.payload.parentId] = childItems, _a)),
            });
        },
        setList: function (state, action) {
            var contents = {};
            var passedInfo = {};
            var children = {};
            action.payload.data.map(function (item) {
                var contentItem = {
                    id: item.id,
                    parentId: item.parentId,
                    orderPosition: item.orderPosition,
                    isNew: false,
                    isPassed: item.isPassed,
                    passedDate: null,
                    content: item.content,
                    children: [],
                    menu: {
                        title: item.menu.title,
                    },
                    nextStep: {
                        buttonTitle: item.nextStep.buttonTitle,
                        conditions: item.nextStep.conditions,
                    }
                };
                passedInfo[item.id] = item.isPassed;
                contents[contentItem.id] = contentItem;
                if (contentItem.parentId) {
                    if (children[contentItem.parentId] == undefined) {
                        children[contentItem.parentId] = [];
                    }
                    children[contentItem.parentId].push(contentItem);
                }
            });
            var _loop_1 = function (parentId) {
                var parent_1 = contents[parentId];
                children[parentId].map(function (child) {
                    parent_1.children.push(child);
                    contents[parentId] = parent_1;
                });
                parent_1.children.sort(function (a, b) {
                    if (a.orderPosition < b.orderPosition)
                        return -1;
                    if (a.orderPosition > b.orderPosition)
                        return 1;
                    return 0;
                });
            };
            for (var parentId in children) {
                _loop_1(parentId);
            }
            return __assign(__assign({}, state), {
                loadingInProgress: false,
                items: contents,
                passedContents: passedInfo
            });
        },
        setActive: function (state, action) {
            var activeContent = state.items[action.payload];
            return __assign(__assign({}, state), {
                activeContent: activeContent,
                showMenuInContentArea: false,
            });
        },
        create: function (state, action) {
            var _a;
            return __assign(__assign({}, state), {
                items: __assign(__assign({}, state.items), (_a = {}, _a[action.payload.id] = action.payload, _a))
            });
        },
        reorder: function (state, action) {
            var _a;
            var reorderListData = action.payload;
            var contentItem = __assign({}, action.payload.dragContent);
            console.log('reorder: contentItem.parentId', contentItem.parentId);
            console.log('reorder: action.payload.hoverIndex', action.payload.hoverIndex);
            // console.log('reorder: action.payload.dragIndex', action.payload.dragIndex)
            var orderContent = [];
            if (contentItem.parentId) {
                var parentItem = state.items[contentItem.parentId];
                orderContent = parentItem.children;
            }
            else {
                orderContent = Object.values(state.items);
                orderContent = orderContent.filter(function (item) { return item.parentId == null; });
                // orderContent = [...orderContent]
            }
            orderContent = JSON.parse(JSON.stringify(orderContent));
            // console.log('orderContent', orderContent)
            orderContent = orderContent.filter(function (i) { return i.id != contentItem.id; });
            // delete orderContent[draggedContentIndex]
            var first = orderContent.slice(0, reorderListData.hoverIndex);
            var second = orderContent.slice(reorderListData.hoverIndex);
            first.push(contentItem);
            var newItems = state.items;
            var index = 0;
            var result = __spreadArray(__spreadArray([], first, true), second, true);
            // result = result.slice(0, 4)
            // result.map(i => {
            //     console.log(i.menu.title)
            // })
            // return state
            // console.log('result', result)
            if (contentItem.parentId) {
                return __assign(__assign({}, state), { items: __assign(__assign({}, state.items), (_a = {},
                        _a[contentItem.parentId] = __assign(__assign({}, state.items[contentItem.parentId]), {
                            children: result
                        }),
                        _a)) });
            }
            else {
                result.map(function (item) {
                    var _a;
                    if (!item) {
                        return;
                    }
                    index++;
                    newItems = __assign(__assign({}, newItems), (_a = {},
                        _a[item.id] = __assign(__assign({}, item), { orderPosition: index }),
                        _a));
                });
                return __assign(__assign({}, state), { items: newItems });
            }
        }
    }
});
export var markAsPassed = (_a = contentListSlice.actions, _a.markAsPassed), toggleMenuInContent = _a.toggleMenuInContent, startLoading = _a.startLoading, setList = _a.setList, setActive = _a.setActive, create = _a.create, reorder = _a.reorder, remove = _a.remove, updateActiveContentCondition = _a.updateActiveContentCondition, setChildItems = _a.setChildItems;
export default contentListSlice.reducer;
