import {handleActions} from 'redux-actions';
import {requestSaveNewMemberDataSuccess, setNewMemberData} from "../actions/main";

const initValues = {
    newProfile: {
        gender: 'm',
        firstname: null,
        lastname: null,
        phoneNum: null,
        email: null,
        birthday: '2000-03-27',
    },
    newMemberId: null,
    justCreated: false,
    inProgress: false,
    isLoaded: false
}
export default handleActions(
    {
        [requestSaveNewMemberDataSuccess]: (state, action) => {
            return {
                ...state,
                ...{
                    justCreated: true,
                    newMemberId: action.payload.member.id,
                }
            }
        },
        [setNewMemberData]: (state, action) => {
            const field = action.payload.field
            const value = action.payload.value

            return {
                ...state,
                newProfile: {...state.newProfile, ...{[field]: value}}
            }
        },
    },
    initValues,
);
