import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {loadProjectsList} from "../actions/main";
import Table from "react-bootstrap/Table";
import {Link} from "react-router-dom";
import {projectCreateUrl, projectProfileUrl} from "../UrlFactory";

const ProjectListPage = (props) => {

    useEffect(() => {
        props.loadProjectsList()
    }, [])

    const projects = props.projectListState.projects.map(project => {
        const projectLink = projectProfileUrl(project.id).getPublicUrl()
        return (<tr key={"project_" + project.id}>
            <td>{project.name}</td>
            <td><Link to={projectLink}>edit</Link></td>
        </tr>)
    });

    return (<div>
        <Link to={projectCreateUrl.getPublicUrl()}>create project</Link>
        <Table striped bordered hover size="sm">
            <tbody>
            {projects}
            </tbody>
        </Table></div>)
};

const mapStateToProps = function (state) {
    return {
        projectListState: state.projectsList,
    }
}

ProjectListPage.propTypes = {
    loadProjectsList: PropTypes.func,
    projectListState: PropTypes.object,
}

export default connect(mapStateToProps, {
    loadProjectsList
})(ProjectListPage);
