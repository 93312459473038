var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { generateRandomStringID } from "../../../utils/strings";
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    conditions: {}
};
var editConditionSlice = createSlice({
    name: 'editCondition',
    initialState: initialState,
    reducers: {
        setConditionsForEdit: function (state, action) {
            if (action.payload.nextStep && action.payload.nextStep.conditions) {
                console.log('action.payload.nextStep.conditions', action.payload.nextStep.conditions);
                var result_1 = {};
                action.payload.nextStep.conditions.map(function (condition) {
                    result_1[condition.id] = condition;
                });
                return __assign(__assign({}, state), { conditions: result_1 });
            }
            else {
                return state;
            }
        },
        create: function (state, action) {
            var _a;
            var id = generateRandomStringID();
            var conditionValues = {};
            //
            var label = '';
            console.log('action.payload', action.payload);
            // console.log('ConditionType.Dropdown', ConditionType.Dropdown.valueOf())
            if (action.payload == 'dropdown') {
                label = 'Select me';
                var id1 = generateRandomStringID();
                var value1 = {
                    id: id1,
                    label: 'variant-1',
                    isNew: true,
                };
                var value2 = {
                    id: generateRandomStringID(),
                    label: 'variant-2',
                    isNew: true,
                };
                conditionValues[value1.id] = value1;
                conditionValues[value2.id] = value2;
            }
            var newDropdown = {
                id: id,
                type: action.payload,
                label: label,
                isNew: true,
                isDeleted: false,
                selectedCheckbox: false,
                selectedDropdownValues: [],
                values: conditionValues
            };
            return __assign(__assign({}, state), {
                conditions: __assign(__assign({}, state.conditions), (_a = {}, _a[id] = newDropdown, _a))
            });
        },
        changeLabel: function (state, action) {
            var _a;
            var item = action.payload.item;
            // item.label = action.payload.label
            return __assign(__assign({}, state), {
                conditions: __assign(__assign({}, state.conditions), (_a = {},
                    _a[item.id] = __assign(__assign({}, item), {
                        label: action.payload.label
                    }),
                    _a))
            });
        },
        changeDropdownValue: function (state, action) {
            var _a, _b;
            var value = action.payload.value;
            var newLabel = action.payload.label;
            var condition = action.payload.condition;
            return __assign(__assign({}, state), {
                conditions: __assign(__assign({}, state.conditions), (_a = {},
                    _a[condition.id] = __assign(__assign({}, condition), {
                        values: __assign(__assign({}, condition.values), (_b = {},
                            _b[value.id] = __assign(__assign({}, condition.values[value.id]), {
                                label: newLabel
                            }),
                            _b))
                    }),
                    _a))
            });
        },
        removeDropdownValue: function (state, action) {
            var _a;
            var value = action.payload.value;
            var condition = action.payload.condition;
            var values = {};
            for (var key in condition.values) {
                if (key != value.id) {
                    values[key] = condition.values[key];
                }
            }
            return __assign(__assign({}, state), {
                conditions: __assign(__assign({}, state.conditions), (_a = {},
                    _a[condition.id] = __assign(__assign({}, condition), {
                        values: values
                    }),
                    _a))
            });
        },
        addDropdownValue: function (state, action) {
            var _a, _b;
            var condition = action.payload;
            var conditionId = condition.id;
            var newDropdownValueId = generateRandomStringID();
            return __assign(__assign({}, state), {
                conditions: __assign(__assign({}, state.conditions), (_a = {},
                    _a[conditionId] = __assign(__assign({}, condition), {
                        values: __assign(__assign({}, condition.values), (_b = {},
                            _b[newDropdownValueId] = {
                                id: newDropdownValueId,
                                label: 'test',
                                isNew: true,
                            },
                            _b))
                    }),
                    _a))
            });
        },
        markAsDeleted: function (state, action) {
            var _a;
            var condition = action.payload;
            return __assign(__assign({}, state), {
                conditions: __assign(__assign({}, state.conditions), (_a = {},
                    _a[condition.id] = __assign(__assign({}, condition), {
                        isDeleted: !state.conditions[condition.id].isDeleted,
                    }),
                    _a))
            });
        }
    },
});
export var setConditionsForEdit = (_a = editConditionSlice.actions, _a.setConditionsForEdit), create = _a.create, markAsDeleted = _a.markAsDeleted, changeLabel = _a.changeLabel, addDropdownValue = _a.addDropdownValue, changeDropdownValue = _a.changeDropdownValue, removeDropdownValue = _a.removeDropdownValue;
export default editConditionSlice.reducer;
